import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { baseModel } from '../../RouteHandler';
import { fnGtmAppClickDownload } from '../Js-CommonFunctions';

export default function ServicesAndExperiences({ fields, isWestfield, isAccountPage }) {

  const [isCommunity, setIsCommunity] = useState(false);
  let twoSliderServices = [];
  let threeSliderServices = [];
  let desc = { value: '' };

  useEffect(() => {

    if (document.getElementById("loyalty-banner")) {
      let Community = document.getElementById("loyalty-banner").getAttribute("data-communityname");
      if (Community !== null && Community !== undefined && Community !== "" && fields.communityServiceItems !== null && fields.communityServiceItems !== undefined && fields.communityServiceItems !== "" &&
        fields.communityServiceItems.communityList !== null && fields.communityServiceItems.communityList !== undefined && fields.communityServiceItems.communityList !== "") {
        setIsCommunity(true);
      }
    }

  }, []);

  useEffect(() => {
  }, [isCommunity]);

  function addSlider() {
    if (fields !== undefined && fields !== "" && fields !== null &&
      fields.communityServiceItems !== null && fields.communityServiceItems !== undefined && fields.communityServiceItems !== "" &&
      fields.communityServiceItems.communityList !== null && fields.communityServiceItems.communityList !== undefined && fields.communityServiceItems.communityList !== "" && isCommunity) {

      if (fields.communityServiceItems.communityList?.length <= 3) {
        fields.communityServiceItems.communityList.map((item, index) => {
          threeSliderServices.push(item);
        });
      }
      else {
        fields.communityServiceItems.communityList.map((item, index) => {
          if (index <= 1) {
            twoSliderServices.push(item);
          }
          else {
            threeSliderServices.push(item);
          }
        });
      }
    }
    else if (fields !== undefined && fields !== "" && fields !== null &&
      fields.serviceItems !== null && fields.serviceItems !== undefined && fields.serviceItems !== "" &&
      fields.serviceItems.communityList !== null && fields.serviceItems.communityList !== undefined && fields.serviceItems.communityList !== "") {

      if (fields.serviceItems.communityList?.length <= 3) {
        fields.serviceItems.communityList.map((item, index) => {
          threeSliderServices.push(item);
        });
      }
      else {
        fields.serviceItems.communityList.map((item, index) => {
          if (index <= 1) {
            twoSliderServices.push(item);
          }
          else {
            threeSliderServices.push(item);
          }
        });
      }
    }
  }

  if (fields !== undefined && fields !== "" && fields !== null &&
    fields.serviceItems != undefined && fields.serviceItems != null && fields.serviceItems != "") {

    desc.value = isCommunity ? fields?.communityServiceItems?.description : fields?.serviceItems?.description;
    addSlider();
    return (
      <>
        <section className={"loyalty-block-wc p-0 bg-white " + (isAccountPage ? "loyalty-block-account-page" : "")}>
          <div className={"loyalty-discover-world-container " + (isAccountPage ? "py-2" : "container py-lg-5")}>
            <div className={"loyalty-discover-world-content d-flex justify-content-center py-2 py-lg-2 " + (isAccountPage ? "account-loyalty-discover-world-content text-black" : "")}>
              {isCommunity ? <RichText field={fields.communityServiceItems.heading} /> : <RichText field={fields.serviceItems.heading} />}</div>
            <div className={"loyalty-discover-world-subcontent d-flex justify-content-center py-2 py-lg-2 " + (isAccountPage ? "account-loyalty-discover-world-subcontent text-black" : "")}><Text field={desc} /></div>
          </div>

          <div className={"loyalty-slider-container " + (isAccountPage ? "py-2" : "container pb-5 pb-lg-5")}>
            <div className="loyalty-two-slider ">
              <div className="row mx-0">
                {twoSliderServices?.map((item, index) => {
                  return (
                    (
                      <div key={index} className="col-md-6 p-3 p-lg-2 d-flex">
                        <div className="card card-inverse w-100 pb-3  rounded-3 text-decoration-none">
                          <div className="card-block rounded-3">
                            <div className="d-flex justify-content-center rounded-3 position-relative align-items-center">
                              <span className={"position-absolute loyalty-card-title " + (isAccountPage ? "account-loyalty-card-title" : "")}>{item.communityTitle}</span>
                              {(isAccountPage ?
                                (<>
                                  {isWestfield &&
                                    <img src={fields.serviceItems?.bgImage?.value?.src} alt=""
                                      loading="lazy" className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}>
                                    </img>
                                  }
                                  {!isWestfield &&
                                    <div className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}></div>
                                  }
                                </>
                                ) :
                                (<>
                                  {baseModel.branded_center &&
                                    <img src={fields.serviceItems.bgImage?.value?.src} alt=""
                                      loading="lazy" className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}>
                                    </img>
                                  }
                                  {!baseModel.branded_center &&
                                    <div className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}></div>
                                  }
                                </>
                                )
                              )
                              }

                            </div>
                            <div className="d-flex justify-content-center rounded-3 align-items-center">
                              <img src={item.communityImage?.value?.src} alt=""
                                loading="lazy" className="w-100 loyalty-program-card-image"></img>
                            </div>
                            {<RichText field={item?.description} />}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div >
            <div className="loyalty-three-slider ">
              <div className="row mx-0">
                {threeSliderServices?.map((item, index) => {
                  return (
                    (
                      <div key={index} className="col-md-4 p-3 p-lg-2 d-flex">
                        <div className="card card-inverse w-100 pb-3  rounded-3 text-decoration-none">
                          <div className="card-block rounded-3">
                            <div className="d-flex justify-content-center rounded-3 position-relative align-items-center">
                              <span className={"position-absolute loyalty-card-title " + (isAccountPage ? "account-loyalty-card-title" : "")}>{item.communityTitle}</span>
                              {(isAccountPage ?
                                (<>
                                  {isWestfield &&
                                    <img src={fields.serviceItems.bgImage?.value?.src} alt=""
                                      loading="lazy" className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}>
                                    </img>
                                  }
                                  {!isWestfield &&
                                    <div className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}></div>
                                  }
                                </>
                                ) :
                                (<>
                                  {baseModel.branded_center &&
                                    <img src={fields.serviceItems.bgImage?.value?.src} alt=""
                                      loading="lazy" className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}>
                                    </img>
                                  }
                                  {!baseModel.branded_center &&
                                    <div className={"w-100 loyalty-card-title-img " + (isAccountPage ? "account-loyalty-card-title-img" : "")}></div>
                                  }
                                </>
                                )
                              )
                              }
                            </div>
                            <div className="d-flex justify-content-center rounded-3">
                              <img src={item?.communityImage?.value?.src} alt=""
                                loading="lazy" className={"w-100 loyalty-program-card-image"}></img>
                            </div>
                            {<RichText field={item?.description} />}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            {fields.heroData !== null && fields.heroData !== undefined && fields.heroData !== "" &&
              fields.heroData.customMobile !== null && fields.heroData.customMobile !== undefined && fields.heroData.customMobile !== "" && fields.heroData.customMobile?.length > 0 &&
              <div className="loyalty-footer-stores py-3 d-flex justify-content-center text-center mt-lg-5 ">
                <ul className="mobile-app-links p-0">
                  {fields.heroData?.customMobile?.map((item, index) => {
                    const anchorId = "footer" + item.storeclassName;
                    return (
                      (item.downloadLink !== null && item.downloadImagePath !== null) &&
                      <li key={index} className="d-inline pe-1">
                        <a aria-label={item?.downloadLink} href={item.downloadLink} id={anchorId} target={item.downloadLinkTarget} data-testid="testclick1" className="text-uppercase " onClick={(e) => fnGtmAppClickDownload(item.gtmAppOS)}>
                          <img src={fields.heroData.defaultImage} loading="lazy" data-alt={item.downloadImagePath} alt={item.downloadImageAltText} />
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            }
          </div >
        </section>
      </>
    )
  }
  else {
    return (<></>)
  }
}

