import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { CommonBookmarkallpage, fnClickNavigation, fnUpdatePageNameInDataAttr, isMobile, lazyLoadingImg } from '../Js-CommonFunctions';
import Service from '../Service/index.js';
const ShopLandingOfferInnerPage = loadable(() => import("../ShopLandingOfferInnerPage"));
const MosaicAll = loadable(() => import("../MosaicAll"));

export default function LoyaltyProgramAdvantage({ fields }) {

    const [visibleShops, setVisibleShops] = useState(6);
    const [visibleRestaurants, setVisibleRestaurants] = useState(6);
    const [visibleEvents, setvisibleEvents] = useState(0);
    let offerItemPerPage = 6;
    let EventsPerOther = 8;
    let EventsPerMobile = 3;
    let EventsPerTab = 9;

    useEffect(() => {
        if (isMobile.any())
            setvisibleEvents(3);
        else if (isMobile.isTablet())
            setvisibleEvents(9);
        else
            setvisibleEvents(8);

        lazyLoadingImg();
    }, []);

    useEffect(() => {
        var totalcount = fields?.loyaltyAdvModel?.eventsInfo?.customItems?.length;
        if (totalcount > visibleEvents) {
            if (document.getElementById('whatshotviewmoreDiv'))
                document.getElementById('whatshotviewmoreDiv').style.display = "block";
        } else {
            if (document.getElementById('whatshotviewmoreDiv'))
                document.getElementById('whatshotviewmoreDiv').style.display = "none";
        }
        CommonBookmarkallpage();
    }, [visibleShops, visibleRestaurants, visibleEvents]);

    const loyaltyOffersShopsVM = (element) => {
        setVisibleShops(visibleShops + offerItemPerPage);
        if (document.getElementById('lcAdvShopsViewMore'))
            document.getElementById('lcAdvShopsViewMore').setAttribute('data-gtmpagenumber', '');
        let pagenumber = Math.ceil(visibleShops / offerItemPerPage) + 1;
        if (document.getElementById('lcAdvShopsViewMore'))
            document.getElementById('lcAdvShopsViewMore').setAttribute('data-gtmpagenumber', pagenumber);

        var totalcount = fields.loyaltyAdvModel.shopOffersInfo.storeTotalOffersCount;
        if (visibleShops >= totalcount) {
            if (document.getElementById("lcAdvShopsViewMoreDiv"))
                document.getElementById("lcAdvShopsViewMoreDiv").style.display = "none";
        }

        CommonBookmarkallpage();   //needs to be implemented as part of Bookmark functionality
        lazyLoadingImg();

    }

    const loyaltyOffersRestaurantVM = (element) => {
        setVisibleRestaurants(visibleRestaurants + offerItemPerPage);
        if (document.getElementById('lcAdvRestaurantsViewMore'))
            document.getElementById('lcAdvRestaurantsViewMore').setAttribute('data-gtmpagenumber', '');
        let pagenumber = Math.ceil(visibleRestaurants / offerItemPerPage) + 1;
        if (document.getElementById('lcAdvRestaurantsViewMore'))
            document.getElementById('lcAdvRestaurantsViewMore').setAttribute('data-gtmpagenumber', pagenumber);

        var totalcount = fields.loyaltyAdvModel.restaurantOffersInfo.restaurantTotalOffersCount; // document.querySelector('#lcAdvRestaurantsOffers #totalviewmorecount')[0].value;
        if (visibleRestaurants >= totalcount) {
            if (document.getElementById('lcAdvRestaurantsViewMoreDiv'))
                document.getElementById("lcAdvRestaurantsViewMoreDiv").style.display = "none";
        }

        CommonBookmarkallpage();   //needs to be implemented as part of Bookmark functionality
        lazyLoadingImg();

    }

    //Function to get more events/news when View More is clicked
    function eventsViewMore(perPageItem, element) {
        var itemsToDisplay = visibleEvents + perPageItem;
        setvisibleEvents(itemsToDisplay);
        if (document.getElementById('viewmorepageother'))
            document.getElementById('viewmorepageother').setAttribute('data-gtmpagenumber', '');
        let pagenumber = Math.ceil(visibleEvents / perPageItem) + 1;
        if (perPageItem === 8) //Desktop View
        {
            if (document.getElementById('viewmorepageother'))
                document.getElementById('viewmorepageother').setAttribute('data-gtmpagenumber', pagenumber);
        }
        else if (perPageItem === 3) //MobileView
        {
            if (document.getElementById('viewmorepagemobile'))
                document.getElementById('viewmorepagemobile').setAttribute('data-gtmpagenumber', pagenumber);
        }
        else if (perPageItem === 9) //MobileView
        {
            if (document.getElementById('viewmorepagetab'))
                document.getElementById('viewmorepagetab').setAttribute('data-gtmpagenumber', pagenumber);
        }


        //Add click navigation analytics changes for news mosaic block
        document.querySelector('#row-isflex-flexgrid-clearfix .gtmClickNavigation').addEventListener('click', function (event) {
            fnUpdatePageNameInDataAttr(event, 'gtmclickposition');
            fnClickNavigation(event);
        });
        var totalcount = parseInt(document.getElementById('totalresult').value);
        if (totalcount > itemsToDisplay) {
            if (document.getElementById('whatshotviewmoreDiv'))
                document.getElementById('whatshotviewmoreDiv').style.display = "block";

        } else {
            if (document.getElementById('whatshotviewmoreDiv'))
                document.getElementById('whatshotviewmoreDiv').style.display = "none";

        }
        CommonBookmarkallpage();
        lazyLoadingImg();
        return false;
    }

    if (fields !== null && fields !== undefined && fields !== "" && fields.loyaltyAdvModel !== null && fields.loyaltyAdvModel !== undefined && fields.loyaltyAdvModel !== "") {
        fields.loyaltyAdvModel.restaurantOffersInfo.offerItems = fields.loyaltyAdvModel.restaurantOffersInfo.items;
        fields.loyaltyAdvModel.shopOffersInfo.offerItems = fields.loyaltyAdvModel.shopOffersInfo.items;
        fields.loyaltyAdvModel.shopOffersInfo.eligibilityLoyaltyText = fields.loyaltyAdvModel.eligibilityLoyaltyText;
        fields.loyaltyAdvModel.shopOffersInfo.eligibilityWorkerText = fields.loyaltyAdvModel.eligibilityWorkerText;

        let isDetailPage = fields.loyaltyAdvModel.shopOffersInfo.isStoreOrRestaurantDetailPage;
        let isOffersPage = fields.loyaltyAdvModel.url === fields.loyaltyAdvModel.urlPrefix + fields.loyaltyAdvModel.slash + fields.loyaltyAdvModel.loyaltyOfferPage;
        let strStyleclassName = "";
        if (fields.loyaltyAdvModel.shopOffersInfo != null && (fields.loyaltyAdvModel.shopOffersInfo.totalOffersCount == 2 && !isOffersPage && !isDetailPage && (fields.loyaltyAdvModel.shopOffersInfo.destinationType != null && fields.loyaltyAdvModel.shopOffersInfo.destinationType === ""))) //block size do not change for Offers page, store/rest. detail pages and destination pages
        {
            strStyleclassName = "col-lg-6";
        }
        else if (isDetailPage || fields.loyaltyAdvModel.shopOffersInfo.offerTypeParameter === fields.loyaltyAdvModel.newsDetailOffer) {
            strStyleclassName = fields.loyaltyAdvModel.isNewBrandedSite ? "col-lg-4" : "col-lg-3";
        }
        else if (fields.loyaltyAdvModel.shopOffersInfo != null && fields.loyaltyAdvModel.shopOffersInfo.destinationType != null && fields.loyaltyAdvModel.shopOffersInfo.destinationType != "") {
            strStyleclassName = fields.loyaltyAdvModel.isNewBrandedSite ? "col-lg-4" : "col-lg-3";
        }
        else if (fields.loyaltyAdvModel.shopOffersInfo.isCategoryFilterComponent) {
            strStyleclassName = "col-lg-3";
        }
        else {
            strStyleclassName = "col-lg-4";
        }
        return (
            <section className={"offers " + (fields.loyaltyAdvModel.isNewBrandedSite ? "back-gray" : "") + " loyalty navme"} id="lcAdvantage">
                <div className="container">
                    <div className={(fields.loyaltyAdvModel.isNewBrandedSite ? "header-title d-flex align-items-baseline flex-wrap justify-content-lg-between" : "content-title text-uppercase text-center underline")}>
                        {fields.loyaltyAdvModel.isNewBrandedSite ?
                            (
                                <div className="title">
                                    <a aria-label={fields?.loyaltyAdvModel?.offers} href={fields.loyaltyAdvModel.urlPrefix + fields.loyaltyAdvModel.slash + fields.loyaltyAdvModel.loyaltyOfferPage}>
                                        <h2><span className="title-border">{fields.loyaltyAdvModel.offers}</span></h2>
                                    </a>
                                </div>
                            ) :
                            (
                                fields.loyaltyAdvModel.offers
                            )
                        }
                    </div>
                    <ul id="Tabbing" className="nav nav-tabs" role="tablist">
                        <li role="presentation" className="makethisactive active">
                            <a href="#lcAdvShopsTab" aria-label={fields?.loyaltyAdvModel?.shops} id="shopsTab" className="gtmLoyaltyAdvantageTabs genericTabs" aria-controls="lcAdvShopsTab" role="tab" data-bs-toggle="tab" onClick={(e) => setme(e)}>
                                <i className="picto icon-srv-handbag"></i>
                                {(fields.loyaltyAdvModel.shops != null && fields.loyaltyAdvModel.shops != "") &&
                                    <h2>
                                        <span>{fields.loyaltyAdvModel.shops}</span>
                                    </h2>
                                }
                            </a>
                        </li>

                        <li role="presentation">
                            <a href="#lcAdvRestaurantsTab" aria-label={fields?.loyaltyAdvModel?.restaurants} id="restaurantsTab" className="gtmLoyaltyAdvantageTabs genericTabs" aria-controls="lcAdvRestaurantsTab" role="tab" data-bs-toggle="tab" onClick={(e) => setme(e)}>
                                <i className="picto icon-factory"></i>
                                {(fields.loyaltyAdvModel.restaurants != null && fields.loyaltyAdvModel.restaurants != "") &&
                                    <h2>
                                        <span>{fields.loyaltyAdvModel.restaurants}</span>
                                    </h2>
                                }
                            </a>
                        </li>

                        <li role="presentation">
                            <a href="#lcAdvServicesTab" aria-label={fields?.loyaltyAdvModel?.services} id="servicesTab" className="gtmLoyaltyAdvantageTabs genericTabs" aria-controls="lcAdvServicesTab" role="tab" data-bs-toggle="tab" onClick={(e) => setme(e)}>
                                <i className="picto icon-servicesaspictosservices"></i>
                                {(fields.loyaltyAdvModel.services != null && fields.loyaltyAdvModel.services != "") &&
                                    <h2>
                                        <span>{fields.loyaltyAdvModel.services}</span>
                                    </h2>
                                }
                            </a>
                        </li>

                        <li role="presentation">
                            <a href="#lcAdvEventsTab" aria-label={fields?.loyaltyAdvModel?.events} id="eventTab" className="gtmLoyaltyAdvantageTabs genericTabs" aria-controls="lcAdvEventsTab" role="tab" data-bs-toggle="tab" onClick={(e) => setme(e)}>
                                <i className="picto icon-servicesaspictosevent"></i>
                                {(fields.loyaltyAdvModel.events != null && fields.loyaltyAdvModel.events != "") &&
                                    <h2>
                                        <span>{fields.loyaltyAdvModel.events}</span>
                                    </h2>
                                }
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="lcAdvShopsTab">
                            {fields.loyaltyAdvModel.shopOffersInfo == null || fields.loyaltyAdvModel.shopOffersInfo.storeOffers == null || fields.loyaltyAdvModel.shopOffersInfo.items.length == 0 ?
                                (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="picto-wrap emptystate">
                                                <i className="picto icon-servicesaspictosoffers"></i>
                                                <p className="text-uppercase">{fields.loyaltyAdvModel.offerEmptyText}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <>
                                        <div className="row" id="lcAdvShopsOffers">
                                            <ShopLandingOfferInnerPage model={fields.loyaltyAdvModel.shopOffersInfo} landingOfferModel={fields.model} values={visibleShops} />
                                        </div>
                                        {fields.loyaltyAdvModel.shopOffersInfo.storeTotalOffersCount > visibleShops &&
                                            <div className={(fields.loyaltyAdvModel.isNewBrandedSite ? "mt-3 mb-1 text-black text-center view-more" : "more text-uppercase")} id="lcAdvShopsViewMoreDiv">
                                                <input type="hidden" id="lcAdvShopsViewMoreCount" value="1" />
                                                {fields.loyaltyAdvModel.isNewBrandedSite ?
                                                    (
                                                        <div id="lcAdvShopsViewMore" onClick={(e) => loyaltyOffersShopsVM(e.currentTarget)}>
                                                            {fields.loyaltyAdvModel.viewMore}
                                                        </div>
                                                    ) :
                                                    (
                                                        <a href="javascript:void(0);" aria-label={fields?.loyaltyAdvModel?.viewMore} id="lcAdvShopsViewMore" onClick={(e) => loyaltyOffersShopsVM(e.currentTarget)}>
                                                            {fields.loyaltyAdvModel.viewMore}<br />
                                                            <i className="picto icon-go-down-arrow"></i>
                                                        </a>
                                                    )
                                                }
                                            </div>
                                        }
                                        <div className="more-info">
                                            {fields.loyaltyAdvModel.cezOffer} <a aria-label={fields?.loyaltyAdvModel?.termsOfUsePage} href={fields.loyaltyAdvModel.urlPrefix + fields.loyaltyAdvModel.slash + fields.loyaltyAdvModel.termsOfUsePage}>{fields.loyaltyAdvModel.conditionsParticulieres}</a>
                                        </div>
                                    </>

                                )
                            }
                        </div>

                        <div role="tabpanel" className="tab-pane" id="lcAdvRestaurantsTab">
                            {fields.loyaltyAdvModel.restaurantOffersInfo == null || fields.loyaltyAdvModel.restaurantOffersInfo.items == null || fields.loyaltyAdvModel.restaurantOffersInfo.items.length == 0 ?
                                (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="picto-wrap emptystate">
                                                <i className="picto icon-servicesaspictosoffers"></i>
                                                <p className="text-uppercase">{fields.loyaltyAdvModel.offerEmptyText}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <>
                                        <div className="row" id="lcAdvRestaurantOffers">
                                            <ShopLandingOfferInnerPage model={fields.loyaltyAdvModel.restaurantOffersInfo} landingOfferModel={fields.model} values={visibleRestaurants} />
                                        </div>
                                        {fields.loyaltyAdvModel.restaurantOffersInfo.restaurantTotalOffersCount > visibleRestaurants &&
                                            <div className={(fields.loyaltyAdvModel.isNewBrandedSite ? "mt-3 mb-1 text-black text-center view-more" : "more text-uppercase")} id="lcAdvRestaurantsViewMoreDiv">
                                                <input type="hidden" id="lcAdvRestaurantsViewMoreCount" value="1" />
                                                {fields.loyaltyAdvModel.isNewBrandedSite ?
                                                    (
                                                        <div id="lcAdvRestaurantsViewMore" onClick={(e) => loyaltyOffersRestaurantVM(e.currentTarget)}>
                                                            {fields.loyaltyAdvModel.viewMore}
                                                        </div>
                                                    ) :
                                                    (
                                                        <a href="javascript:void(0);" aria-label={fields?.loyaltyAdvModel?.viewMore} id="lcAdvRestaurantsViewMore" onClick={(e) => loyaltyOffersRestaurantVM(e.currentTarget)}>
                                                            {fields.loyaltyAdvModel.viewMore}<br />
                                                            <i className="picto icon-go-down-arrow"></i>
                                                        </a>
                                                    )
                                                }
                                            </div>
                                        }
                                        <div className="more-info">
                                            {fields.loyaltyAdvModel.cezOffer} <a aria-label={fields?.loyaltyAdvModel?.termsOfUsePage} href={fields.loyaltyAdvModel.urlPrefix + fields.loyaltyAdvModel.slash + fields.loyaltyAdvModel.termsOfUsePage}>{fields.loyaltyAdvModel.conditionsParticulieres}</a>
                                        </div>
                                    </>
                                )
                            }
                        </div>

                        <div role="tabpanel" className="tab-pane" id="lcAdvServicesTab">
                            {(fields.loyaltyAdvModel.servicesInfo == null || ((fields.loyaltyAdvModel.servicesInfo.servicePagePro == null || fields.loyaltyAdvModel.servicesInfo.servicePagePro.length == 0)
                                && (fields.loyaltyAdvModel.servicesInfo.servicehighlightmodel == null || fields.loyaltyAdvModel.servicesInfo.servicehighlightmodel?.serviceHighlightList == null || fields.loyaltyAdvModel.servicesInfo.servicehighlightmodel?.serviceHighlightList.length == 0))) ?
                                (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="picto-wrap emptystate">
                                                <i className="picto icon-servicesaspictosservices"></i>
                                                <p className="text-uppercase">{fields.loyaltyAdvModel.servicesEmptyText}</p>
                                            </div>

                                        </div>
                                    </div>
                                ) :
                                (
                                    <Service fields={fields.loyaltyAdvModel} />
                                )
                            }
                        </div>

                        <div role="tabpanel" className="tab-pane" id="lcAdvEventsTab">
                            {(fields.loyaltyAdvModel.eventsInfo == null || fields.loyaltyAdvModel.eventsInfo.customItems == null || fields.loyaltyAdvModel.eventsInfo.customItems.length == 0) ?
                                (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="picto-wrap emptystate">
                                                <i className="picto icon-servicesaspictosevent"></i>
                                                <p className="text-uppercase">{fields.loyaltyAdvModel.eventsEmptyText}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <>
                                        <div className="whats-hot-mosiac related-news-events four-block">

                                            <div id="unexpected">
                                                <div id="row-isflex-flexgrid-clearfix">
                                                    <MosaicAll model={fields.loyaltyAdvModel.eventsInfo} commonValues={fields.model} visibleItemCount={visibleEvents} />
                                                </div>
                                            </div>
                                        </div>
                                        {fields.loyaltyAdvModel.eventsInfo.items != null &&
                                            (fields.loyaltyAdvModel.isNewBrandedSite ?
                                                (
                                                    <div id="whatshotviewmoreDiv">
                                                        <div className="mt-3 mb-1 text-black text-center view-more d-none d-lg-block" id="whatshotviewmore">
                                                            <div id="viewmorepageother"
                                                                onClick={(e) => eventsViewMore(EventsPerOther, e.currentTarget)}>
                                                                {fields.loyaltyAdvModel.eventsInfo.viewMoreDict}
                                                            </div>
                                                        </div>

                                                        <div className="mt-3 mb-1 text-black text-center view-more d-block d-sm-none" id="whatshotviewmoremobile">
                                                            <div id="viewmorepagemobile"
                                                                onClick={(e) => eventsViewMore(EventsPerMobile, e.currentTarget)}>
                                                                {fields.loyaltyAdvModel.eventsInfo.viewMoreDict}
                                                            </div>
                                                        </div>

                                                        <div className="mt-3 mb-1 text-black text-center view-more d-md-block d-none d-xl-none" id="whatshotviewmoretab">
                                                            <div id="viewmorepagetab"
                                                                onClick={(e) => eventsViewMore(EventsPerTab, e.currentTarget)}>
                                                                {fields.loyaltyAdvModel.eventsInfo.viewMoreDict}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className="container" id="whatshotviewmoreDiv">
                                                        <div className="more text-uppercase d-none d-lg-block" id="whatshotviewmore">
                                                            <a href="#whatshotviewmore" aria-label={fields?.loyaltyAdvModel?.eventsInfo} id="viewmorepageother"
                                                                onClick={(e) => eventsViewMore(EventsPerOther, e.currentTarget)}>
                                                                {fields.loyaltyAdvModel.eventsInfo.viewMoreDict}<br />
                                                                <i className="picto icon-go-down-arrow"></i>
                                                            </a>
                                                        </div>

                                                        <div className="more text-uppercase d-block d-sm-none" id="whatshotviewmoremobile">
                                                            <a href="#whatshotviewmoremobile" aria-label={fields?.loyaltyAdvModel?.eventsInfo} id="viewmorepagemobile"
                                                                onClick={(e) => eventsViewMore(EventsPerMobile, e.currentTarget)}>
                                                                {fields.loyaltyAdvModel.eventsInfo.viewMoreDict}<br />
                                                                <i className="picto icon-go-down-arrow"></i>
                                                            </a>
                                                        </div>

                                                        <div className="more text-uppercase d-md-block d-none d-xl-none" id="whatshotviewmoretab">
                                                            <a href="#whatshotviewmoretab" aria-label={fields?.loyaltyAdvModel?.eventsInfo} id="viewmorepagetab"
                                                                onClick={(e) => eventsViewMore(EventsPerTab, e.currentTarget)}>
                                                                {fields.loyaltyAdvModel.eventsInfo.viewMoreDict}<br />
                                                                <i className="picto icon-go-down-arrow"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            )

                                        }

                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    else {
        return <></>
    }
}

function setme(e) {
    var thisnode = e.currentTarget;
    var thisli = e.currentTarget.getAttribute('href');
    if (document.getElementsByClassName('makethisactive').length > 0) {
        document.querySelectorAll(".makethisactive").forEach(x => { x.classList.remove("active") })

    }
    thisnode.parentElement.classList.remove("active");
    var currentHref = thisli.split("#")[1];
    if (!thisli.includes("webview=1")) {
        window.history.pushState('', document.title, window.location.pathname + '#Tab=' + currentHref);
    }
    else {
        window.history.pushState('', document.title, window.location.pathname + "?webview=1" + '#Tab=' + currentHref);
    }
}


