import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect } from "react";
import {
  fnGtmRetailerServiceClick,
  storeInfoRightMobile
} from "../Js-CommonFunctions";

export default function StoreMapInfoRight({ fields }) {
  useEffect(() => {
    storeInfoRightMobile();
    document.querySelectorAll(".gtm-retailerserviceclick")
      .forEach(function (ele) {
        ele.addEventListener("click", function () {
          fnGtmRetailerServiceClick(ele.getAttribute("data-target"));
        });
      });
  }, []);
  function showMore() {
    document.getElementsByClassName("read-more-trigger")[0].style.display =
      "none";
    document.getElementsByClassName("read-less-trigger")[0].style.display =
      "inline-block";
    let ele = document.getElementsByClassName("time-text-area");
    for (let i = 0; i < ele.length; i++) {
      ele[i].classList.remove("d-none");
      ele[i].classList.add("d-flex");
    }
  }
  function showLess() {
    document.getElementsByClassName("read-less-trigger")[0].style.display =
      "none";
    document.getElementsByClassName("read-more-trigger")[0].style.display =
      "inline-block";
    let ele = document.getElementsByClassName("time-text-area");
    for (let i = 0; i < ele.length; i++) {
      if (!ele[i].classList.contains("active")) {
        ele[i].classList.add("d-none");
        ele[i].classList.remove("d-flex");
      }
    }
  }
  if (
    fields?.storeMapInfoRight != null &&
    fields?.storeMapInfoRight !== undefined
  ) {
    return (
      <>
        {/* <div className="col-12 col-md-6 d-lg-none d-block right-content">
          <div className="title d-md-block">Liens pratiques :</div>
          <div className="d-none d-md-block">
            <a className="fw-bold d-block text-reset" href="#">Site internet</a>
            <span className="picto icon-group"></span><a href="#" className="fw-bold  d-block  text-reset">Menu</a>
          </div>
          <p className="fontinfos mt-3">Horaires d’ouverture :</p>
          <ul className="ms-0 mb-4 p-0">
            <li className="d-flex justify-content-between time-text-area">
              Mardi
              <span className="hours">
                · De 09:45 à 12:00<br />
                · De 13:00 à 22:45
              </span>
            </li>
            <li className="d-flex justify-content-between time-text-area">
              Mardi
              <span className="hours">
                · De 09:45 à 12:00<br />
                · De 13:00 à 22:45
              </span>
            </li>
            <li className="d-flex justify-content-between time-text-area active">
              Mardi
              <span className="hours">
                · De 09:45 à 12:00<br />
                · De 13:00 à 22:45
              </span>
            </li>
          </ul>
          <div className="text-end my-2">
            <a aria-label="J'en profite" href="#" className="grid-new-cta">Vior plus<span className="picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5"></span></a>
          </div>
          <div className="map-services">
            <div className="title">Services</div>
            <ul className="m-0 p-0 mb-3">
              <li data-toggle="collapse" className="collapsed" data-target="#collapaseMenu" aria-expanded="false" aria-controls="collapaseMenu">
                <span className="picto icon-group"></span><span className="menu-title">Menu</span><span className="picto icon-minus"></span>
              </li>
              <div className="collapse" id="collapaseMenu">
                <a href="#">
                  <span className="picto icon-click-and-collect"></span><span className="menu-title">Menu</span><span className="picto icon-go-right-arrow"></span>
                </a>
                <a href="#">
                  <span className="picto icon-click-and-collect"></span><span className="menu-title">Menu</span><span className="picto icon-go-right-arrow"></span>
                </a>
                <a href="#">
                  <span className="picto icon-click-and-collect"></span><span className="menu-title">Menu</span><span className="picto icon-go-right-arrow"></span>
                </a>
              </div>
            </ul>
          </div>
        </div>
        <div className="text-uppercase fw-bold mobile-heading d-md-none">Informations pratiques</div> */}
        {fields.storeMapInfoRight.title.value !== null &&
          fields.storeMapInfoRight.title.value !== "" && (
            <div
              className={`${fields.storeMapInfoRight.isRebranded ? "mb-4 " : "mb-4 "
                }text-uppercase heading-text`}
            >
              <RichText field={fields.storeMapInfoRight.title} />
            </div>
          )}
        <div className="row">
          <div
            className="col-12 col-md-6 px-lg-0 left-content"
            id="leftinfo"
          >
            <div className="">
              {fields.storeMapInfoRight.description.value !== null &&
                fields.storeMapInfoRight.description.value !== "" && (
                  <div
                    className={`text-area ${fields.storeMapInfoRight.isRebranded ? "mb-4 " : ""
                      }more`}
                    id="descriptiontext"
                  >
                    <RichText field={fields.storeMapInfoRight.description} />
                  </div>
                )}
              {/* <span className="morelink" id="more-text-trigger">Show More</span>
              <span className="morelink less" id="less-text-trigger">Show Less</span> */}
            </div>
            {((fields.storeMapInfoRight.safetyMessageTitle !== undefined &&
              fields.storeMapInfoRight.safetyMessageTitle.value !== null &&
              fields.storeMapInfoRight.safetyMessageTitle.value !== "") ||
              (fields.storeMapInfoRight.safetyMessageDescription !==
                undefined &&
                fields.storeMapInfoRight.safetyMessageDescription.value !==
                null &&
                fields.storeMapInfoRight.safetyMessageDescription.value !==
                "") ||
              (fields.storeMapInfoRight.safetyMessageImage !== undefined &&
                fields.storeMapInfoRight.safetyMessageImage.value !== null &&
                fields.storeMapInfoRight.safetyMessageImage.value !== "")) && (
                <div className="safety-message-block">
                  {fields.storeMapInfoRight.safetyMessageTitle.value !== null &&
                    fields.storeMapInfoRight.safetyMessageTitle.value !== "" && (
                      <div className="heading-text">
                        <Text
                          field={fields.storeMapInfoRight.safetyMessageTitle}
                        />
                      </div>
                    )}
                  {((fields.storeMapInfoRight.safetyMessageImage.value !== null &&
                    fields.storeMapInfoRight.safetyMessageImage.value !== "") ||
                    (fields.storeMapInfoRight.safetyMessageDescription !==
                      undefined &&
                      fields.storeMapInfoRight.safetyMessageDescription.value !==
                      null &&
                      fields.storeMapInfoRight.safetyMessageDescription.value !==
                      "")) && (
                      <div className="safety-message ">
                        <div
                          className={`safety-message-wrap position-relative border-0 shadow-none ${fields.storeMapInfoRight.safetyMessageImage.value !==
                            null &&
                            fields.storeMapInfoRight.safetyMessageImage.value !== ""
                            ? ""
                            : "no-image"
                            }`}
                        >
                          {fields.storeMapInfoRight.safetyMessageImage.value !==
                            null &&
                            fields.storeMapInfoRight.safetyMessageImage.value !==
                            "" && (
                              <div
                                className={`img-wrap ${fields.storeMapInfoRight
                                  .safetyMessageDescription !== undefined &&
                                  fields.storeMapInfoRight.safetyMessageDescription
                                    .value !== null &&
                                  fields.storeMapInfoRight.safetyMessageDescription
                                    .value !== ""
                                  ? "withtext"
                                  : ""
                                  }`}
                              >
                                <img
                                className="border-8"
                                  src={
                                    fields.storeMapInfoRight.safetyMessageImage
                                      .value
                                  }
                                  alt={
                                    fields.storeMapInfoRight.safetyMessageImageAlt
                                      .value
                                  }
                                />
                              </div>
                            )}
                          {fields.storeMapInfoRight.safetyMessageDescription !==
                            undefined &&
                            fields.storeMapInfoRight.safetyMessageDescription
                              .value !== null &&
                            fields.storeMapInfoRight.safetyMessageDescription
                              .value !== "" && (
                              <div className="content-block">
                                <div className="title">HEALTH &amp; SAFETY</div>
                                <div className="description">
                                  <RichText
                                    field={
                                      fields.storeMapInfoRight
                                        .safetyMessageDescription
                                    }
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}

                  {fields.storeMapInfoRight.safetyMessageLinkSrc.value !== null &&
                    fields.storeMapInfoRight.safetyMessageLinkSrc.value !== "" &&
                    fields.storeMapInfoRight.safetyMessageLinkText.value !==
                    null &&
                    fields.storeMapInfoRight.safetyMessageLinkText.value !==
                    "" && (
                      <a
                        aria-label={fields?.storeMapInfoRight?.safetyMessageLinkTarget?.value}
                        className={"safety-nav " +(fields.storeMapInfoRight.isRebranded ? "": "text-start")}
                        href={fields.storeMapInfoRight.safetyMessageLinkSrc.value}
                        target={
                          fields.storeMapInfoRight.safetyMessageLinkTarget.value
                        }
                      >
                        <span>
                          <Text
                            field={fields.storeMapInfoRight.safetyMessageLinkText}
                          />
                        </span>
                        <span className="picto icon-go-right-arrow"></span>
                      </a>
                    )}
                </div>
              )}
          </div>
          <div className="col-12 col-md-6  d-lg-block right-content">
            {fields.storeMapInfoRight.blnIsRestaurent &&
              fields.storeMapInfoRight.attachmentDocument !== null &&
              fields.storeMapInfoRight.attachmentDocument !== "" && (
                <>
                  <div className={`title${fields.storeMapInfoRight.isRebranded ? " d-md-block" : ""}`} >
                    <Text field={fields.storeMapInfoRight.practicalInfo} /> :
                  </div>
                  <div
                    className={`d-none d-md-block${fields.storeMapInfoRight.isRebranded ? " mb-3" : ""
                      }`}
                  >
                    <span className="picto icon-group"></span>
                    <a
                      aria-label={fields?.storeMapInfoRight?.downloadPDFMenus}
                      id="menu"
                      className={`fw-bold text-reset${fields.storeMapInfoRight.isRebranded ? " d-block" : ""
                        }`}
                      target=" _blank"
                      href={fields.storeMapInfoRight.attachmentDocument}
                    >
                      <Text field={fields.storeMapInfoRight.downloadPDFMenus} />
                    </a>
                  </div>
                </>
              )}
            {((fields.storeMapInfoRight.openingHoursList !== null &&
              fields.storeMapInfoRight.openingHoursList !== undefined &&
              fields.storeMapInfoRight.openingHoursList.length > 0) ||
              (fields.storeMapInfoRight.openingHours !== null &&
                fields.storeMapInfoRight.openingHours !== undefined &&
                fields.storeMapInfoRight.openingHours.length > 0)) &&
              fields.storeMapInfoRight.showTimeList != null &&
              !fields.storeMapInfoRight.isComingSoon && (
                <div id="openings" className="mb-3">
                  <p
                    className={`${fields.storeMapInfoRight.isRebranded
                      ? "fontinfos"
                      : "font-bold"
                      } mt-3`}
                  >
                    <Text field={fields.storeMapInfoRight.openingHoursText} />
                  </p>
                  {fields.storeMapInfoRight.isCinema ? (
                    <p
                      className={`${fields.storeMapInfoRight.isRebranded ? "mb-3 " : ""
                        }time-text-area`}
                    >
                      {fields.storeMapInfoRight.cinemaOpenings !== null &&
                        fields.storeMapInfoRight.cinemaOpenings !== undefined &&
                        fields.storeMapInfoRight.cinemaOpenings.map(
                          (timing, index) => {
                            return (
                              <>
                                <span key={index}>{`${timing.value} : `}</span>
                                {timing.key !== null && timing.key !== "" && (
                                  <span key={index}>{timing.key}</span>
                                )}
                                <br></br>
                              </>
                            );
                          }
                        )}
                    </p>
                  ) : (
                    <>
                      <ul className="ms-0 mb-4 p-0">
                        {fields.storeMapInfoRight.showTimeList != null && (
                          <>
                            {fields.storeMapInfoRight.showTimeList.map(
                              (eachShowTimes) => {
                                var displayClass = "d-none";
                                if (
                                  fields.storeMapInfoRight.currentDay.value ===
                                  eachShowTimes.day
                                )
                                  displayClass =
                                    "d-flex fw-bold active";
                                return (
                                  <li
                                    key={eachShowTimes.day}
                                    className={`justify-content-between time-text-area ${displayClass}`}
                                  >
                                    <span className="days">
                                      {
                                        fields.storeMapInfoRight
                                          .culturalDaysOfWeekNames[
                                        eachShowTimes.day
                                        ]
                                      }
                                    </span>
                                    <span className="hours">
                                      {eachShowTimes.fromTime !== null &&
                                        eachShowTimes.fromTime !== "" &&
                                        eachShowTimes.fromTime !== undefined &&
                                        eachShowTimes.fromTime.toLowerCase() ===
                                        fields.storeMapInfoRight.closed.value.toLowerCase() ? (
                                        <>
                                          <Text
                                            field={
                                              fields.storeMapInfoRight.closed
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {eachShowTimes.timelst.map((time) => {
                                            return (
                                              <>
                                                {`· ${time}`}
                                                <br></br>
                                              </>
                                            );
                                          })}
                                        </>
                                      )}
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </>
                        )}
                      </ul>
                      <div
                        className="text-end my-2 read-more-trigger w-100"
                        id="moreClk"
                        data-testid="testClick1"
                        onClick={() => {
                          showMore();
                        }}
                      >
                        <a href="javascript:void(0)" className="grid-new-cta">
                          <Text field={fields.storeMapInfoRight.showMore} />

                          <span
                            className={`picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5`}
                          ></span>
                        </a>
                      </div>
                      <div
                        className="text-end my-2 read-less-trigger w-100"
                        style={{ display: "none" }}
                        data-testid="testClick2"
                        id="lessClk"
                        onClick={() => {
                          showLess();
                        }}
                      >
                        <a href="javascript:void(0)" className="grid-new-cta">
                          <Text field={fields.storeMapInfoRight.showLess} />
                        </a>
                        <span
                          className={`picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5`}
                        ></span>
                      </div>
                    </>
                  )}
                </div>
              )}
            {fields.storeMapInfoRight.blnIsRestaurent &&
              fields.storeMapInfoRight.attachmentDocument !== null &&
              fields.storeMapInfoRight.attachmentDocument !== "" && (
                <>
                  <p
                    className={`${fields.storeMapInfoRight.isRebranded
                      ? "fontinfos"
                      : "font-bold"
                      } mt-3 d-block d-sm-none"`}
                  >
                    <Text field={fields.storeMapInfoRight.practicalInfo} /> :
                  </p>
                  <div className="mb-0 d-md-none">
                    <span className="picto icon-group"></span>
                    <a
                      id="menu"
                      aria-label={fields?.storeMapInfoRight?.attachmentDocument}
                      className="fontbold"
                      target=" _blank"
                      href={fields.storeMapInfoRight.attachmentDocument}
                    >
                      <Text field={fields.storeMapInfoRight.downloadPDFMenus} />
                    </a>
                  </div>
                </>
              )}
            <div className="map-services">
              {((fields.storeMapInfoRight.availableServices !== null &&
                fields.storeMapInfoRight.availableServices !== undefined &&
                fields.storeMapInfoRight.availableServices.length > 0) ||
                (fields.storeMapInfoRight.menus !== null &&
                  fields.storeMapInfoRight.menus !== undefined &&
                  fields.storeMapInfoRight.menus.length > 1) ||
                (fields.storeMapInfoRight.deliveryServices !== null &&
                  fields.storeMapInfoRight.deliveryServices !== undefined &&
                  fields.storeMapInfoRight.deliveryServices.length > 0)) && (
                  <>
                    <div
                      className="title"
                      id="availableservices"
                    >
                      <Text
                        field={fields.storeMapInfoRight.availableServicesText}
                      />
                    </div>

                    <ul className="m-0 p-0 mb-3">
                      {fields.storeMapInfoRight.menus !== null && fields.storeMapInfoRight.menus !== undefined && fields.storeMapInfoRight.menus !== "" && fields.storeMapInfoRight.menus.length > 1 && (
                        <>
                          <li
                            data-toggle="collapse"
                            className="collapsed"
                            data-target="#collapaseMenu"
                            aria-expanded="false"
                            aria-controls="collapaseMenu"
                          >
                            {/* <div className="img-wrap"> */}
                              <span className="picto icon-group"></span>
                            {/* </div> */}
                            <span className="menu-title">
                              <Text
                                field={fields.storeMapInfoRight.menuService}
                              />
                            </span>
                            <span className="picto icon-minus"></span>
                          </li>
                          <div className="collapse" id="collapaseMenu">
                            {fields.storeMapInfoRight.menus.map((menu) => {
                              return (
                                <a
                                  key={menu.name.value}
                                  aria-label={menu?.url}
                                  href={menu.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="gtm-retailerserviceclick"
                                  data-target={menu.gtmTagging}
                                >
                                  {/* <div className="img-wrap"> */}
                                    <span className="picto icon-click-and-collect"></span>
                                  {/* </div> */}
                                  <span className="menu-title">
                                    <Text field={menu.name} />
                                  </span>
                                  <span className="picto icon-go-right-arrow"></span>
                                </a>
                              );
                            })}
                          </div>
                        </>
                      )}

                      {fields.storeMapInfoRight.availableServices !== null && fields.storeMapInfoRight.availableServices !== undefined && fields.storeMapInfoRight.availableServices !== "" && fields.storeMapInfoRight.availableServices.map(
                        (category) => {
                          return (
                            <a
                              href={category.url}
                              aria-label={category?.url}
                              key={category.name.value}
                              target={`${!category.url.startsWith("#") ? "_blank" : ""
                                }`}
                              data-target={category.gtmTagging}
                              className={`${category.url.startsWith("#")
                                ? "scrollToHidden "
                                : ""
                                } gtm-retailerserviceclick`}
                            >
                              {/* <div className="img-wrap"> */}
                                <span
                                  className={`picto ${category.picto}`}
                                ></span>
                              {/* </div> */}
                              <span className="menu-title">
                                <Text field={category.name} />
                              </span>
                              <span className="picto icon-go-right-arrow"></span>
                            </a>
                          );
                        }
                      )}

                      {fields.storeMapInfoRight.deliveryServices !== null && fields.storeMapInfoRight.deliveryServices !== undefined && fields.storeMapInfoRight.deliveryServices !== "" && fields.storeMapInfoRight.deliveryServices.map(
                        (category, index) => {
                          if (category.isImageExists) {
                            return (
                              <a
                                href={category.url}
                                aria-label={category?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="gtm-retailerserviceclick"
                                data-target={category.gtmTagging}
                                key={index}
                              >
                                <div className="img-wrap">
                                  <img
                                    src={category.picto}
                                    alt={category.name.value}
                                  />
                                </div>
                                <span className="menu-title">
                                  <Text field={category.name} />
                                </span>
                                <span className="picto icon-go-right-arrow"></span>
                              </a>
                            );
                          } else {
                            return (
                              <a
                                href={category.url}
                                aria-label={category?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="gtm-retailerserviceclick"
                                data-target={category.gtmTagging}
                                key={index}
                              >
                                <div className="img-wrap">
                                  <span
                                    className={`picto ${category.picto}`}
                                  ></span>
                                </div>
                                <span className="menu-title">
                                  <Text field={category.name} />
                                </span>
                                <span className="picto icon-go-right-arrow"></span>
                              </a>
                            );
                          }
                        }
                      )}
                    </ul>
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
