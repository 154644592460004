import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import { baseModel } from '../../RouteHandler';
import { closeVehicleErrorPopin, createParkingCookie, fnRedirectToConnectLogin, fnRedirectToMyAccount } from "../Js-CommonFunctions";
import { addVehicleClick, updatePartnerDetail } from '../MyAccount/MyAccountScripts';

export default function EasyParking({ fields }) {

  useEffect(() => {
    if (fields !== null && fields !== undefined) {
      if (fields.showVehiclePopin) {
        setTimeout(function () { addVehicleClick(fields, null, true, false, null, null, true); }, 2000);
      }
    }
  }, []);


  if (fields !== null && fields !== undefined && (fields.secondaryParkingBlock !== null && fields.secondaryParkingBlock !== undefined)) {
    return (
      <>
        <div className="col-12 col-lg-4 ps-lg-3 pe-lg-0">
          <div className=" enjoy-cta-block px-4 py-4 text-center bg-white">
            {!fields.isAuthenticated ? (
              <>  
                <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                <div className="title text-uppercase easyparking-header text-start mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                  onClick={() => { createParkingCookie(); fnRedirectToConnectLogin(baseModel.connectLoginUrlFromParking) }}>{fields.secondaryParkingBlock.loginCTA}</button>
              </>
            ) : (
              fields.isSmartParker ? (
                <>
                  {(!fields.visitorVehicleAvailable) &&
                    <div id="vehicleErrorText" className="profile-warning d-flex align-items-center my-3 mobile-container">
                      <div className="text-black text-decoration-none d-flex align-items-center">
                        <span className="icon-alert-info warning-icon fs-3 me-2"></span>
                        <Text field={fields.secondaryParkingBlock.parkingErrorText} />
                      </div>
                      <div>
                        <a href="#" className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none" onClick={() => { closeVehicleErrorPopin() }}></a>
                      </div>
                    </div>
                  }
                  <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                  <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                  <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                  <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                  <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                    data-target="#licenseplate-popin" onClick={() => { fnRedirectToMyAccount(fields.accountUrl?.link?.value?.url + "?ShowSuccessPopin=manage-vehicles") }}>{fields.secondaryParkingBlock.manageCTA}</button>
                </>
              ) : (
                <>
                  {(fields.visitorVehicleAvailable) ? (
                    (fields.isPartnerActivated) ? (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.secondaryActivationTitle}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => updatePartnerDetail(fields.pcCode, fields.parkingPartner,
                            (fields.accountUrl?.link?.value?.url + "?ShowSuccessPopin=easy-parking&pcCode=" + fields.pcCode))}>{fields.secondaryParkingBlock.secondaryActivationCTA}</button>
                      </>
                    ) : (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => updatePartnerDetail(fields.pcCode, fields.parkingPartner,
                            (fields.accountUrl?.link?.value?.url + "?ShowSuccessPopin=easy-parking&pcCode=" + fields.pcCode))}>{fields.secondaryParkingBlock.firstActivationCTA}</button>
                      </>
                    )
                  ) : (
                    (fields.isPartnerActivated) ? (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.secondaryActivationTitle}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => { addVehicleClick(fields, null, true, false, null, null, true) }}>
                          {fields.secondaryParkingBlock.secondaryActivationCTA}</button>
                      </>
                    ) : (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => { addVehicleClick(fields, null, true, false, null, null, true) }}>
                          {fields.secondaryParkingBlock.firstActivationCTA}</button>
                      </>
                    )
                  )
                  }
                </>
              )
            )
            }
          </div>
        </div>
      </>
    )
  }
  else {
    return <>
    </>
  }
}
