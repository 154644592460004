import types from './types';

export function setVisitorId(payload) {
    return {
        type: types.SET_VISITORID,
        payload: payload
    }
}

export function setAccessToken(payload) {
    return {
        type: types.SET_ACCESSTOKEN,
        payload: payload
    }
}

export function setRefreshToken(payload) {
    return {
        type: types.SET_REFRESHTOKEN,
        payload: payload
    }
}

export function setAquisitionPcCode(payload) {
    return {
        type: types.SET_ACQUISITION_PCCODE,
        payload: payload
    }
}

export function setFirstName(payload) {
    return {
        type: types.SET_VISITOR_FIRST_NAME,
        payload: payload
    }
}

export function setLastName(payload) {
    return {
        type: types.SET_VISITOR_LAST_NAME,
        payload: payload
    }
}

export function setInitials(payload) {
    return {
        type: types.SET_VISITORNAMEINITIALS,
        payload: payload
    }
}

export function setEmail(payload) {
    return {
        type: types.SET_VISITOR_EMAIL,
        payload: payload
    }
}

export function setWorkerCenters(payload) {
    return {
        type: types.SET_WORKERCENTER,
        payload: payload
    }
}

export function setBarCode(payload) {
    return {
        type: types.SET_BARCODE,
        payload: payload
    }
}

// export function setVisitorCenters(payload) {
//     return {
//         type: types.SET_VISITORCENTERS,
//         payload: payload
//     }
// }
export function setLogin(payload) {
    return {
        type: types.SET_LOGIN,
        payload: payload
    }
}

export function doLogout(payload) {
    return {
        type: types.DO_LOGOUT,
        payload: payload
    }
}

export function setBirthday(payload) {
    return {
        type: types.SET_VISITORBIRTHDATE,
        payload: payload
    }
}

export function setAccountCreationDate(payload) {
    return {
        type: types.SET_VISITORACCOUNTCREATIONDATE,
        payload: payload
    }
}

export function setFavCenters(payload) {
    return {
        type: types.SET_VISITORFAVCENTERS,
        payload: payload
    }
}

export function setBookmarkedOffer(payload) {
    return {
        type: types.SET_VISITORBOOKMARKEDOFFER,
        payload: payload
    }
}

export function setBookmarkedStore(payload) {
    return {
        type: types.SET_VISITORBOOKMARKEDSTORE,
        payload: payload
    }
}

export function setBookmarkedRestaurant(payload) {
    return {
        type: types.SET_VISITORBOOKMARKEDRESTAURANT,
        payload: payload
    }
}

export function setBookmarkedEvent(payload) {
    return {
        type: types.SET_VISITORBOOKMARKEDEVENT,
        payload: payload
    }
}

export function setVisitorCommunityName(payload) {
    return {
        type: types.SET_VISITORCOMMUNITYNAME,
        payload: payload
    }
}