import loadable from "@loadable/component";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Cookies from "universal-cookie";
import {
  CommonBookmarkallpage,
  URWindowLocation,
  fnClickNavigation,
  fnGtmOfferClick,
  fnUpdatePageNameInDataAttr,
  isMobile,
  lazyLoadingImg
} from "../Js-CommonFunctions";
import { baseModel } from "../../RouteHandler.js";
const PersonalizedBlock = loadable(() => import('../PersonalizedBlock/index.js'), { ssr: false });

export default function HomePageOffers({ fields }) {
  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render

  useEffect(() => {
    setBoolUseEffect(true);

    if (window.location.href.indexOf("offerslogin") >= 0) {
      var heightHeader = document.getElementById("headerBlock").offsetHeight + document.getElementById("MainHeader").offsetHeight;
      var rect = document.querySelector('#homePageOffers').getBoundingClientRect();
      if (isMobile.any()) {
        window.scrollTo({
          top: rect.top + window.scrollY + 400 - heightHeader, behavior: "smooth",
        });
      }
      else {
        window.scrollTo({
          top: rect.top + window.scrollY - 30 - 550 - heightHeader, behavior: "smooth",
        });
      }
    }
  }, []);

  useEffect(() => {
    CommonBookmarkallpage();
    lazyLoadingImg();
    document
      .querySelectorAll("#homePageOffers .gtmOffersNavigation")
      .forEach((item) => {
        item.addEventListener("click", function (event) {
          //Bind the GTM click event for click navigations in this offers component
          fnUpdatePageNameInDataAttr(event.currentTarget, "gtmclickposition");
        });
      });

    document
      .querySelectorAll("#homePageOffers .gtmClickNavigation")
      .forEach((item) => {
        item.addEventListener("click", function (event) {
          //Bind the GTM click event for click navigations in this offers component
          fnUpdatePageNameInDataAttr(event.currentTarget, "gtmclickposition");
          fnClickNavigation(event.currentTarget);
        });
      });
  }, [boolUseEffect]);

  var totalSlidesCount = fields.homePageOffers?.offerItems?.length + 1;
  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        />
      );
    },
    arrows: false,
    dots: false,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    afterChange: () => {
      lazyLoadingImg();
    },
    responsive: [
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: totalSlidesCount > 1 ? 1.3 : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (
    fields.homePageOffers != null &&
    fields.homePageOffers.offerItems != null &&
    fields.homePageOffers.offerItems.length > 0
  ) {
    var leftlink = fields.homePageOffers.leftLink;
    var rightlink = fields.homePageOffers.rightLink;
    var termslink = fields.homePageOffers.termslink;
    var isNewBrandedSite = fields.homePageOffers.isNewBrandedSite;

    return (
      <section
        className={
          ' back-gray offers homePageOffers ' +
          (isNewBrandedSite === true ? ' ' : '') +
          (fields.homePageOffers.displayPictureForOffers === true ? ' ' : '')
        }
        id="homePageOffers"
        data-loadcontentasync={fields.homePageOffers.isPageEditor === true ? false : true}
      >
        <div className='container'>
          <div
            className={
              'header-title d-flex align-items-baseline flex-wrap justify-content-lg-between ' +
              (isNewBrandedSite === true ? '' : ' ')
            }
          >
            <a
              aria-label={fields?.homePageOffers?.personalizedOffers}
              className={
                ' title ' +
                (isNewBrandedSite === true ? '' : '') +
                ' gtmClickNavigation'
              }
              href={leftlink}
            >
              {fields.homePageOffers.personalizedOffers != null &&
                fields.homePageOffers.personalizedOffers != '' && (
                  <>
                    {isNewBrandedSite === true ? (
                      <h2>
                        <span className='news-title'>
                          {fields.homePageOffers.personalizedOffers}
                        </span>
                      </h2>
                    ) : (
                      <h2>{fields.homePageOffers.personalizedOffers}</h2>
                    )}
                  </>
                )}
            </a>
            {/* <a
              href={rightlink}
              aria-label="news-link"
              className={
                'pull-right action ' +
                (isNewBrandedSite === true ? ' picto icon-plus-big' : '') +
                ' gtmClickNavigation'
              }
            >
              {fields.homePageOffers.offersCount != null && isNewBrandedSite === false && (
                <>
                  <strong>{fields.homePageOffers.seeAllOffers}</strong>
                  <> ({fields.homePageOffers.offersCount})</>
                  <span className="picto icon-ico-chevron-right"></span>
                </>
              )}
            </a> */}
          </div>
          <Slider {...settings} className=" row carousel-offers slider-block d-lg-flex" id='carouselOffer'>
            {renderHomepageOffers(fields)}
          </Slider>
          {fields.homePageOffers.isEuropeanSite === true && (
            <div className='more-info'>
              {fields.homePageOffers.cezOffer}
              <> </>
              <a
                href={fields.homePageOffers.termsOfUsePage}
                aria-label={fields.homePageOffers.termsOfUsePage}
              >
                {fields.homePageOffers.conditionsParticulieres}
              </a>
            </div>
          )}
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}

function fnOffersClick(e, offer, fields, link) {
  if (e.target.classList.contains('offers-test')) {
    fnGtmOfferClick(offer.offer.title, offer.storeitem.name, offer.storeitem.retailerName, offer.eligibilityText, false, fields.homePageOffers.isNewBrandedSite);
    URWindowLocation(link)
  }
}

function renderHomepageOffers(fields) {
  let counter = 0;
  const cookies = new Cookies();
  var personlizedblockDislpalyed = false;
  fields.homePageOffers.userLogged =
    cookies.get(fields.homePageOffers.fidCookieName) != null ? true : false;
  var isNewBrandedSite = fields.homePageOffers.isNewBrandedSite;

  let offersBlock = [];
  {
    fields.homePageOffers.offerItems
      .map((offer, index) => {
        var externalOfferLink =
          cookies.get(fields.homePageOffers.uidCookieName) != null &&
            cookies.get(fields.homePageOffers.fidCookieName) != null
            ? offer.externalOfferLink + '?FidID=' + cookies.get(fields.homePageOffers.fidCookieName)
            : offer.externalOfferLink;
        var isExternalOffer =
          offer.commercialOperation != null &&
            offer.commercialOperation.categoryId === fields.homePageOffers.commercialExternalType
            ? true
            : false;
        var strCommercialOperation =
          offer.commercialOperation != null && offer.commercialOperation.name != null
            ? offer.commercialOperation.name
            : '';
        var strEligibility = offer.strEligibility;
        var desc = { value: offer.offer?.description };
        counter++;

        const link =
          isExternalOffer === false && offer.storeLinkURL != null && offer.storeLinkURL != ''
            ? fields.homePageOffers.urlPrefix + offer.storeLinkURL + '#offers'
            : externalOfferLink;
        if (
          fields.homePageOffers.homePageOffersBlockPosition === counter &&
          fields.homePageOffers.userLogged === false &&
          fields.homePageOffers.isLoyaltyDisabled === false
        ) {
          offersBlock.push(
            <div
              key={index}
              className={
                'col-6 col-lg-4 personal-offer-new px-3 bg-white col-md-4 mt-2 mb-3 d-flex justify-content-between flex-column ' +
                (isNewBrandedSite === true ? '' : 'offer-wrap-new') +
                (fields.homePageOffers.isUSUK === true &&
                  fields.homePageOffers.displayImageinHomePage === false
                  ? ' us-uk'
                  : '')
              }
            >
              <PersonalizedBlock model={fields.homePageOffers} />
            </div>
          );
          personlizedblockDislpalyed = true;
        }
        offersBlock.push(
          <div
            className={
              'col-lg-4 col-md-4 position-relative  px-lg-0 mt-2 mb-3 offer-wrap-new  offers-test' + // Adding "offers-test" class to prevent onClick trigger 2 times
              (isNewBrandedSite === true ? '' : '') +
              (fields.homePageOffers.displayImageinHomePage === true ? '' : '') +
              (isExternalOffer === false ? ' gtmOffersNavigation' : '') +
              (counter > 3 ? ' d-lg-none ' : '') +
              (fields.homePageOffers.isUSUK === true &&
                fields.homePageOffers.displayImageinHomePage === false
                ? ' us-uk'
                : '')
            }
            onClick={(e) => fnOffersClick(e, offer, fields, link)}
          >
            {fields.homePageOffers.userLogged && (
              <>
                {offer.offerType != null ? (
                  <input type="hidden" id={'offerType'} value={offer.offerType} />
                ) : (
                  <input type="hidden" id="offerType" value="Regular" />
                )}
              </>
            )}
            <a
              className={'icon-heart-unfill-rw bookmark-news-new bookmark picto  ava-wishlist position-absolute'}
              style={{ zIndex: '2' }}
              href="#"
              aria-label={offer.offer.dealId}
              aria-labelledby="deal"
              data-itemlabel={offer.offer.title}
              data-templatetype="Home Center"
              data-storename={offer.storeitem != null ? offer.storeitem.name : ''}
              data-retailerName={offer.storeitem != null ? offer.storeitem.retailerName : ''}
              role="button"
            ></a>

            <a
              aria-label={fields?.homePageOffers?.displayImageinHomePage}
              className={"header " + (offer.hasLogo ? "justify-content-center" : " ") + " position-relative border-8 bg-white"}
              // {
              //   (fields.homePageOffers.displayImageinHomePage === false
              //     ? 'brand-picture-wrap-new bg-white'
              //     : ' brand-image-wrap position-relative') + ' d-inline-block w-100'
              // }
              href="#"
              role="button"
              onClick={(e) => {
                e.preventDefault();
                return e.currentTarget.closest('.offer-wrap-new').click();
              }}
            >
              {offer.hasLogo &&
                <span className="helper"></span>
              }
              {offer.offerImage != null && offer.offerImage != '' ? (
                <img
                  className={(offer.hasLogo ? "d-inline-block home-offers-image " : "d-flex offer-img-new w-100  p-0 " + (fields.homePageOffers.isUSUK ? "" : ""))}
                  src={fields.homePageOffers.defaultImage}
                  data-alt={offer.offerImage}
                  alt={offer.imgLogoAltText}
                  title={offer.imgLogoAltText}
                />
              ) : (
                <>
                  {offer.storeitem != null && offer.storeitem.storeTypeIds.length > 0 && (
                    <>
                      {offer.storeitem.storeTypeIds.includes(fields.homePageOffers.typeStore) ? (
                        <span className={'picto icon-ico-ph-shops'}></span>
                      ) : (
                        <span className={'picto icon-srv-restaurant'}></span>
                      )}
                    </>
                  )}
                </>
              )}
              {
                isNewBrandedSite ?
                  (offer.eligibilityText !== null && offer.eligibilityText !== '' && baseModel.westfieldClubLogo?.value !== null && baseModel.westfieldClubLogo?.value !== "" && baseModel.westfieldClubLogo?.value.src !== null && baseModel.westfieldClubLogo?.value?.src !== "" &&
                    <div className="westfield-club-logo d-flex align-items-center justify-content-center w-100">
                      <img className="d-flex wclub-img p-0" src={baseModel.westfieldClubLogo?.value?.src} loading="lazy"
                        alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                    </div>
                  )
                  :
                  (offer.eligibilityText !== null && offer.eligibilityText !== '' && baseModel.clubLogo?.value !== null && baseModel.clubLogo?.value !== "" && baseModel.clubLogo?.value.src !== null && baseModel.clubLogo?.value?.src !== "" &&
                    <div className="westfield-club-logo d-flex align-items-center justify-content-center w-100">
                      <img className="d-flex wclub-img p-0" src={baseModel.clubLogo?.value?.src} loading="lazy"
                        alt={baseModel.clubLogo?.value?.alt} title="club" />
                    </div>
                  )
              }
            </a>
            <a className='offer-content bg-transparent mt-2 d-block text-center' role="button">
              <div
                className='text-start retailer-name my-1'
                onClick={(e) => {
                  e.preventDefault();
                  return e.currentTarget.closest('.offer-wrap-new').click();
                }}
              >
                {offer.storeitem != null && <>{offer.storeitem.name}</>}
              </div>
              <div
                className={'offer-title-new'}
                onClick={(e) => {
                  e.preventDefault();
                  return e.currentTarget.closest('.offer-wrap-new').click();
                }}
              >
                {offer.offer.title}
              </div>
              <div className="date text-start my-1"
                onClick={(e) => {
                  e.preventDefault();
                  return e.currentTarget.closest('.offer-wrap-new').click();
                }}
              >
                {offer.validity}
              </div>

              <div className="d-flex discount-block">
                {offer.offer.sticker !== null && offer.offer.sticker !== "" && offer.offer.sticker !== undefined &&
                  <span
                    className=
                    'discount-new rounded-3'
                    onClick={(e) => {
                      e.preventDefault();
                      return e.currentTarget.closest('.offer-wrap-new').click();
                    }}
                  >
                    {offer.offer.sticker}
                  </span>
                }
                {offer.offer.subtitle !== null && offer.offer.subtitle !== "" && offer.offer.subtitle !== undefined &&
                  <span
                    className={'discount-description text-start' + (offer.offer.sticker !== null && offer.offer.sticker !== "" && offer.offer.sticker !== undefined ? "" : " p-0")}
                    // role="button"
                    // href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      return e.currentTarget.closest('.offer-wrap-new').click();
                    }}
                  >
                    {offer.offer.subtitle}
                  </span>
                }
              </div>
              {fields.homePageOffers.isUSUK && offer.offer.description != null && offer.offer.description != '' && (
                <div
                  className={
                    'discount-description text-start p-0 my-1'
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    return e.currentTarget.closest('.offer-wrap-new').click();
                  }}
                >
                  <RichText field={desc} />
                </div>
              )}
            </a >
            <div className="text-start bg-transparent  my-2" >
              <a aria-label={fields?.homePageOffers?.homePageOffersText} href="#" className="grid-new-cta pe-1" onClick={(e) => {
                e.preventDefault();
                return e.currentTarget.closest('.offer-wrap-new').click();
              }}> {fields.homePageOffers.homePageOffersText}</a>
              <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5" />
            </div >
          </div >
        );
        if (
          ((fields.homePageOffers.offerItems.length === 1 &&
            fields.homePageOffers.userLogged === false &&
            personlizedblockDislpalyed === false) ||
            (fields.homePageOffers.offerItems.length === counter &&
              personlizedblockDislpalyed === false &&
              fields.homePageOffers.userLogged === false)) &&
          fields.homePageOffers.isLoyaltyDisabled === false
        ) {
          offersBlock.push(
            <div
              className={
                'col-6 col-lg-4 personal-offer-new px-3 bg-white col-md-4 mt-2 mb-3 d-flex justify-content-between flex-column ' +
                (isNewBrandedSite ? '' : 'offer-wrap-new ') +
                (fields.homePageOffers.isUSUK === true &&
                  fields.homePageOffers.displayImageinHomePage === false
                  ? ' us-uk'
                  : '')
              }
            >
              <PersonalizedBlock model={fields.homePageOffers} />
            </div>
          );
        }
      });
  }
  return offersBlock;
}
