import React, { useEffect, useState } from "react";
import {
  CommonBookmarkallpage,
  categoryFilter,
  dosubCategoryFilter,
  fnGtmCategorySearchFilter,
  lazyLoadingImg,
  letterFilter
} from "../Js-CommonFunctions";

function NewsStoreCountCondition(fields, urlPrefix, filterType) {
  var newStoreRestGTM = "";
  var offerStoreRestGTM = "";
  if (
    fields.listOfShopsRestaurent.newStoreCount > 0 ||
    fields.listOfShopsRestaurent.offersStoreCount > 0
  ) {
    if (fields.listOfShopsRestaurent.newStoreCount > 0) {
      newStoreRestGTM = fields.listOfShopsRestaurent.storetype.toLowerCase() === "shopping"
        ? fields.listOfShopsRestaurent.storesNewStoreGTM
        : fields.listOfShopsRestaurent.restaurantsNewStoreGTM
    }
    if (fields.listOfShopsRestaurent.offersStoreCount) {
      offerStoreRestGTM = fields.listOfShopsRestaurent.storetype.toLowerCase() ===
        "shopping"
        ? fields.listOfShopsRestaurent.storeWithOffersGTM
        : fields.listOfShopsRestaurent.restaurantsWithOffersGTM
    }
    return (
      <>
        {fields.listOfShopsRestaurent.newStoreCount > 0 && (
          <li><a
            href={urlPrefix + fields.listOfShopsRestaurent.newstoreFilterURL}
            className={fields.listOfShopsRestaurent.filterNewStoreClass}
            onClick={(e) => fnGtmCategorySearchFilter(e.target, newStoreRestGTM, "", fields.listOfShopsRestaurent.newStoreCount, fields.listOfShopsRestaurent.storeTypegtm, fields.listOfShopsRestaurent.storeTypegtm + " List")}
            data-testid="testClick1"
          >
            {(fields.listOfShopsRestaurent.storetype.toLowerCase() ===
              "shopping"
              ? fields.listOfShopsRestaurent.storesNewStore
              : fields.listOfShopsRestaurent.restaurantsNewStore) +
              " " +
              "(" +
              fields.listOfShopsRestaurent.newStoreCount +
              ")"}
          </a>
          </li>
        )}
        {fields.listOfShopsRestaurent.offersStoreCount > 0 && (
          <li><a
            href={urlPrefix + fields.listOfShopsRestaurent.offerFilterURL}
            className={fields.listOfShopsRestaurent.filterOfferStoreClass}
            data-testid="testClick2"
            onClick={(e) => fnGtmCategorySearchFilter(e.target, offerStoreRestGTM, "", fields.listOfShopsRestaurent.offersStoreCount, fields.listOfShopsRestaurent.storeTypegtm, fields.listOfShopsRestaurent.storeTypegtm + " List")}
          >
            {(fields.listOfShopsRestaurent.storetype.toLowerCase() ===
              "shopping"
              ? fields.listOfShopsRestaurent.storeWithOffers
              : fields.listOfShopsRestaurent.restaurantsWithOffers) +
              " " +
              "(" +
              fields.listOfShopsRestaurent.offersStoreCount +
              ")"}
          </a>
          </li>
        )}
        <hr />
      </>
    );
  }
}

function ListofShopRestaurantCategoryInner(fields, urlPrefix, filterType) {
  if (fields.listOfShopsRestaurent.storetype.toLowerCase() === "shopping") {
    return (
      fields.listOfShopsRestaurent.parentCategoryList !== null &&
      fields.listOfShopsRestaurent.parentCategoryList.map(
        (parentCategory, index) => {
          let parentSelectedClass = "";
          if (
            parentCategory.display_Name.toLowerCase() ===
            fields.listOfShopsRestaurent.strParentCategory.toLowerCase()
          ) {
            if (
              fields.listOfShopsRestaurent.strChildCategory === null ||
              fields.listOfShopsRestaurent.strChildCategory === ""
            ) {
              parentSelectedClass = "selected";
            } else {
              parentSelectedClass = "active";
            }
          }
          if (
            parentCategory.nbOfStores != null &&
            parentCategory.nbOfStores !== 0
          ) {
            return (
              <li key={index}>
                <a
                  href={urlPrefix + parentCategory.parentCategoryFilterURL}
                  onClick={(e) => fnGtmCategorySearchFilter(e.target, parentCategory.display_Name, "", parentCategory.nbOfStores, "Stores", "Stores List")}
                  className={"collapsed " + parentSelectedClass}
                  aria-expanded={parentSelectedClass !== "" ? " true" : "false"}
                  data-testid="testClick3"
                >
                  {parentCategory.display_Name +
                    " (" +
                    (parentCategory.nbOfStores != null
                      ? parentCategory.nbOfStores
                      : 0) +
                    ")"}
                </a>

                {fields.listOfShopsRestaurent.categoryList !== null && (
                  <ul
                    className={
                      "filter-item-list" +
                      (parentSelectedClass !== "" ? "" : " collapse") +
                      (parentSelectedClass === " selected"
                        ? parentSelectedClass
                        : "")
                    }
                    id={fields.listOfShopsRestaurent.parentCategoryFilterURL}
                    aria-expanded={parentSelectedClass ? "true" : "false"}
                    style={{ height: parentSelectedClass !== "" ? "" : "0px;" }}
                  >
                    {fields.listOfShopsRestaurent.categoryList.map(
                      (subCategory, index) => {
                        let childSelectedClass = "";
                        if (
                          subCategory.display_Name.toLowerCase() ===
                          fields.listOfShopsRestaurent.strChildCategory.toLowerCase()
                        ) {
                          childSelectedClass = "selected";
                        }
                        return (
                          subCategory.parent_ID === parentCategory.id && (
                            <li>
                              {" "}
                              <a
                                href={
                                  urlPrefix + subCategory.childcategoryFilterURL
                                }
                                onClick={(e) => fnGtmCategorySearchFilter(e.target, subCategory.display_Name, "", subCategory.nbOfStores, "Stores", "Stores List")}
                                className={childSelectedClass}
                                data-analyticslabel={subCategory.display_Name}
                                data-testid="testClick4"
                              >
                                {subCategory.display_Name +
                                  " (" +
                                  ((subCategory.nbOfStores != null
                                    ? subCategory.nbOfStores
                                    : 0) +
                                    ")")}
                              </a>
                            </li>
                          )
                        );
                      }
                    )}
                  </ul>
                )}
              </li>
            );
          }
        }
      )
    );
  } else if (
    fields.listOfShopsRestaurent.storetype.toLowerCase() === "restaurants"
  ) {
    return (
      fields.listOfShopsRestaurent.parentCategoryList !== null &&
      fields.listOfShopsRestaurent.categoryList.map((subCategory, index) => {
        let childSelectedClass = "";
        if (
          subCategory.display_Name.toLowerCase() ===
          fields.listOfShopsRestaurent.strParentCategory.toLowerCase()
        ) {
          childSelectedClass = "selected";
        }
        return (
          subCategory !== null && (
            <a
              href={urlPrefix + subCategory.subCategoryFilterURL}
              className={childSelectedClass}
              onClick={(e) => fnGtmCategorySearchFilter(e.target, subCategory.display_Name, "", subCategory.nbOfStores, "Restaurants", "Restaurants List")}
              data-testid="testClick5"
            >
              {subCategory.display_Name +
                " (" +
                (subCategory.nbOfStores != null ? subCategory.nbOfStores : 0) +
                ")"}
            </a>
          )
        );
      })
    );
  }
}

export default function ListofShopRestaurantCategory({ fields }) {
  let str0 = "";
  if (
    fields.listOfShopsRestaurent?.storeDetails != null &&
    fields.listOfShopsRestaurent?.storeDetails.length > 0
  ) {
    str0 = fields.listOfShopsRestaurent.storeDetails[0].display_Name;
  }

  let storeAlphabets = JSON.stringify(
    fields.listOfShopsRestaurent?.storeAlphabets
  );
  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render

  useEffect(() => {
    if (storeAlphabets !== null) {
      if (document.getElementById("products") !== null) {
        dosubCategoryFilter(str0 != undefined ? str0 : "", storeAlphabets, []);
        lazyLoadingImg();
      }
    }
    setBoolUseEffect(true);

  }, []);

  useEffect(() => {
    CommonBookmarkallpage();
    if (window.innerWidth < 991) {
      CommonBookmarkallpage();
    }
  }, [boolUseEffect]);

  const urlPrefix =
    fields.listOfShopsRestaurent?.urlPrefix !== ""
      ? fields.listOfShopsRestaurent?.urlPrefix
      : "";
  const count =
    fields.listOfShopsRestaurent !== null &&
      fields.listOfShopsRestaurent !== undefined
      ? fields.listOfShopsRestaurent.totalShopCount
      : 0;
  const filterType =
    fields.listOfShopsRestaurent?.filterType === 1
      ? "filterRestaurants"
      : "filterStores";

  if (
    fields.listOfShopsRestaurent !== null &&
    fields.listOfShopsRestaurent !== undefined
  ) {
    return (
      <section
        className={
          "filter-area" +
          (fields.listOfShopsRestaurent.isNewBrandedSite ? " " : "") +
          " new stores stores-new "
        }
        id="products"
      >
        <div className="container">
          <div className="row filter-wrap">
            <div className="col-12 col-lg-3 filter-category-wrap mb-4">
              <div
                className="action btn-show-filter collapsed"
                id="categoryfilter"
                data-bs-toggle="collapse"
                data-bs-target="#brandFilter"
                aria-expanded="false"
                aria-controls="brandFilter"
              >
                <a
                  className={
                    "link-button text-uppercase" +
                    (fields.listOfShopsRestaurent.isNewBrandedSite
                      ? ""
                      : " ") +
                    " btn-see-more link-expandable"
                  }
                >
                  {fields.listOfShopsRestaurent.filterByDict}
                  <span className="picto icon-go-down-arrow"></span>
                </a>
              </div>
              <div id="brandFilter" className="brandfilter collapse">
                {fields.listOfShopsRestaurent.categoryDict && (
                  <h2 className="filter-wrap-title fw-bold text-uppercase">
                    {fields.listOfShopsRestaurent.categoryDict}
                  </h2>
                )}
                <ul
                  className={
                    "filter-category-list " +
                    (fields.listOfShopsRestaurent.isNewBrandedSite
                      ? "selected"
                      : fields.listOfShopsRestaurent.allSelectedMain)
                  }
                  id="filter-category-list"
                >
                  <li>
                    <a
                      href={
                        urlPrefix + fields.listOfShopsRestaurent.strLandingPage
                      }
                      id="AtoZtag"
                      className={fields.listOfShopsRestaurent.allSelected}
                      onClick={(e) => {
                        categoryFilter("All", e.currentTarget);
                        fnGtmCategorySearchFilter(e.target, fields.listOfShopsRestaurent.shopfromAtoZDictGtm, "", count, fields.listOfShopsRestaurent.storeTypegtm, fields.listOfShopsRestaurent.storeTypegtm + " List")
                      }}
                      data-testid="testClick6"
                    >
                      {fields.listOfShopsRestaurent.shopfromAtoZDict +
                        " (" +
                        count +
                        ")"}
                    </a>
                  </li>
                  <hr />
                  {NewsStoreCountCondition(fields, urlPrefix, filterType)}
                  {ListofShopRestaurantCategoryInner(
                    fields,
                    urlPrefix,
                    filterType
                  )}
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-9 filter-result-wrap">
              <div className="filter-alphabetic-old letters">
                <a
                  href="javascript:void(0);"
                  onClick={(e) =>
                    letterFilter(
                      "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,Others,LoyaltyPillar",
                      e.currentTarget
                    )
                  }
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter active"
                  data-testid="testClick7"
                >
                  <span className="vertical-align text-uppercase all ">
                    {fields.listOfShopsRestaurent.filterAll}
                  </span>
                </a>
                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("A,B", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                  data-testid="testClick8"
                >
                  <span className="vertical-align text-uppercase ">a b</span>
                </a>
                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("C,D", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">c d</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("E,F", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">e f</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("G,H", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">g h</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("I,J", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">i j</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("K,L", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">k l</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("M,N", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">m n</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("O,P", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">o p</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("Q,R", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">q r</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("S,T", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">s t</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("U,V", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">u v</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("W,X", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">w x</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("Y,Z", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">y z</span>
                </a>

                <a
                  href="javascript:void(0);"
                  onClick={(e) => letterFilter("Others", e.currentTarget)}
                  className="filter  bg-lighter-plus  text-dark disablehref analytics_filterLetterClk gtmFilter"
                >
                  <span className="vertical-align text-uppercase ">0-9</span>
                </a>
              </div>
              <div className="filter-result row" id="letterFilterResult">
                {BrandListForLoop(fields.listOfShopsRestaurent, urlPrefix)}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}

let isLazyLoadEnabled = false;

function BrandListForLoop(fields, urlPrefix) {


  let loyaltyPillarIndex = 0;
  let nextPillarDisplay = 5;
  let count = 0;

  if (fields.displaystoreitems !== null) {
    let tempvalue = "";
    let parent = [];
    let blockCount = 0;

    let store;

    for (let i = 0; i < fields.displaystoreitems.length;) {
      let store = fields.displaystoreitems[i];
      if (
        store != null &&
        store.display_Name !== "" &&
        !store.display_Name.substring(0, 1).toLowerCase() === tempvalue
      ) {
        tempvalue = store.display_Name.substring(0, 1).toLowerCase();
      }

      for (; i < fields.displaystoreitems.length; i++) {
        store = fields.displaystoreitems[i];
        if (
          store != null &&
          store.display_Name !== "" &&
          !store.display_Name.substring(0, 1).toLowerCase() === tempvalue
        ) {
          return <></>;
        }
        blockCount++;
        let isPillarDisplay = false;
        let pillarItem;
        let loyaltyGTMClickPostion;
        let hascCurbside = store.curbsideUrl !== "";
        let isLoyalityPageExists = false;
        isLoyalityPageExists = fields.isUS
          ? (isLoyalityPageExists = true)
          : (isLoyalityPageExists =
            fields.loyaltyPorgramExists && fields.is_Loyalty_Page_Exists
              ? true
              : false);
        if (
          blockCount == nextPillarDisplay &&
          isLoyalityPageExists &&
          fields.pillarItems != null &&
          fields.pillarItems.length > 0
        ) {
          nextPillarDisplay = nextPillarDisplay + 9;
          pillarItem = fields.isLPV2Site
            ? loyaltyPillarIndex % fields.pillarItems.length
            : loyaltyPillarIndex % fields.pillarItems.length;
          if (fields.pillarItems[pillarItem] != null) {
            loyaltyGTMClickPostion =
              (fields.pillarItems[pillarItem].pillarGTMPushName !== ""
                ? fields.pillarItems[pillarItem].pillarGTMPushName
                : "") +
              "-" +
              (blockCount + loyaltyPillarIndex) +
              "-" +
              fields.store_GTM_Type;

            isPillarDisplay = true;
            loyaltyPillarIndex++;
          }
        }

        parent.push(
          <>
            {isPillarDisplay && (
              <div
                key={i}
                className={
                  "categoryListFilter letter-filterLoyaltyPillar" +
                  (fields.isCategoryFilterComponent
                    ? " col-lg-3"
                    : " col-lg-4") +
                  " col-12 mb-1 mb-lg-3  gtmClickNavigation"
                }
                data-title=""
                data-name="LoyaltyPillar"
                data-alt="LoyaltyPillar"
              >
                {fields.isClubPage &&
                  <div className={"premium-block personal-offer-new bg-white position-relative block-link " + (fields.isUSUK ? (fields.uS_UK_STORELOGO + "-pillars") : "")}>
                    <div className="d-flex h-100">
                      <div className="card card-inverse pillars-card-border rounded-3 border-0 w-100">
                        <div className="card-block rounded-3">
                          <div className="d-flex justify-content-center rounded-3">
                            {fields.pillarItems[pillarItem].pillarHasImage && fields.pillarItems[pillarItem]?.pillarImageMediaURL !== null && fields.pillarItems[pillarItem]?.pillarImageMediaURL !== "" && !fields.isMobileView &&
                              <img src={fields.pillarItems[pillarItem]?.pillarImageMediaURL} loading="lazy" alt={fields.pillarItems[pillarItem]?.pillarImageAlt} className="w-100 pillars-card-img">
                              </img>
                            }
                            {fields.pillarItems[pillarItem].pillarHasMobileImage && fields.pillarItems[pillarItem]?.pillarImageMobileMediaURL !== null && fields.pillarItems[pillarItem]?.pillarImageMobileMediaURL !== "" && fields.isMobileView &&
                              <img src={fields.pillarItems[pillarItem]?.pillarImageMobileMediaURL} loading="lazy" alt={fields.pillarItems[pillarItem]?.pillarMobileImageAlt} className="w-100 pillars-card-img">
                              </img>
                            }
                            {((!fields.pillarItems[pillarItem].pillarHasImage && !fields.isMobileView) || (!fields.pillarItems[pillarItem].pillarHasMobileImage && fields.isMobileView)) &&
                              <span
                                className={"picto " + fields.pillarItems[pillarItem].pillarPicto}
                              ></span>
                            }
                          </div>
                          <div className="d-flex justify-content-center position-relative align-items-center">
                            <span className="position-absolute pillars-card-title text-white">{fields.pillarItems[pillarItem].pillarTitle}</span>
                            {fields.pillarItems[pillarItem].pillarTitleImageURL !== null && fields.pillarItems[pillarItem].pillarTitleImageURL !== undefined && fields.pillarItems[pillarItem].pillarTitleImageURL !== "" &&
                              <img src={fields.pillarItems[pillarItem].pillarTitleImageURL} alt="" loading="lazy" className="w-100 pillars-card-title-img"></img>
                            }
                          </div>
                          <div className="d-flex justify-content-center pillars-title">{fields.pillarItems[pillarItem]?.pillarLongDescrition}
                          </div>
                          <a className="d-flex justify-content-center pillars-header text-black text-decoration-underline" href={fields.pillarItems[pillarItem].pillarLink}>
                            {fields.pillarItems[pillarItem].pillarLinkText}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {!fields.isClubPage &&
                  <div
                    className={
                      "premium-block personal-offer-new bg-white position-relative" +
                      (!fields.isNewBrandedSite
                        ? " loyalty-bg"
                        : "")
                    }
                  >
                    <div className="loyalty-wrap bg-white d-flex justify-content-between flex-column">
                      <a
                        className="helper"
                        href={fields.pillarItems[pillarItem].pillarLink}
                      ></a>
                      <div className="header mb-0 position-relative">
                        {bandListInnerfn(fields, pillarItem, i, count)}
                      </div>
                      <div className="loyalty-details d-flex justify-content-center text-start flex-column">
                        <div className="title text-uppercase fw-bold m-0">
                          {fields.pillarItems[pillarItem].pillarTitle}
                        </div>
                        <div className="description text-start m-0">
                          {" "}
                          {fields.pillarItems[pillarItem].pillarLongDescrition}
                        </div>
                        <div className="text-start">
                          <a
                            href={fields.pillarItems[pillarItem].pillarLink}
                            className="grid-new-cta"
                          >
                            {fields.pillarItems[pillarItem].pillarLinkText}
                          </a>
                          <span
                            className={
                              "picto icon-arrow-square-red-right-rw align-middle px-0 fs-5"
                            }
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>
            )}

            {AlphabetsFilterResult(
              fields,
              store,
              urlPrefix,
              hascCurbside,
              i,
              count
            )}
          </>
        );
        count++;
      }
      return parent;
    }
  }
}


function AlphabetsFilterResult(
  fields,
  store,
  urlPrefix,
  hascCurbside,
  i,
  count
) {
  {
    var gtmbookmarktype = fields.storetype === ""
      ? ""
      : fields.storetype.toLowerCase() === fields.restaurants
        ? fields.gtM_BOOKMARK_TYPE_RESTAURANT
        : fields.gtM_BOOKMARK_TYPE_STORE
  }
  return (
    <div
      key={i}
      className={
        "categoryListFilter letter-filter letter-filter " +
        store.checkforothers +
        (fields.isCategoryFilterComponent ? " col-lg-3" : " col-lg-4") +
        " " +
        "col-12 mb-1 mb-lg-3"
      }
      data-title={store.strCategory}
      data-name={
        store != null && store.display_Name !== ""
          ? store.display_Name.substring(0, 1).toUpperCase()
          : ""
      }
      data-alt={
        store != null && store.checkforothers !== ""
          ? store.checkforothers.toLowerCase()
          : ""
      }
    >
      <div
        className={
          "thumbnail  position-relative border-0 rounded-0 p-0 text-center" +
          (fields.isUSUK ? fields.uS_UK_STORELOGO : "") +
          (hascCurbside || store.clickAndCollect ? " curbside-block" : "")
        }
      >
        <a
          href="javascript:void(0);"
          className={
            (fields.isNewBrandedSite ? "" : " ") + "icon-heart-unfill-rw bookmark-news-new bookmark picto  ava-wishlist position-absolute"

          }
          aria-label={store.storeId.toLowerCase()}
          aria-labelledby={fields.storetype !== "" && fields.storetype.toLowerCase() === fields.restaurants
            ? "restaurant" : "store"}
          data-itemlabel={store.display_Name}
          data-templatetype={fields.storetype !== "" && fields.storetype.toLowerCase() === fields.restaurants
            ? "Restaurants List" : "Stores List"}
        ></a>

        <a
          href={
            store != null && store.urlPath !== ""
              ? urlPrefix + fields.cslash + store.urlPath
              : " javascript:void(0);"
          }
          className="position-relative"
        >
          {store.clickAndCollect && (
            <div className="curbside loyalty-text fw-bold position-absolute text-start text-uppercase">
              {fields.clickAndCollectText}
            </div>
          )}
          {hascCurbside && !store.clickAndCollect && (
            <div className="curbside loyalty-text fw-bold position-absolute text-start text-uppercase">
              {store.curbsideText}
            </div>
          )}

          {store.isNewStore && (
            <div
              className={
                "new-tap loyalty-text fw-bold position-absolute text-start text-uppercase" +
                (fields.isNewBrandedSite ? "" : " pink" + "")
              }
            >
              {fields.newTagText}
            </div>
          )}
          {store.isComingSoon && (
            <div
              className={
                "new-tap loyalty-text fw-bold position-absolute text-start text-uppercase" +
                (fields.isNewBrandedSite ? "" : " pink" + " ")
              }
            >
              {fields.comingSoon}
            </div>
          )}
          <div className="header">
            {/* <span className="helper"></span> */}
            <img
              loading="lazy"
              src={store.imgUrl}
              className="align-middle"
              alt={store.imgAltText === "" ? "" : store.imgAltText}
              title={store.imgAltText === "" ? "" : store.imgAltText}
            />
          </div>
        </a>
        <div className="offer-content bg-transparent mt-2 d-flex flex-column text-start">
          <a
            className={
              "offer-title-new" +
              (fields.isNewBrandedSite ? " " : "")
            }
            href={urlPrefix + fields.cslash + store.urlPath}
          >
            {store.display_Name === "" ? "" : store.display_Name}
          </a>
          {storesCondition(fields, store, urlPrefix)}
          <a
            className="text-start area-map my-1"
            href={urlPrefix + fields.cslash + store.urlPath}
          >
            {store.level === "" && store.zone === ""
              ? ""
              : store.level + " " + store.zone}
          </a>
          {store.offersCount > 0 && (
            <>
              <a
                className={
                  "offer-tap-stores fw-bold d-block text-start  " +
                  (fields.isLPV2Site ? " premium-text" : " loyalty-text")
                }
                href={
                  urlPrefix +
                  fields.cslash +
                  store.urlPath +
                  (store.offersCount > 0 ? "#" + fields.evenT_OFFERS : "")
                }
              >
                <span
                  className={
                    "picto align-middle pe-2" +
                    (store.viPIconDisplay === true ? " icon-vip-card" : "")
                  }
                ></span>
                <span>{store.offersCount + " " + store.offersDictionary} </span>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function storesCondition(fields, store, urlPrefix) {
  if (store.openingHours.length > 0 && store.isOpen && !store.isComingSoon) {
    {
      return store.openingHours.map((opening, index) => {
        return (
          <a key={index}
            className="date-stores text-start my-1"
            href={urlPrefix + fields.cslash + store.urlPath}
          >
            {opening}
          </a>
        );
      });
    }
  }
  else if (!store.IsOpen && !store.isComingSoon) {
    return (
      <a
        className="date-stores text-start my-1"
        href={urlPrefix + fields.cslash + store.urlPath}
      >
        {store.openingHours}
      </a>
    );
  }
}

function bandListInnerfn(fields, pillarItem, i, count) {
  if (!fields.pillarItems[pillarItem].pillarHasImage) {
    return (
      <span
        className={"offer-logo d-flex justify-content-center picto " + fields.pillarItems[pillarItem].pillarPicto}
      ></span>
    );
  } else if (!fields.pillarItems[pillarItem].pillarImageMediaURL !== "") {
    return (
      <img
        loading="lazy"
        src={fields.pillarItems[pillarItem].pillarImageMediaURL}
        alt={fields.pillarItems[pillarItem].pillarImageAlt}
      />
    );
  }
}
