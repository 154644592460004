import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';


export default function OpeningHours({ fields }) {

  if (fields.openinghrs != null) {
    let divMapping = 0;
    let offerbgstyle = 0;
    const divhomemapping = 100;
    let openingHoursClassName = "";

    return (
      (fields.openinghrs.customOpeningHrslist.length > 0) &&
      <section>
        <div className="container">
          <div className={fields.openinghrs.cls1}>
            {(fields.openinghrs.title !== "" && fields.openinghrs.title !== null) &&
              <h2 className={(fields.openinghrs.isNewBrandedSite ? " page-title" : " content-title") + "text-uppercase text-center" + (fields.openinghrs.isNewBrandedSite ? "" : " underline")}>{fields.openinghrs.title}</h2>

            }
            <div className="row isflex">

              {fields.openinghrs.customOpeningHrslist.map((itemblock, index) => {
                divMapping++;
                var openingHrslistName = { value: itemblock.openingHrslist.name !== "" && itemblock.openingHrslist.name !== null ? itemblock.openingHrslist.name : "" }
                var pictogramWithStyle = { value: itemblock.openingHrslist.pictogramWithStyle !== "" && itemblock.openingHrslist.pictogramWithStyle !== null ? itemblock.openingHrslist.pictogramWithStyle : "" }
                var hasPicto = itemblock.openingHrslist.pictogram !== "" && itemblock.openingHrslist.pictogram !== null && itemblock.openingHrslist.pictogram !== undefined ? true : false
                if (offerbgstyle == 0) { openingHoursClassName = "bg-main  text-light"; }
                if (offerbgstyle == 1) { openingHoursClassName = "bg-light  text-light"; }
                if (offerbgstyle == 2) { openingHoursClassName = "bg-dark  text-light"; }
                if (offerbgstyle == 3) { openingHoursClassName = "bg-lighter  text-light"; }
                offerbgstyle++;

                return (

                  (fields.openinghrs.isNewBrandedSite) ?
                    (
                      <div key={index} className="col-md-3 col-sm-6 col-12">
                        <div className={"item" + (fields.openinghrs.cls3)} style={fields.openinghrs.cls4}>
                          {hasPicto &&
                            <div className="picto-wrap">
                              {(pictogramWithStyle !== "") &&
                                <RichText field={pictogramWithStyle} />
                              }
                            </div>
                          }
                          {(openingHrslistName !== "") &&
                            <h3 className="whats-hot-title text-uppercase">
                              <span>
                                <RichText field={openingHrslistName} />
                              </span>
                            </h3>

                          }
                          <div className="description" style={fields.openinghrs.cls5}>

                            {itemblock.seeAllOpeningHours.map((showTime, index) => {
                              return (
                                <p key={index}>
                                  <span>
                                    {showTime.days}
                                  </span>
                                  :<br />
                                  <span className=" title-light">{showTime.eachShowTimesKeys}</span>
                                </p>
                              )
                            })}
                          </div>
                        </div>

                      </div>

                    ) :
                    <>


                      <div id={divMapping} className={"col-12 col-md-6 col-lg-3 col " + openingHoursClassName}>
                        <div className={"item" + fields.openinghrs.cls3} style={fields.openinghrs.cls4}>
                          {hasPicto &&
                            <div className="picto-wrap">
                              {(pictogramWithStyle !== "") &&
                                <RichText field={pictogramWithStyle} />
                              }
                            </div>
                          }
                          {(openingHrslistName !== "") &&
                            <h3 className="whats-hot-title text-uppercase">
                              <span>
                                <RichText field={openingHrslistName} />
                              </span>
                            </h3>
                          }
                          <div className="description" style={fields.openinghrs.cls5}>
                            {itemblock.seeAllOpeningHours.map((showTime, index) => {
                              return (
                                <p key={index}>
                                  <span>
                                    {showTime.days}
                                  </span>
                                  :<br />
                                  <span className=" title-light">{showTime.eachShowTimesKeys}</span>
                                </p>
                              )
                            })}
                          </div>
                        </div>
                      </div>


                    </>

                )


              })}
            </div>
          </div>
        </div>
      </section>
    );

  } else {
    return <div></div>;
  }
}

