import React from "react";
import { baseModel } from "../../RouteHandler";
import store from "../../redux/store";
import { URWindowLocation, fnConnectRedirectTodetailPage, fnRedirectToConnectLogin, openLoyaltyCardBarCodePopin } from "../Js-CommonFunctions";

export default function HowToEnjoyIt({ model, constants }) {
  const state = store.getState();
  const visitorId = state?.user?.visitorId;
  const barCode = state?.user?.barCode;

  if (model != null) {
    //var loyalityClass = constants.isLPV2Site && !constants.isNewBrandedSite ? "btn-loyalty" : "btn-dark"
    return (
      <div className="col-12 col-lg-4 ps-lg-3 pe-lg-0">
        <div className=" enjoy-cta-block px-4 py-4 text-center bg-white">
          <div className="title text-uppercase easyparking-header text-start mb-3 mt-3 mb-lg-3">{model.title}</div>
          <div className="description text-start text-midgray mb-4">
            {model.description}
          </div>
          {(visitorId !== null && visitorId !== undefined && visitorId !== "") ?
            (
              <> <button aria-label={model?.title} className="d-lg-none easy-parking-content-button btn btn-dark" data-testid="checkServiceBookingBugUrl" onClick={(e) => checkServiceBookingBugUrl(model.buttonLink, barCode)} >{model.buttonText}</button>
                <a href={model.buttonLink} aria-label={model?.title} className="d-none d-lg-inline-block easy-parking-content-button btn btn-dark " >{model.buttonText}</a>
              </>
            ) :
            (
              <> <button  className="d-lg-none easy-parking-content-button btn btn-dark " id="service_bookingbug_cta_mobile" data-testid='bookingbugcta_mobile' onClick={(e) => { fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }}>{model.buttonText}</button>
                <button  className="d-none d-lg-inline-block easy-parking-content-button btn btn-dark " id="service_bookingbug_cta" data-testid="bookingbugcta" onClick={(e) => { fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }}>{model.buttonText}</button>
              </>
            )
          }
        </div>
      </div>
    )
  } else {
    return <></>;
  }
}


function checkServiceBookingBugUrl(url, barcode) {
  if (url != null && url !== "") {
    var url_account = url.substring(url.lastIndexOf('/'));
    if (url_account === baseModel.profilePage) {
      openLoyaltyCardBarCodePopin(barcode);
    } else {
      URWindowLocation(url);
    }
  }
}
