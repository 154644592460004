import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setAccessToken,
  setAccountCreationDate,
  setAquisitionPcCode,
  setBarCode,
  setBirthday,
  setBookmarkedEvent,
  setBookmarkedOffer,
  setBookmarkedRestaurant,
  setBookmarkedStore,
  setEmail,
  setFavCenters,
  setFirstName,
  setInitials,
  setLastName,
  setLogin,
  setRefreshToken,
  setVisitorCommunityName,
  setVisitorId,
  setWorkerCenters
} from '../../redux/user/actions';
import { createCookie, erasecookie, readCookie } from '../Js-CommonFunctions';

export default function LoginConnect({ fields }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (fields.visitorData !== null && fields.visitorData !== undefined && fields.visitorData !== "") {
      createCookie("UUID", "true", 1);
      dispatch(setVisitorId(fields.visitorData.visitorId));
      dispatch(setAccessToken(fields.visitorData.accessToken));
      dispatch(setRefreshToken(fields.visitorData.refreshToken));
      dispatch(setAquisitionPcCode(fields.visitorData.aquisitionPcCode));
      dispatch(setEmail(fields.visitorData.email));
      dispatch(setFirstName(fields.visitorData.firstName));
      dispatch(setLastName(fields.visitorData.lastName));
      dispatch(setInitials(fields.visitorData.visitorInitials));
      dispatch(setWorkerCenters(fields.visitorData.workerCenters));
      dispatch(setBarCode(fields.visitorData.barcode));
      dispatch(setBirthday(fields.visitorData.birthdate));
      dispatch(setAccountCreationDate(fields.visitorData.accountCreationDate));
      dispatch(setFavCenters(fields.visitorData.favCenterlables));
      dispatch(setBookmarkedOffer(fields.visitorData.bookmarkedOffer));
      dispatch(setBookmarkedStore(fields.visitorData.bookmarkedStores));
      dispatch(setBookmarkedRestaurant(fields.visitorData.bookmarkedRestaurants));
      dispatch(setBookmarkedEvent(fields.visitorData.bookmarkedEvent));
      dispatch(setLogin(true));
      dispatch(setVisitorCommunityName(fields.visitorData.communityGenericName))
      let url = "/";
      let detailPage = readCookie("detailPage");
      let parkingActivation = readCookie("ParkingActivation");

      if (detailPage) {
        url = detailPage;
        erasecookie("detailPage");
      }
      else if (parkingActivation) {
        url = parkingActivation;
      }
      else if (fields.visitorData.redirectToPage) {
        url = fields.visitorData.languageAndVirtualPath + fields.visitorData.redirectToPage;
      }
      else if (fields.visitorData?.pageName?.toLowerCase() === 'homepage') {
        url = fields.visitorData.languageAndVirtualPath === "" ? "/" : fields.visitorData.languageAndVirtualPath;
      }
      else {
        url = fields.visitorData.languageAndVirtualPath + '/' + fields.visitorData.pageName;
      }

      window.location.href = url;
    }
  }, []);

  if (fields.visitorData !== null && fields.visitorData !== undefined && fields.visitorData !== "") {
    return (
      <>
        <div className="d-flex justify-content-center loader-login fs-2">{fields.visitorData.loaderText}</div>
        <div data-title="dot-spin" id="dotloader" className="d-flex align-items-center justify-content-center account-loader bg-black opacity-50">
          <div className="stage">
            <div className="dot-spin"></div>
          </div>
        </div>
      </>
    )
  }
  else {
    return <></>;
  }
}