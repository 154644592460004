import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from "react";
import { lazyLoadingImg } from '../Js-CommonFunctions.js';
import { articleConstant } from '../SitecoreFieldConstants.js';

export default function ArticleProductDetail({ fields }) {
  useEffect(() => {
    lazyLoadingImg();
  }, []);

  var model = fields?.currentArticleItem;
  if (model != null && model.item != null) {
    if ((model.item.subtitle !== "") || (model.item.desktop_Product_Image != null && (model.item.desktop_Product_Image?.value?.src !== "") && !model.isMobileView) || (model.item.mobile_Product_Image != null && (model.item.mobile_Product_Image?.value?.src !== "") && model.isMobileView) || (model.item.article_Description !== "") || (model.item.credits !== "") || (model.item.legal_Mention !== "")) {
      let creditVar = { value: '' };
      let articleDescription = { value: '' };
      let legalMention = { value: '' };
      creditVar.value = model.credit;
      articleDescription.value = model.article_Des;
      legalMention.value = model.legal_Mentio;

      return (
        <article className={"article-detail mb-5" + (model.isNewBrandedSite ? "" : "")}>
          <div className="container">
            <div className="article-product">
              {((model.item.subtitle !== "")) &&
                <h2 className={"content-title "+ (model.isNewBrandedSite ? " text-start text-capitalize" : "") }><RichText field={fields.content[articleConstant.subtitle]} /></h2>
              }
              {((model.item.desktop_Product_Image != null && (model.item.desktop_Product_Image?.value?.src !== "") && !model.isMobileView) || (model.item.mobile_Product_Image != null && (model.item.mobile_Product_Image?.value?.src !== "") && model.isMobileView)) &&
                <div className="img-wrap text-center">
                  {(model.isExperienceEditor) ?
                    (
                      fields.currentArticleItem.item.mobile_Product_Image != null && fields.currentArticleItem.item.mobile_Product_Image?.value?.src != null && fields.currentArticleItem.isMobileView ?
                        (<Image field={fields.currentArticleItem.item.mobile_Product_Image} imageParams={{ mh: 300 }} alt={fields.currentArticleItem.item.mobile_Product_Image?.value?.alt} title={fields.currentArticleItem.item.mobile_Product_Image?.value?.alt} />)
                        :
                        fields.currentArticleItem.item.desktop_Product_Image != null && fields.currentArticleItem.item.desktop_Product_Image?.value?.src != null && !fields.currentArticleItem.isMobileView ?
                          (<Image field={fields.currentArticleItem.item.desktop_Product_Imagee} imageParams={{ mh: 300 }} alt={fields.currentArticleItem.item.desktop_Product_Image?.value?.alt} title={fields.currentArticleItem.item.desktop_Product_Image?.value?.alt} />)
                          :
                          <></>
                    ) :
                    (
                      <img src={model.defaultImage} data-alt={(model.isMobileView ? (model.mobileModifiedImageItem) : (model.desktopModifiedImageItem))} alt={(model.isMobileView ? model.item.mobile_Product_Image?.value?.alt : model.item.desktop_Product_Image?.value?.alt)} title={(model.isMobileView ? model.item.mobile_Product_Image?.value?.alt : model.item.desktop_Product_Image?.value?.alt)}></img>
                    )
                  }
                </div>
              }
              {((model.item.article_Description !== "")) &&
                <div className={(model.isNewBrandedSite ? "" : "  ") + "description"}>
                  <RichText field={articleDescription} />
                </div>
              }
              {((model.item.credits !== "")) &&
                <div className="credits">
                  <RichText field={creditVar} />
                </div>
              }
              {((model.item.legal_Mention !== "")) &&
                <div className="legal">
                  <RichText field={legalMention} />
                </div>
              }
            </div>
          </div>
        </article>
      )
    }
    else {
      return <></>;
    }
  } else {
    return <></>;
  }
}
