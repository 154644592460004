import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler';
import { GetRenewedAccessToken, createCookie, fnRedirectToConnectLogin, homeProfileDataLayer, isMobile, isTokenExpired, logoutFromGS, readCookie } from "../Js-CommonFunctions.js";
import MyBarCode from '../MyBarcode';
import MyBenefitsAndServices from '../MyBenefitsAndServices';
import MyCommunications from '../MyCommunications';
import MyFavorites from '../MyFavorites';
import MyPersonalInfo from '../MyPersonalInfo';
import { DeleteVisitorConfirmation, ResetData } from './MyAccountScripts';
import { setVisitorCommunityName } from '../../redux/user/actions.js';

export default function MyAccount({ fields }) {
  var dataItem = null;

  const [myPersonalInfo, setMyPersonalInfo] = useState();
  const [myBarcodeInfo, setMyBarcodeInfo] = useState();
  const [myCommunications, setMyCommunications] = useState();
  const [myFavorites, setMyFavorites] = useState();
  const [hideBenefitsTab, setBenefitsTab] = useState(false);
  const [preferredCenters, setPreferredCenters] = useState([]);
  const [legalTextIdInfo, setLegalTextIdInfo] = useState({});
  const [isUpdateCenter, setIsUpdateCenter] = useState(false);

  var visitorId = useSelector((state) => state.user.visitorId)
  var accessToken = useSelector((state) => state.user.accessToken)
  var refreshToken = useSelector((state) => state.user.refreshToken)
  var acquisitionPcCode = useSelector((state) => state.user.acquisitionPcCode)
  const communityGenericName = useSelector((state) => state.user.communityName);

  var queryStringShowSuccessPopin = "";
  var queryStringPcCode = "";
  if (typeof window !== "undefined") {
    const query = new URLSearchParams(window.location.search);
    queryStringShowSuccessPopin = query.get('ShowSuccessPopin');
    queryStringPcCode = query.get('pcCode');
    hashMyCommunications();
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if (fields !== undefined && fields !== null && fields !== "" && fields.redirectUrl !== undefined && fields.redirectUrl !== null && fields.redirectUrl !== "") {
      fnRedirectToConnectLogin(fields.redirectUrl);
    }

    var id = readCookie("UUID");
    var loader = document.getElementById("dotloader")
    if (id === null || id === undefined || id === "") {
      if (loader !== null && loader !== undefined && loader !== "") {
        loader.classList.remove("d-none");
      }
    }

    if (fields !== null && fields !== undefined && fields !== "") {

      if (fields.common !== null && fields.common !== undefined && fields.common !== "") {

        setPreferredCenters(fields.common.preferredCenters);

        var legalTextInfo = {
          latestlegalTextId: fields.common.commercialEmailLatestLegalTextId,
          visitorOptedLegalTextId: fields.common.commercialEmailOptinLegalTextId,
          hasCommerctialOptIn: fields.common.commercialEmailOptinEnabled
        }

        setLegalTextIdInfo(legalTextInfo);
      }
      if (fields.props !== null && fields.props !== undefined && fields.props !== "") {
        if (communityGenericName !== fields.props.communityGenericName)
          dispatch(setVisitorCommunityName(fields.props.communityGenericName));
        setBenefitsTab(fields.props.hideBenefits);
        if (fields.props.hideBenefits) {
          if (fields.myBarcodeInfo !== null && fields.myBarcodeInfo !== undefined && fields.myBarcodeInfo !== "") {
            setMyBarcodeInfo(fields.myBarcodeInfo);
          }
          if (document.getElementById('my-benefits-and-services')) {
            document.getElementById('my-benefits-and-services').classList.remove('active', 'show');
          }
          if (document.getElementById('my-loyalty-card-tab')) {
            if (!isMobile.any()) {
              document.getElementById('my-loyalty-card-tab').classList.add('active');
            }
            if (document.getElementById('my-loyalty-card')) {
              document.getElementById('my-loyalty-card').classList.add('active', 'show');
            }
          }
        } else {
          if (!isMobile.any()) {
            if (document.getElementById('my-benefits-and-services-tab')) {
              document.getElementById('my-benefits-and-services-tab').classList.add('active');
            }
          }
        }
      }
    }

    if (isMobile.any()) {
      document.querySelectorAll("a.nav-link").forEach((x) => {
        x.addEventListener("click", function (e) {
          var $currentAnchor = e.currentTarget;
          if ($currentAnchor.getAttribute("data-menu")) {
            var menuSelected = $currentAnchor.getAttribute("data-menu").valueOf();
            if (menuSelected) {
              document.getElementById("menuList").classList.add("d-none");
              document.getElementById(menuSelected).classList.remove("d-none");
            }
          }
        });
      });
      if (fields.myBenefits !== null && fields.myBenefits !== "" && queryStringShowSuccessPopin !== null && queryStringShowSuccessPopin !== "" && queryStringShowSuccessPopin !== undefined
        && document.getElementById("menuList") && !document.getElementById("menuList").classList.contains("d-none")) {
        document.getElementById("menuList").classList.add("d-none");
      }
    }

    if (window !== "undefined") {
      //My Personal Info
      if (window.location.href.indexOf("#mypersonalinformation") >= 0) {
        if (document.getElementById('my-benefits-and-services-tab')) {
          document.getElementById('my-benefits-and-services-tab').classList.remove('active');
          if (document.getElementById('my-benefits-and-services')) {
            document.getElementById('my-benefits-and-services').classList.remove('active');
            document.getElementById('my-benefits-and-services').classList.remove('show')
          }
        }
        else if (document.getElementById('my-loyalty-card-tab')) {
          document.getElementById('my-loyalty-card-tab').classList.remove('active');
          if (document.getElementById('my-loyalty-card')) {
            document.getElementById('my-loyalty-card').classList.remove('active');
            document.getElementById('my-loyalty-card').classList.remove('show')
          }
        }

        if (document.getElementById('my-personal-information-tab')) {
          if (isMobile.any()) {
            document.getElementById('my-personal-information-tab').click();
          }
          else {
            document.getElementById('my-personal-information-tab').click();
            document.getElementById('my-personal-information-tab').classList.add('active');
            if (document.getElementById('my-personal-information')) {
              document.getElementById('my-personal-information').classList.add('active');
              document.getElementById('my-personal-information').classList.add('show');
            }
          }
        }
      }
      //Barcode
      if (window.location.href.indexOf("#mybarcode") >= 0) {
        if (document.getElementById('my-benefits-and-services-tab')) {
          document.getElementById('my-benefits-and-services-tab').classList.remove('active');
          if (document.getElementById('my-benefits-and-services')) {
            document.getElementById('my-benefits-and-services').classList.remove('active');
            document.getElementById('my-benefits-and-services').classList.remove('show')
          }
        }

        if (document.getElementById('my-loyalty-card-tab')) {
          document.getElementById('my-loyalty-card-tab').classList.add('active');
          if (document.getElementById('my-loyalty-card')) {
            document.getElementById('my-loyalty-card').classList.add('active');
            document.getElementById('my-loyalty-card').classList.add('show')
          }
        }
      }
      //Communications
      if (window.location.href.indexOf("#mycommunications") >= 0) {
        if (document.getElementById('my-benefits-and-services-tab')) {
          document.getElementById('my-benefits-and-services-tab').classList.remove('active');
          if (document.getElementById('my-benefits-and-services')) {
            document.getElementById('my-benefits-and-services').classList.remove('active');
            document.getElementById('my-benefits-and-services').classList.remove('show')
          }
        }
        else if (document.getElementById('my-loyalty-card-tab')) {
          document.getElementById('my-loyalty-card-tab').classList.remove('active');
          if (document.getElementById('my-loyalty-card')) {
            document.getElementById('my-loyalty-card').classList.remove('active');
            document.getElementById('my-loyalty-card').classList.remove('show')
          }
        }

        if (document.getElementById('my-communications-tab')) {
          if (isMobile.any()) {
            document.getElementById('my-communications-tab').click();
          }
          else {
            document.getElementById('my-communications-tab').click();
            document.getElementById('my-communications-tab').classList.add('active');
            if (document.getElementById('my-communications')) {
              document.getElementById('my-communications').classList.add('active');
              document.getElementById('my-communications').classList.add('show');
            }
          }
        }
      }
      // My Favourites
      if (window.location.href.indexOf("#myfavourites") >= 0) {
        if (document.getElementById('my-benefits-and-services-tab')) {
          document.getElementById('my-benefits-and-services-tab').classList.remove('active');
          if (document.getElementById('my-benefits-and-services')) {
            document.getElementById('my-benefits-and-services').classList.remove('active');
            document.getElementById('my-benefits-and-services').classList.remove('show')
          }
        }
        else if (document.getElementById('my-loyalty-card-tab')) {
          document.getElementById('my-loyalty-card-tab').classList.remove('active');
          if (document.getElementById('my-loyalty-card')) {
            document.getElementById('my-loyalty-card').classList.remove('active');
            document.getElementById('my-loyalty-card').classList.remove('show')
          }
        }

        if (document.getElementById('my-favorites-tab')) {
          document.getElementById('my-favorites-tab').classList.add('active');
          if (document.getElementById('my-favorites')) {
            document.getElementById('my-favorites').classList.add('active');
            document.getElementById('my-favorites').classList.add('show');
          }
        }
      }
    }

  }, []);

  useEffect(() => {
    if (fields !== undefined && fields !== null && fields !== "" && queryStringShowSuccessPopin !== null && queryStringShowSuccessPopin !== undefined && queryStringShowSuccessPopin !== ""
      && queryStringShowSuccessPopin === "manage-vehicles") {
      if (document.getElementById('my-personal-information-tab')) {
        if (isMobile.any()) {
          document.getElementById('my-personal-information-tab').click();
        }
        else {
          document.getElementById('my-personal-information-tab').click();
          document.getElementById('my-personal-information-tab').classList.add('active');
          if (document.getElementById('my-personal-information')) {
            document.getElementById('my-personal-information').classList.add('active');
            document.getElementById('my-personal-information').classList.add('show');
          }
        }
      }
    }
    else if (dataItem !== null && dataItem !== undefined && dataItem !== "") {
      var benefitsection = document.getElementById('my-benefits-and-services-tab');
      if (benefitsection !== null && benefitsection !== undefined && benefitsection !== "" && isMobile.any()) {
        benefitsection.click();
      }
    }
  }, [dataItem])

  function hashMyCommunications() {
    //Communications
    if (window.location.href.indexOf("#mycommunications") >= 0) {
      if (document.getElementById('my-benefits-and-services-tab')) {
        document.getElementById('my-benefits-and-services-tab').classList.remove('active');
        if (document.getElementById('my-benefits-and-services')) {
          document.getElementById('my-benefits-and-services').classList.remove('active');
          document.getElementById('my-benefits-and-services').classList.remove('show')
        }
      }
      else if (document.getElementById('my-loyalty-card-tab')) {
        document.getElementById('my-loyalty-card-tab').classList.remove('active');
        if (document.getElementById('my-loyalty-card')) {
          document.getElementById('my-loyalty-card').classList.remove('active');
          document.getElementById('my-loyalty-card').classList.remove('show')
        }
      }

      if (document.getElementById('my-communications-tab')) {
        if (isMobile.any()) {
          document.getElementById('my-communications-tab').click();
        }
        else {
          document.getElementById('my-communications-tab').click();
          document.getElementById('my-communications-tab').classList.add('active');
          if (document.getElementById('my-communications')) {
            document.getElementById('my-communications').classList.add('active');
            document.getElementById('my-communications').classList.add('show');
          }
        }
      }
      //hide burgermenu
      var menu = document.getElementById('navbar');
      if (menu && menu.classList.contains('show')) {
        menu.classList.remove('show');
        document.body.classList.remove("nav-on");
        var preheaderEle = document.getElementById("headerBlock");
        if (preheaderEle !== null && preheaderEle !== "" && preheaderEle.classList.contains("preheader-frontindex")) {
          document.getElementById("headerBlock").classList.remove("preheader-frontindex");
        }
        var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
        if (isMobileView && document.getElementsByClassName("navbar-toggler")[0]) {
          document.getElementsByClassName("navbar-toggler")[0].classList.add("icon-hamburger")
          document.getElementsByClassName("navbar-toggler")[0].classList.remove("icon-cross")

        }
      }
    }
  }
  function BindContent(menuId) {
    if (isTokenExpired()) {
      var args = [menuId];
      GetRenewedAccessToken(GetMenuContent, args);
    } else {
      GetMenuContent(menuId, visitorId, accessToken, refreshToken);
    }
  }

  function GetMenuContent(menuId, visitorId, accessToken, refreshToken) {
    var loader = document.getElementById("loader");
    if (menuId === "my-loyalty-card") {
      var id = readCookie("RefreshContent");
      if (document.getElementById("my-loyalty-card").innerHTML === "" || (id !== null && id !== "" && id.indexOf("loyaltycard") >= 0)) {
        if (id !== null && id !== "") {
          var value = id.replace("loyaltycard", "");
          createCookie("RefreshContent", value, 0);
        }
        if (document.getElementById("my-loyalty-card").innerHTML === "") {
          document.getElementById("my-loyalty-card").style.opacity = 0;
        }
        loader.classList.remove("d-none");

        axios({
          method: "post",
          url: "/api/sitecore/VisitorApi/GetMyLoyaltyCard",
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken,
            contextLanguage: baseModel.contextLang
          },
          async: false,
        })
          .then(function (response) {
            if (response.data !== "") {
              setMyBarcodeInfo(response.data);
              //createRoot(document.getElementById("my-loyalty-card")).render(<MyBarCode fields={response.data} />);
              document.getElementById("my-loyalty-card").style.opacity = 1;
              loader.classList.add("d-none");
            } else {
              document.getElementById("my-loyalty-card").style.opacity = 1;
              loader.classList.add("d-none");
            }
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    }
    else if (menuId === "my-personal-information") {
      var id = readCookie("RefreshContent");
      if (document.getElementById("my-personal-information").innerHTML === "" || (id !== null && id !== "" && id.indexOf("personalinformations") >= 0)) {
        if (id !== null && id !== "") {
          var value = id.replace("personalinformations", "");
          createCookie("RefreshContent", value, 0);
        }
        if (document.getElementById("my-personal-information").innerHTML === "") {
          document.getElementById("my-personal-information").style.opacity = 0;
        }
        loader.classList.remove("d-none");
        axios({
          method: "post",
          url: "/api/sitecore/VisitorApi/GetMyPersonalInformation?contextSite=" + baseModel.urContextSite +
            "&contextLanguage=" + baseModel.contextLang + "&showSuccessPopin=" + queryStringShowSuccessPopin + "&communityBlock=" + fields.myBenefits.communityBlockDatasource,
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken
          },
          async: false,
        })
          .then(function (response) {
            if (response.data !== "") {
              setMyPersonalInfo(response.data);
              document.getElementById("my-personal-information").style.opacity = 1;
              loader.classList.add("d-none");
            } else {
              document.getElementById("my-personal-information").style.opacity = 1;
              loader.classList.add("d-none");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    else if (menuId === "my-communications") {
      var id = readCookie("RefreshContent");
      if (document.getElementById("my-communications").innerHTML === "" || (id !== null && id !== "" && id.indexOf("communications") >= 0)) {
        if (id !== null && id !== "") {
          var value = id.replace("communications", "");
          createCookie("RefreshContent", value, 0);
        }
        if (document.getElementById("my-communications").innerHTML === "") {
          document.getElementById("my-communications").style.opacity = 0;
        }
        loader.classList.remove("d-none");
        axios({
          method: "post",
          url: "/api/sitecore/VisitorApi/GetMyCommunications?contextLanguage=" + baseModel.contextLang,
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken,
            acquisitionPcCode: acquisitionPcCode
          },
          async: false,
        })
          .then(function (response) {
            if (response.data !== "") {
              setMyCommunications(response.data);
              document.getElementById("my-communications").style.opacity = 1;
              loader.classList.add("d-none");
            } else {
              document.getElementById("my-communications").style.opacity = 1;
              loader.classList.add("d-none");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    else if (menuId === "my-favorites") {
      if (document.getElementById("my-favorites").innerHTML === "") {
        document.getElementById("my-favorites").style.opacity = 0;
        loader.classList.remove("d-none");
        axios({
          method: "post",
          url: "/api/sitecore/VisitorApi/GetMyFavorites?contextLanguage=" + baseModel.contextLang,
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken
          },
          async: false,
        })
          .then(function (response) {
            if (response.data !== "") {
              setMyFavorites(response.data);
              document.getElementById("my-favorites").style.opacity = 1;
              loader.classList.add("d-none");
            } else {
              document.getElementById("my-favorites").style.opacity = 1;
              loader.classList.add("d-none");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  if (fields !== undefined && fields !== null && fields !== "" && fields.account !== undefined && fields.account !== null && fields.account !== "") {
    if (fields.account.menu !== undefined && fields.account.menu !== null && fields.account.menu !== "" && fields.account.menu.length > 0) {
      var firstMenuID = fields.account.menu[0].menuId;

      return (
        <div className="account-main on-homepage bg-white" id="accountMain">
          <section className="even mob-background d-md-block d-lg-block d-xl-block">
            <div className="container p-0">
              <div className="row mx-0 ">
                <div className="col-md-4 mobile-left-padding-container border-8 p-lg-3" id="menuList">
                  <div className="myaccount-title mt-4 mb-4 text-black d-lg-none d-xl-none">{fields.account.myAccountTitle}</div>
                  <div className="nav flex-column nav-pills nav-pills-custom border-8 " id="v-pills-tab" role="tablist" aria-orientation="vertical">

                    {fields.account.menu.map((item, index) => {
                      if (queryStringShowSuccessPopin !== null && queryStringShowSuccessPopin !== undefined && queryStringShowSuccessPopin !== ""
                        && item.menuId === "my-benefits-and-services") {
                        dataItem = item;
                      }
                      return (
                        <a key={index} href={"#" + item.menuId} aria-label={item?.menuId} className={"nav-link "
                          + ((item.menuId === "my-benefits-and-services" && hideBenefitsTab) ? " d-none" : "")}
                          id={item.menuId + "-tab"} aria-controls={item.menuId} data-bs-toggle="pill" role="tab" aria-selected={index === 0 ? "true" : "false"}
                          data-menu={item.menuId} data-testid="my-benefits-id" onClick={(e) => { BindContent(item.menuId); homeProfileDataLayer(item.menuId); ResetData(item.menuId); }}>
                          <span className="account-menu-nav-item">
                            <span className="account-menu-text">
                              <i className={"picto account-icon-spacing fs-3 " + item.picto}></i>
                              <span>{item.title}</span>
                            </span>
                            <i className="picto icon-go-right-arrow account-icon-right-arrow text-black"></i>
                          </span>
                        </a>
                      )
                    })
                    }
                  </div>
                  <div className="myaccount-logout d-lg-none d-xl-none" data-testid='testclick1' onClick={() => { return logoutFromGS() }}>{fields.account.logOut?.link?.value?.text}</div>
                  <div className="myaccount-deletemyaccount d-lg-none d-xl-none" data-testid='testclick2' onClick={() => { return DeleteVisitorConfirmation() }}>{fields.account.deleteMyAccount}</div>
                </div>

                <div className="col-md-8 mobile-right-padding-container  p-lg-3">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div className={"tab-pane tab-pane-shadow shopping-center multicentre multicentre-detail fade p-0 border-0" + ((firstMenuID === "my-benefits-and-services" && !fields.props.isMobileView) ? " active show" : "") + ((fields.props.isMobileView) ? " d-none" : "")}
                      id="my-benefits-and-services" role="tabpanel" aria-labelledby="my-benefits-and-services-tab">
                      <MyBenefitsAndServices fields={fields.myBenefits} setBenefitsTab={setBenefitsTab} preferredCenters={preferredCenters} setPreferredCenters={setPreferredCenters} isUpdateCenter={isUpdateCenter} setIsUpdateCenter={setIsUpdateCenter} legalTextIdInfo={legalTextIdInfo} setLegalTextIdInfo={setLegalTextIdInfo} />
                    </div>
                    <div className={"tab-pane tab-pane-shadow fade bg-white loyalty-card p-5 mobile-padding py-lg-4" + ((firstMenuID === "my-loyalty-card" && !fields.props.isMobileView) ? " active show" : "") + ((fields.props.isMobileView) ? " d-none" : "")}
                      id="my-loyalty-card" role="tabpanel" aria-labelledby="my-loyalty-card-tab">
                      {(myBarcodeInfo !== undefined && myBarcodeInfo !== null && myBarcodeInfo !== "") ?
                        <MyBarCode fields={myBarcodeInfo} />
                        : (<></>)
                      }
                    </div>
                    <div className={"tab-pane tab-pane-shadow fade bg-white p-5 mobile-padding personal-information-container py-lg-4" + ((firstMenuID === "my-personal-information" && !fields.props.isMobileView) ? " active show" : "") + ((fields.props.isMobileView) ? " d-none" : "")}
                      id="my-personal-information" role="tabpanel" aria-labelledby="my-personal-information-tab">
                      {(myPersonalInfo !== undefined && myPersonalInfo !== null && myPersonalInfo !== "") ?
                        <MyPersonalInfo fields={myPersonalInfo} />
                        : (<></>)
                      }
                    </div>
                                  <div className={"tab-pane tab-pane-shadow fade bg-white p-5 mobile-padding pt-lg-4 pb-lg-4  " + ((firstMenuID === "my-communications" && !fields.props.isMobileView) ? " active show" : "") + ((fields.props.isMobileView) ? " d-none" : "")}
                      id="my-communications" role="tabpanel" aria-labelledby="my-communications-tab">
                      {(myCommunications !== undefined && myCommunications !== null && myCommunications !== "") ?
                        <MyCommunications fields={myCommunications} setPreferredCenters={setPreferredCenters} setIsUpdateCenter={setIsUpdateCenter} legalTextIdInfo={legalTextIdInfo} setLegalTextIdInfo={setLegalTextIdInfo} />
                        : (<></>)
                      }
                    </div>
                    <div className={"tab-pane tab-pane-shadow my-favourite fade bg-white p-5 mobile-padding py-lg-4 " + ((firstMenuID === "my-favorites" && !fields.props.isMobileView) ? " active show" : "") + ((fields.props.isMobileView) ? " d-none" : "")}
                      id="my-favorites" role="tabpanel" aria-labelledby="my-favorites-tab">
                      {(myFavorites !== undefined && myFavorites !== null && myFavorites !== "") ?
                        <MyFavorites fields={myFavorites} />
                        : (<></>)
                      }
                    </div>
                  </div>
                  <div id="loader" data-bs-title="dot-spin" className="d-flex align-items-center justify-content-center account-loader d-none bg-black opacity-50">
                    <div className="dot-spin"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    }
  }
  else {
    return (
      <></>
    )
  }
}