export default {
    user: {
        isLoggedIn: false,
        accessToken: '',
        refreshToken: '',
        visitorId: '',
        acquisitionPcCode: '',
        firstName: '',
        lastName: '',
        initials: '',
        email: '',
        barCode: '',
        //visitorCenters:[],
        workerCenter: '',
        birthdate:'',
        accountCreationDate:'',
        favCenterLables:'',
        bookmarkedOffer:'',
        bookmarkedStore:'',
        bookmarkedEvent:'',
        bookmarkedRestaurant:'',
        communityName:''
    }
}