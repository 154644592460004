import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { articleConstant } from "../SitecoreFieldConstants.js";

export default function ArticleIntroduction({ fields }) {

  if (fields.currentArticleItem !== null && fields.currentArticleItem !== undefined &&
    fields.currentArticleItem.item !== null && fields.currentArticleItem.item !== undefined &&
    fields.currentArticleItem.item.title.trim() !== ""
  ) {
    let title = { value: "" };

    if (!fields.currentArticleItem.isExperienceEditor) {
      title.value = fields.currentArticleItem.item.title;
    }

    if (
      fields.currentArticleItem !== null && fields.currentArticleItem !== undefined &&
      fields.currentArticleItem.item !== null && fields.currentArticleItem.item !== undefined &&
      fields.currentArticleItem.item.title !== ""
    ) {
      return (
        <div>
          <div className="anchor-navigation">
            {fields.currentArticleItem.isNewBrandedSite ? (
              <nav className="container">
                {fields.currentArticleItem.isExperienceEditor ? (
                  <h1 className="page-title text-capitalize text-start">
                    {" "}
                    <RichText field={fields.content[articleConstant.title]} />
                  </h1>
                ) : (
                  <h1 className="page-title text-capitalize text-start">
                    <RichText field={title} />
                  </h1>
                )}
              </nav>
            ) : (
              <nav className="container navbar">
                {fields.currentArticleItem.isExperienceEditor ? (
                  <h1 className="page-title text-uppercase">
                    <RichText field={fields.content[articleConstant.title]} />
                  </h1>
                ) : (
                  <h1 className="page-title text-uppercase">
                    <RichText field={title} />
                  </h1>
                )}
              </nav>
            )}
          </div>
          {imageTag(fields)}
        </div>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}

function imageTag(fields) {
  if (fields.currentArticleItem !== null && fields.currentArticleItem.item !== null
    && fields.currentArticleItem.item.introduction_Text.trim() !== "") {
    let introtext = { value: "" };
    if (!fields.currentArticleItem.isExperienceEditor) {
      introtext.value = fields.currentArticleItem.item.introduction_Text;
    }

    if ((fields.currentArticleItem.item.desktop_Image != null &&
      fields.currentArticleItem.item.desktop_Image?.value?.src !== null &&
      !fields.currentArticleItem.isMobileView) ||
      (fields.currentArticleItem.item.mobile_Image != null &&
        fields.currentArticleItem.item.mobile_Image?.value?.src !== null &&
        fields.currentArticleItem.isMobileView) ||
      fields.currentArticleItem.item.introduction_Text !== "") {
      return (
        <section className="article-detail article-intro text-center">
          <div className="container">
            {((fields.currentArticleItem.item.desktop_Image !== null &&
              fields.currentArticleItem.item.desktop_Image?.value?.src !== null &&
              !fields.currentArticleItem.isMobileView) ||
              (fields.currentArticleItem.item.mobile_Image !== null &&
                fields.currentArticleItem.item.mobile_Image?.value?.src !== null &&
                fields.currentArticleItem.isMobileView)) && (
                <div className="row img-wrap">
                  {fields.currentArticleItem.item.mobile_Image !== null &&
                    fields.currentArticleItem.item.mobile_Image?.value?.src !== null &&
                    fields.currentArticleItem.isMobileView ? (
                    <Image
                      field={fields.currentArticleItem.item.mobile_Image}
                      imageParams={{ mh: 300 }}
                      alt={
                        fields.currentArticleItem.item.mobile_Image?.value?.alt
                      }
                      title={
                        fields.currentArticleItem.item.mobile_Image?.value?.alt
                      }
                      className="p-0 border-8"
                    />
                  ) : fields.currentArticleItem.item.desktop_Image != null &&
                    fields.currentArticleItem.item.desktop_Image?.value?.src !=
                    null &&
                    !fields.currentArticleItem.isMobileView ? (
                    <Image
                      className="p-0 border-8"
                      field={fields.currentArticleItem.item.desktop_Image}
                      imageParams={{ mh: 300 }}
                      alt={
                        fields.currentArticleItem.item.desktop_Image?.value?.alt
                      }
                      title={
                        fields.currentArticleItem.item.desktop_Image?.value?.alt
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            {fields.currentArticleItem.item.introduction_Text !== "" && (
              <div
                className={
                  "article-description ms-0 mb-4" +
                  (fields.currentArticleItem.isNewBrandedSite ? "" : "")
                }
              >
                {fields.currentArticleItem.isExperienceEditor ? (
                  <RichText field={fields.content[articleConstant.introtext]} />
                ) : (
                  <RichText field={introtext} />
                )}
              </div>
            )}
          </div>
        </section>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}
