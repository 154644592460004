import React, { useEffect } from "react";
import { lazyLoadingImg } from "../Js-CommonFunctions";
export default function TopBrands({ fields }) {
  useEffect(() => {
   
    lazyLoadingImg();

  }, []);

  if (
    fields.topStoreViewModel != null &&
    fields.topStoreViewModel.top_Store_Items != null &&
    fields.topStoreViewModel.top_Store_Items.length > 1
  ) {
    let brandCount =
      "(" + fields.topStoreViewModel.storesCount.toString() + ")";
    let topBrandIndex = 0;

    return (
      <section
        className={
          " top-brands top-brands-new" +
          (fields.topStoreViewModel.isNewBrandedSite ? "" : "")
        }
        id="brands"
      >
        <div
          className={
            "container " +
            (fields.topStoreViewModel.isNewBrandedSite &&
              fields.topStoreViewModel.isHomePage
              ? " "
              : "")
          }
        >
          {fields.topStoreViewModel.showTitleAsLink ? (
            <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between">
              <a
                className="title gtmClickNavigation"
                aria-label={fields?.topStoreViewModel.brands_Title}
                href={
                  fields.topStoreViewModel.urlPrefix +
                  "/" +
                  fields.topStoreViewModel.generalLinkURL
                }
              >
                {fields.topStoreViewModel.brands_Title != null &&
                  fields.topStoreViewModel.brands_Title != "" &&
                  fields.topStoreViewModel.isNewBrandedSite === false ? (
                  <h2>{fields.topStoreViewModel.brands_Title}</h2>
                ) : (
                  fields.topStoreViewModel.isHomePage === true && (
                    <h2>
                      <span className="news-title">
                        {fields.topStoreViewModel.brands_Title}
                      </span>
                    </h2>
                  )
                )}
              </a>
              {/* <a
                className={
                  " action " +
                  (fields.topStoreViewModel.isNewBrandedSite
                    ? "picto icon-plus-big"
                    : "") +
                  " gtmClickNavigation"
                }
                href={
                  fields.topStoreViewModel.urlPrefix +
                  "/" +
                  fields.topStoreViewModel.generalLinkURL
                }
                aria-label="offers-link"
              >
                {!fields.topStoreViewModel.isNewBrandedSite && (
                  <>
                    <strong>{fields.topStoreViewModel.generalLinkText}</strong>{" "}
                    {brandCount}
                    <span className="picto icon-ico-chevron-right"></span>
                  </>
                )}
              </a> */}
            </div>
          ) :
            // fields.topStoreViewModel.isNewBrandedSite &&
            fields.topStoreViewModel.isHomePage ? (
              <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between">
                <div className="title mb-1 mb-lg-0 testinghomepage">
                  {fields.topStoreViewModel.brands_Title != null &&
                    fields.topStoreViewModel.brands_Title != "" && (
                      <h2>
                        <span className="news-title">
                          {fields.topStoreViewModel.brands_Title}
                        </span>
                      </h2>
                    )}
                </div>
              </div>
            ) : (
              !fields.topStoreViewModel.isNewBrandedSite && (
                <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between ">
                  <div className="title ">
                    {fields.topStoreViewModel.brands_Title != null &&
                      fields.topStoreViewModel.brands_Title != "" && (
                        <h2>{fields.topStoreViewModel.brands_Title}</h2>
                      )}
                  </div>
                </div>
              )
            )}
          <div className="topbrands-wrap row text-center" id="topBrands">
            {fields.topStoreViewModel.top_Store_Items.map((next, index) => {
              topBrandIndex = topBrandIndex + 1;
              return (
                <div key={index}
                  className={
                    "col-lg-3 col-6 brand-block mb-lg-0 px-6 px-lg-12 " +
                    (fields.topStoreViewModel.isUSUK ? "us-uk" : "")
                  }
                >
                  <a id="brands-anchor"
                    aria-label={next?.display_Name}
                    href={fields.topStoreViewModel.urlPrefix + next.url_Path}
                    className="d-block  brand-anchor gtmClickNavigation"
                  >
                    <div className="card bg-transparent border-0">
                      <div
                        className={
                          "brand-img " +
                          (fields.topStoreViewModel.isNewBrandedSite
                            ? ""
                            : "")
                        }
                      >
                        <img
                          src={fields.topStoreViewModel.defaultImage}
                          data-alt={next.image_Url}
                          alt={next.display_Name}
                          title={next.display_Name}
                        />
                      </div>

                    </div>
                  </a>
                  <a
                    href={fields.topStoreViewModel.urlPrefix + next.url_Path}
                    className={
                      "d-flex text-start brand-title fw-bold mt-2" +
                      (fields.topStoreViewModel.isNewBrandedSite
                        ? ""
                        : "")
                    }
                  >
                    {next.display_Name}
                  </a>
                </div>
              );
            })}
          </div>
          {!fields.topStoreViewModel.isHomePage && <hr className="mt-5 mb-0" />}
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
