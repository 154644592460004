import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { articleConstant } from '../SitecoreFieldConstants';

export default function ArticleFaceToFace({ fields }) {
  var model = fields?.currentArticleItem;
  if (model !== null && model?.item !== null && model?.item !== undefined && model?.item != "") {
    if (model.articleType !== "" && model.articleType !== undefined && model.articleType !== null) {
      if (model.articleType == "Primary") {
        let primaryShortDescription = { value: '' };
        let primaryLongDescription = { value: '' };
        let primaryHashTag = { value: '' };

        if (!fields.currentArticleItem.isExperienceEditor) {
          primaryShortDescription.value = fields.currentArticleItem.item.primary_Face_To_Face_Short_Description;
          primaryLongDescription.value = fields.currentArticleItem.item.primary_Face_To_Face_Long_Description;
          primaryHashTag.value = fields.currentArticleItem.item.primary_Face_To_Face_Hashtag;
        }

        if ((model.item.primary_Face_To_Face_Image != null) && (model.item.primary_Face_To_Face_Image?.value?.src !== "") || (model.item.primary_Face_To_Face_Long_Description !== "") || (model.item.primary_Face_To_Face_Short_Description !== "") || (model.item.primary_Face_To_Face_Hashtag !== "")) {
          return (
            <article className={"article-detail" + (model.isNewBrandedSite ? " mt-5" : "")} >
              <div className="container">
                <div className="facetoface">
                  {(fields.currentArticleItem.item.primary_Face_To_Face_Image != null) &&
                    <div className="img-wrap text-center">
                      {fields.currentArticleItem.item.primary_Face_To_Face_Image != null && fields.currentArticleItem.item.primary_Face_To_Face_Image?.value?.src != null ?
                        (<Image field={fields.currentArticleItem.item.primary_Face_To_Face_Image} imageParams={{ mh: 438 }} alt={fields.currentArticleItem.item.primary_Face_To_Face_Image?.value?.alt} title={fields.currentArticleItem.item.primary_Face_To_Face_Image?.value?.alt} />)
                        :
                        <></>
                      }
                    </div>
                  }
                  <div className="description">
                    {(model.item.primary_Face_To_Face_Short_Description !== "") &&
                      fields.currentArticleItem.isExperienceEditor ?
                      (
                        <div className="short-description"><RichText field={fields.content[articleConstant.primaryShortDescription]} /><br></br></div>
                      ) :
                      (
                        <div className="short-description"><RichText field={primaryShortDescription} /><br></br></div>
                      )
                    }
                    {((model.item.primary_Face_To_Face_Long_Description !== "")) &&
                      <div className="richtext long-description">
                        {fields.currentArticleItem.isExperienceEditor ?
                          (
                            <RichText field={fields.content[articleConstant.primaryLongDescription]} />
                          ) : (
                            <RichText field={primaryLongDescription} />
                          )
                        }
                      </div>
                    }
                  </div>
                  {((model.item.primary_Face_To_Face_Hashtag !== "")) &&
                    fields.currentArticleItem.isExperienceEditor ?
                    (
                      <div className="hashtag"><RichText field={fields.content[articleConstant.primaryHashTag]} /></div>
                    ) : (
                      <div className="hashtag"><RichText field={primaryHashTag} /></div>
                    )
                  }
                </div>
              </div>
            </article>
          )
        }
      }
      else {
        var model = fields.currentArticleItem;
        let secondaryShortDescription = { value: '' };
        let secondaryLongDescription = { value: '' };
        let secondaryHashTag = { value: '' };

        if (!fields.currentArticleItem.isExperienceEditor) {
          secondaryShortDescription.value = fields.currentArticleItem.item.secondary__Face_To_Face_Short_Description;
          secondaryLongDescription.value = fields.currentArticleItem.item.secondary__Face_To_Face_Long_Description;
          secondaryHashTag.value = fields.currentArticleItem.item.secondary_Face_To_Face_Hashtag;
        }

        if (model.item.secondary_Face_To_Face_Image != null && (model.item.secondary_Face_To_Face_Image?.value?.src !== "") || (model.item.secondary__Face_To_Face_Long_Description !== "") || (model.item.secondary__Face_To_Face_Short_Description !== "") || (model.item.secondary_Face_To_Face_Hashtag !== "")) {
          return (
            <article className={"article-detail" + (model.isNewBrandedSite ? " mt-5" : "")}>
              <div className="container">
                <div className="facetoface">
                  {(fields.currentArticleItem.item.secondary_Face_To_Face_Image != null && fields.currentArticleItem.item.secondary_Face_To_Face_Image.value !== null && fields.currentArticleItem.item.secondary_Face_To_Face_Image.value != "") &&

                    <div className="img-wrap text-center">
                      {(model.isExperienceEditor) ?
                        (
                          fields.currentArticleItem.item.secondary_Face_To_Face_Image != null && fields.currentArticleItem.item.secondary_Face_To_Face_Image?.value?.src != null ?
                            (<Image field={fields.currentArticleItem.item.secondary_Face_To_Face_Image} imageParams={{ mh: 438 }} alt={fields.currentArticleItem.item.secondary_Face_To_Face_Image?.value?.alt} title={fields.currentArticleItem.item.secondary_Face_To_Face_Image?.value?.alt} />)
                            :
                            <></>
                        ) :
                        (
                          <img src={model.defaultImage} data-alt={fields.currentArticleItem.secondaryFaceToFaceImage} alt={model.item.secondary_Face_To_Face_Image?.value?.alt} title={model.item.secondary_Face_To_Face_Image?.value?.alt} />
                        )
                      }
                    </div>
                  }
                  <div className="description">
                    {(model.item.secondary__Face_To_Face_Short_Description !== "") &&
                      fields.currentArticleItem.isExperienceEditor ?
                      (
                        <div className="short-description"><RichText field={fields.content[articleConstant?.secondaryShortDescription]} /><br></br></div>
                      ) : (
                        <div className="short-description"><RichText field={secondaryShortDescription} /><br></br></div>
                      )
                    }
                    {(model.item.secondary__Face_To_Face_Long_Description !== "") &&
                      <div className="richtext long-description">
                        {fields.currentArticleItem.isExperienceEditor ?
                          (
                            <RichText field={fields.content[articleConstant.secondaryLongDescription]} />
                          ) : (
                            <RichText field={secondaryLongDescription} />
                          )
                        }
                      </div>
                    }
                  </div>
                  {(model.item.secondary_Face_To_Face_Hashtag !== "") &&

                    fields.currentArticleItem.isExperienceEditor ?
                    (
                      <div className="hashtag"><RichText field={fields.content[articleConstant.secondaryHashTag]} /></div>
                    ) : (
                      <div className="hashtag"><RichText field={secondaryHashTag} /></div>
                    )
                  }
                </div>
              </div>
            </article>
          )
        }
      }
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}
