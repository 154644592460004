const types = {
  SET_VISITORID: 'SET_VISITORID',
  SET_ACCESSTOKEN: 'SET_ACCESSTOKEN',
  SET_REFRESHTOKEN: 'SET_REFRESHTOKEN',
  SET_ACQUISITION_PCCODE: 'SET_ACQUISITION_PCCODE',
  SET_VISITORNAMEINITIALS: 'SET_VISITORNAMEINITIALS',
  SET_VISITOR_EMAIL: 'SET_VISITOR_EMAIL',
  SET_VISITOR_FIRST_NAME: 'SET_VISITOR_FIRST_NAME',
  SET_VISITOR_LAST_NAME: 'SET_VISITOR_LAST_NAME',
  SET_WORKERCENTER: 'SET_WORKERCENTER',
  SET_BARCODE :'SET_BARCODE',
  SET_VISITORCENTERS :'SET_VISITORCENTERS',
  SET_LOGIN: 'SET_LOGIN',
  DO_LOGOUT: 'DO_LOGOUT',
  SET_VISITORBIRTHDATE: 'SET_VISITORBIRTHDATE',
  SET_VISITORACCOUNTCREATIONDATE: 'SET_VISITORACCOUNTCREATIONDATE',
  SET_VISITORFAVCENTERS: 'SET_VISITORFAVCENTERS',
  SET_VISITORBOOKMARKEDOFFER: 'SET_VISITORBOOKMARKEDOFFER',
  SET_VISITORBOOKMARKEDSTORE: 'SET_VISITORBOOKMARKEDSTORE',
  SET_VISITORBOOKMARKEDRESTAURANT: 'SET_VISITORBOOKMARKEDRESTAURANT',
  SET_VISITORBOOKMARKEDEVENT: 'SET_VISITORBOOKMARKEDEVENT',
  SET_VISITORCOMMUNITYNAME :'SET_VISITORCOMMUNITYNAME'
}

export default types;