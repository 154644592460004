import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
// import { useSelector } from 'react-redux';
// import { baseModel } from '../../RouteHandler';
import { CommonBookmarkallpage, lazyLoadingImg, webViewEqualsOne } from '../Js-CommonFunctions';
const ShopLandingOfferInnerPage = loadable(() => import("../ShopLandingOfferInnerPage"));

function loyalityCondition(fields, loyaltyprogram, isNewBrandedSite) {

  if (loyaltyprogram) {
    return (
      (isNewBrandedSite) ?
        (
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between">
            <div className="title">
              <h2><span className="news-title">{fields.shopLandingOfferItem.offers}</span></h2>
            </div>
          </div>

        ) : (
          <>
            <p>{"NewBarnded:" + isNewBrandedSite}</p>
            <p>{"loyaltyprogram" + loyaltyprogram} </p>
            <h2 className="content-title text-uppercase text-center underline">{fields.shopLandingOfferItem.totalOffersCount + fields.shopLandingOfferItem.strSelectedCategory + fields.shopLandingOfferItem.offers}</h2>
          </>
        )

    )
  }


}

function shoprestaurantCondition(fields, shoprestaurant, isNewBrandedSite) {
  if (shoprestaurant) {
    return (
      // (isNewBrandedSite) ?
      //   (
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between">
            <a className="title" href={fields.shopLandingOfferItem.leftLink}>
              <h2><span className="news-title">{fields.shopLandingOfferItem.totalOffersCount > 1 ? fields.shopLandingOfferItem.offers : fields.shopLandingOfferItem.offer}</span></h2>
            </a>
          </div>

        //) 
        // : (
        //   <h2 className="content-title text-uppercase text-center underline">{fields.shopLandingOfferItem.totalOffersCount > 1 ? fields.shopLandingOfferItem.offers : fields.shopLandingOfferItem.offer}</h2>
        // )
    )
  }
}

function Innercondtion(fields, isDetailPage, strContextPageName) {
  if (fields.shopLandingOfferItem.totalOffersCount > 0) {
    return (
      // <>
      //   {
      //     (isDetailPage) &&
      //     <a className="text-center view-more" href={fields.shopLandingOfferItem.rightLink}>
      //       <div>{fields.shopLandingOfferItem.seeAllOffers}</div>
      //     </a>
      //   }
      //   {
      //     (fields.shopLandingOfferItem.isEuropeanSite) &&
      //     <div className="more-info ">
      //       {fields.shopLandingOfferItem.cezOffer} <a href={fields.shopLandingOfferItem.termsOfUsePage} aria-label={fields?.shopLandingOfferItem?.conditionsParticulieres}>{fields.shopLandingOfferItem.conditionsParticulieres}</a>
      //     </div>
      //   }
      // </>
      <div className="more-info ">
      {fields.shopLandingOfferItem.cezOffer} <a href={fields.shopLandingOfferItem.termsOfUsePage} aria-label={fields?.shopLandingOfferItem?.conditionsParticulieres}>{fields.shopLandingOfferItem.conditionsParticulieres}</a>
    </div>
    )
  }
}

export default function ShopLandingOffers({ fields }) {
  const [visible, setVisible] = useState(0);
  let isperPage = 0;

  // let workerCenter = useSelector((state) => state.user.workerCenter);
  // let isWorker =
  //   workerCenter !== undefined &&
  //   workerCenter !== '' &&
  //   workerCenter.split(',').includes(baseModel.pC_Code);

  useEffect(() => {
    if (fields.shopLandingOfferItem !== null) {
      if (fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.destination) {

        isperPage = fields.shopLandingOfferItem.isNewBrandedSite ? 9 : 8
        setVisible(isperPage);
      } else {
        isperPage = fields.shopLandingOfferItem.offerItems.length;
        setVisible(fields.shopLandingOfferItem.offerItems.length);

      }
    }
  }, []);

  useEffect(() => {
    lazyLoadingImg();
    CommonBookmarkallpage();

  }, [visible]);

  const fnViewMoreClick = () => {
    setVisible(fields.shopLandingOfferItem.totalOffersCount);
    webViewEqualsOne();
    var itemcount = document.getElementById("firstviewmorecount").value;
    var totalcount = document.getElementById("totalviewmorecount").value;
    if (itemcount == totalcount) {
      document.getElementById("viewmoremain").style.display = "none";
    }

    lazyLoadingImg();

  }

  let isDestinationPage = false;
  let loyaltyprogram = false;
  let shoprestaurant = false;
  let becomemember = false;
  let isDetailPage = false;
  let isTitleDisplay = false;
  let displayNone = "";
  let destinationType = "asZX";
  let title = "";
  let itemId = ""
  let strContextPageName = "";
  let count = 0;


  if (fields.shopLandingOfferItem != null && fields.shopLandingOfferItem.offerTypeParameter != null) {
    if (fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.shopLanding || fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.restaurantLanding) {
      shoprestaurant = true;
      fields.shopLandingOfferItem.displayCTA = true;
    }
    if (fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.loyalityProgram) {
      loyaltyprogram = true;
    }
    if (fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.destination) {
      isDestinationPage = true;
      fields.shopLandingOfferItem.displayCTA = false;
      destinationType = fields.shopLandingOfferItem != null && fields.shopLandingOfferItem.destinationType != null && fields.shopLandingOfferItem.destinationType !== "" ? fields.shopLandingOfferItem.destinationType : "";
      title = (fields.shopLandingOfferItem.destinationTitle !== "" && fields.shopLandingOfferItem.destinationTitle !== null) ? fields.shopLandingOfferItem.offers + fields.shopLandingOfferItem.symbolHyphen + fields.shopLandingOfferItem.destinationTitle : fields.shopLandingOfferItem.offers;
    }
    if (fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.storeDetailOffer || fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.restaurantDetailOffer || fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.newsDetailOffer) {
      isDetailPage = true;
      fields.shopLandingOfferItem.displayCTA = false;
    }
    itemId = fields.shopLandingOfferItem.itemId;
    strContextPageName = fields.shopLandingOfferItem.strContextPageName;
  }
  if (fields.shopLandingOfferItem != null && fields.shopLandingOfferItem.totalOffersCount !== null && (fields.shopLandingOfferItem.totalOffersCount > 0 || isDestinationPage)) {
    const isNewBrandedSite = fields.shopLandingOfferItem.isNewBrandedSite;
    return (
      <section className={'offers offers-new offers-store-new' + (isNewBrandedSite ? ' back-gray' : '')} id="offers">
        <div className="container">
          {isDetailPage && (
            <div
              className={
                'header-title d-flex align-items-baseline flex-wrap justify-content-lg-between' +
                (isNewBrandedSite ? '' : ' mb-3')
              }
            >
              {fields.shopLandingOfferItem.isOfferPageExists &&
                fields.shopLandingOfferItem.offersCount > 0 ? (
                isNewBrandedSite ? (
                  <a className="title gtmClickNavigation" href={fields.shopLandingOfferItem.leftLink}>
                    <h2>
                      <span className="title-border">{fields.shopLandingOfferItem.offers}</span>
                    </h2>
                  </a>
                ) : (
                  <>
                    <a
                      className="title mb-1 mb-lg-0 gtmClickNavigation"
                      href={fields.shopLandingOfferItem.leftLink}
                    >
                      <h2>{fields.shopLandingOfferItem.offers}</h2>
                    </a>
                    {/* <a
                      className="action gtmClickNavigation"
                      href={fields.shopLandingOfferItem.rightLink}
                    >
                      <strong>{fields.shopLandingOfferItem.seeAllOffers}</strong>{' '}
                      {'(' + fields.shopLandingOfferItem.offersCount + ')'}
                      <span className="picto icon-ico-chevron-right"></span>
                    </a> */}
                  </>
                )
              ) : (
                <div className={'title' + isNewBrandedSite ? '' : ' mb-1 mb-lg-0'}>
                  {isNewBrandedSite ? (
                    <h2>
                      {' '}
                      <span className="news-title">{fields.shopLandingOfferItem.offers}</span>
                    </h2>
                  ) : (
                    <h2>{fields.shopLandingOfferItem.offers}</h2>
                  )}
                </div>
              )}
            </div>
          )}
          {isDestinationPage && (
            <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3 destination">
              {fields.shopLandingOfferItem.isOfferPageExists &&
                fields.shopLandingOfferItem.offersCount > 0 ? (
                <>
                  <h2 className={'title' + (isNewBrandedSite ? '' : ' ')}>
                    <a className="gtmClickNavigation" href={fields.shopLandingOfferItem.leftLink}>
                      {fields.shopLandingOfferItem.offers}
                    </a>{' '}
                    {fields.shopLandingOfferItem.symbolHyphen}{' '}
                    <span>{fields.shopLandingOfferItem.destinationTitle}</span>
                  </h2>
                  <a className="action gtmClickNavigation" href={fields.shopLandingOfferItem.rightLink}>
                    <strong>{fields.shopLandingOfferItem.seeAllOffers}</strong>{' '}
                    {'(' + fields.shopLandingOfferItem.offersCount + ')'}
                    <span className="picto icon-ico-chevron-right"></span>
                  </a>
                </>
              ) : (
                title !== '' &&
                title !== null && (
                  <h2 className="title ">
                    <span>{title}</span>
                  </h2>
                )
              )}
            </div>
          )}
          {shoprestaurantCondition(fields, shoprestaurant, isNewBrandedSite)}

          {loyalityCondition(fields, loyaltyprogram, isNewBrandedSite)}

          {isNewBrandedSite ? (
            <>
              <div className="row " id="offersresult">
                <ShopLandingOfferInnerPage
                  model={fields.shopLandingOfferItem}
                  landingOfferModel={fields.model}
                  values={visible}
                // isWorker={isWorker}
                />
              </div>
              {!loyaltyprogram && fields.shopLandingOfferItem.displayCTA ? (
                <div className="text-end my-2 view-more">
                <a aria-label={fields?.shopLandingOfferItem?.viewAllOffers} href={fields.shopLandingOfferItem.rightLink} className="grid-new-cta analytics_OffersVMClk gtmViewMoreClick">{fields.shopLandingOfferItem.viewAllOffers}
                <span className="picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5"></span></a>
            </div>
                // <a
                //   href={fields.shopLandingOfferItem.rightLink}
                //   aria-label={fields?.shopLandingOfferItem?.viewAllOffers}
                //   className="text-center view-more analytics_offersViewAllOffersClk"
                // >
                //   <div>{fields.shopLandingOfferItem.viewAllOffers}</div>
                // </a>
              ) : (
                <>
                  {!(fields.shopLandingOfferItem.totalOffersCount > visible) &&
                    displayNone == 'none'}
                  <div
                    className="text-end my-2 view-more"
                    id="viewmoremain"
                    style={{ display: displayNone }}
                  >
                    <input type="hidden" id="viewmorecount" value="1" />
                    <input type="hidden" id="itemquerystring" value={itemId} />
                    {fields.shopLandingOfferItem.totalOffersCount > visible && (
                      <>
                        <a
                          id="viewmoreofferpage"
                          data-testid="testClick"
                          onClick={fnViewMoreClick}
                          className="grid-new-cta analytics_OffersVMClk gtmViewMoreClick"
                        >
                          {fields.shopLandingOfferItem.viewMore}
                        </a>
                        <i className="picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5"></i>
                      </>
                    )}
                  </div>
                </>
              )}
              {fields.shopLandingOfferItem.totalOffersCount > 0 && (
                <>
                  {Innercondtion(fields, isDetailPage, strContextPageName)}
                  <hr className="mt-5 mb-0" />
                </>
              )}
            </>
          ) : (
            <>
              <div className="row " id="offersresult">
                <ShopLandingOfferInnerPage
                  model={fields.shopLandingOfferItem}
                  landingOfferModel={fields.model}
                  values={visible}
                // isWorker={isWorker}
                />
              </div>
              {!loyaltyprogram && fields.shopLandingOfferItem.displayCTA ? (
                <div className="action py-4">
                  <a
                    href={fields.shopLandingOfferItem.rightLink}
                    aria-label={fields?.shopLandingOfferItem?.rightLink}
                    className="link-button btn-lighter analytics_offersViewAllOffersClk"
                  >
                    {fields.shopLandingOfferItem.viewAllOffers}
                  </a>
                </div>
              ) : (
                <>
                  {!(fields.shopLandingOfferItem.totalOffersCount > visible) &&
                    displayNone == 'none'}

                  <div
                    className={
                      isNewBrandedSite
                        ? ' text-center view-more'
                        : 'mt-3 mb-1 text-black text-center text-uppercase view-more'
                    }
                    id="viewmoremain"
                    style={{ display: displayNone }}
                  >
                    <input type="hidden" id="viewmorecount" value="1" />
                    <input type="hidden" id="itemquerystring" value={itemId} />

                    {fields.shopLandingOfferItem.totalOffersCount > visible && (
                      <>
                        <div
                          id="viewmoreofferpage"
                          onClick={fnViewMoreClick}
                          className="analytics_OffersVMClk gtmViewMoreClick"
                        >
                          {fields.shopLandingOfferItem.viewMore}
                        </div>
                        <i className="picto icon-go-down-arrow"></i>
                      </>
                    )}
                  </div>
                </>
              )}
              {fields.shopLandingOfferItem.totalOffersCount > 0 &&
                fields.shopLandingOfferItem.isEuropeanSite && (
                  <div className={'more-info' + (isNewBrandedSite ? ' mb-5' : '')}>
                    {fields.shopLandingOfferItem.cezOffer}{' '}
                    <a
                      href={fields.shopLandingOfferItem.termsOfUsePage}
                      aria-label={fields?.shopLandingOfferItem?.termsOfUsePage}
                    >
                      {fields.shopLandingOfferItem.conditionsParticulieres}
                    </a>
                  </div>
                )}
            </>
          )}
        </div>
      </section>
    );

  } else {
    return <></>;
  }
}
