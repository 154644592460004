import loadable from "@loadable/component";
import React, { useEffect } from "react";
import { CommonBookmarkallpage, lazyLoadingImg } from '../Js-CommonFunctions';
const BrandsList = loadable(() => import("../BrandsList"));
const ShopLandingOfferInnerPage = loadable(() => import("../ShopLandingOfferInnerPage"));
const MosaicAll = loadable(() => import("../MosaicAll"));

export default function GenericCategoryList({ fields }) {

  useEffect(() => {

    CommonBookmarkallpage();
    lazyLoadingImg();
  }, []);
  if (fields?.filterModel !== undefined && fields?.filterModel !== null && fields?.filterModel !== "") {
    if ((fields.filterModel.isStoreFilter || fields.filterModel.isRestaurantFilter) && fields.filterModel.displayStoreitems.displaystoreitems.length > 0) {
      return (
        <section className="filter-area new stores">
          <div className="container">
            <div className="row filter-wrap">
              <div className="col-12 col-lg-12 filter-result-wrap">
                <div className="filter-result row">
                  <BrandsList model={fields.filterModel.displayStoreitems} />
                </div>
              </div>
            </div>
          </div>
        </section>

      )
    }
    else if (fields.filterModel.isDealFilter && fields.filterModel.displayDealitems.offerItems.length > 0) {
      return (
        <section className={"filter-area new offers " + (fields.filterModel.isNewBranded ? " back-gray" : "")} >
          <div className="container">
            <div className="row filter-wrap">
              <div className="col-12 col-lg-12 filter-result-wrap">
                <div className={"filter-result " + (fields.filterModel.isNewBranded ? " p-2" : "")}>
                  <div className={"row commercial-offers" + (fields.filterModel.isNewBranded ? " border-bottom" : "") + " contentSection"}>
                    <ShopLandingOfferInnerPage model={fields.filterModel.displayDealitems} landingOfferModel={fields.model} values={fields.filterModel.displayDealitems.offerItems.length} />

                  </div>
                </div>
              </div>
            </div >
          </div >
        </section >
      )
    }
    else if (fields.filterModel.isEventFilter && fields.filterModel.displayEventsitems.customItems.length > 0) {
      return (
        <section className="filter-area new news-events four-block">
          <div className="container border-bottom">
            <div className="row filter-wrap">
              <div className="col-12 col-lg-12 filter-result-wrap">
                <MosaicAll model={fields.filterModel.displayEventsitems} commonValues={fields.filterModel.displayEventsitems} visibleItemCount={fields.filterModel.displayEventsitems.customItems.length} />
              </div>
            </div>
          </div>
        </section>
      )
    }

    else {
      return <></>;
    }
  }
  else {
    return <></>;
  }
}