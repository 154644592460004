import React, { useEffect } from 'react';
import {
  LoadNewStores,
  fnGetLuxLanguage, fnGetLuxPassword,
  fnIsSearchPage, fnSearchBind, fnSearchExit,
  fnSearchNoResult,
  fnSearchText,
  fnsearchTextReset
} from '../Js-CommonFunctions';
import { baseModel } from '../../RouteHandler';

export default function SearchRendering({ searchData }) {
  useEffect(() => {
    var searchIntervalTimer;
    if (window.location.href.indexOf("search") >= 0) {
      searchIntervalTimer = setInterval(fnSearchBind, 1000)
    }
    else {
      clearInterval(searchIntervalTimer);
      searchIntervalTimer = null;
    }

    if (searchData.baseModel.searchPage) {
      LoadNewStores();
      fnSearchNoResult();
      fnGetLuxLanguage();
      fnGetLuxPassword();
    }

    fnIsSearchPage();

    var searchTextTimer;
    var searchTexttimeout = 500;

    if (document.getElementById("SearchText") != null) {

      document.getElementById("SearchText").addEventListener('keydown', function (event) {
        if (event.key == 13) {
          event.preventDefault();
          return false;
        }
      });
      document.getElementById("SearchText").addEventListener('input', function (event) {
        if (event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
        clearTimeout(searchTextTimer);
        searchTextTimer = setTimeout(fnSearchText, searchTexttimeout);
      });
      //line10399 doubt
      // document.getElementById("SearchText").addEventListener('input', function () {
      //   var searchInput = this.value //WS-1739 sub issue-6 Close button not displayed on load in IE when characters are

      //   if (searchInput.length != 0) {
      //     document.getElementById("searchTextReset").style.display = "block";
      //   } else {
      //     document.getElementById("searchTextReset").style.display = "none";
      //   }
      // });
    }

    fnsearchTextReset();

  }, []);


  if (searchData != null) {
    return (
      (searchData.pageName === searchData.searchPage) ?
        (
          <>
            <div className="modal-header justify-content-start d-lg-none pb-0 pt-4">
              {/* <a className="close search-modal-header fs-5 ms-2" role="button" data-bs-toggle="modal"
                data-bs-target="#searchPopin">
                <span aria-hidden="true" className="picto icon-cross fs-1 text-black "></span>
              </a> */}
            </div>
            <div className='searchbar'>
              <a className="d-flex justify-content-end align-items-center search-close" role="button" data-bs-toggle="modal" data-bs-target="#searchPopin" id="searchExit" onClick={(e) => { fnSearchExit() }}>{searchData.searchExitText}
                <div className="picto icon-close-circle-rw navbar-toggler-navbar d-flex justify-content-end ps-lg-4 ps-3 fs-1" data-testid='test1'
                  role="button" data-bs-toggle="modal" data-bs-target="#searchPopin"></div>
              </a>
              <form className="global-form search-form" data-testid='test3' onSubmit={e => { e.preventDefault(); }}>
                <div className="container px-lg-5 search-form-width">
                  <div className="form-group">
                    <label htmlFor="SearchText" aria-label="search-text" className="picto icon-magnifier fs-3 ms-3 text-black"></label>
                    <input type="text" name="focus" required="" className="form-control" id="SearchText" placeholder={searchData.searchPagePh} autoFocus data-testid='test-search' />
                    {/* <button className="picto icon-close-search-btn me-1" aria-label="search-close" type="reset" id="searchTextReset"></button> */}
                  </div>
                </div>
              </form>
            </div>
            <div className="search-wrapper search-result" id="searchContent" >
            </div>

            <div className="search-wrapper search-result" id="searchContent1">
              <div className='container'>
                <div className="search-landing container" id="searchNewStoresList" style={{ display: "none" }}>
                </div>
                <div className="search-landing container" id="search-no-results" style={{ display: "none" }}></div>
              </div>
             
            </div>
            <div className='container' id="getsearchresult">
              <div className="search-landing container" id="search-store-block">

                <div className="align-items-end d-flex justify-content-between search-title text-black" id="search-store-block">
                  <h2 className="" id="storeTitle">
                    {/* <span id="storeCountTitle"> </span> */}
                  </h2>
                </div>

                <div className="categories row justify-content-start" id="ulStores" style={{ display: 'none' }}>
                  <div id="storesresult" className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1 pb-0 px-2">
                    {/* <span className="new-tap pink" id="liSpanStoreName"></span> */}
                    <a className={"block store-wrap d-flex justify-content-center pb-0 border-8 bg-gray" + (baseModel.isEuropeanSite ? "" : "us-uk")} href="" id="liStoreAnchor">
                      <div className="store-img d-flex justify-content-center ">
                        <img src="" alt="" title="" className='top-logo' id="liImage" />
                        <span className="picto icon-ico-ph-shops" style={{ display: 'none' }} id="liSpanStoresDefaultPicto"></span>
                        <span className="picto icon-srv-restaurant" style={{ display: 'none' }} id="liSpanRestaurantsDefaultPicto"></span>
                      </div>
                    </a>
                    <a href="" className="d-flex store-name  text-start gtmSearchNavigation" id="liDescriptionAnchor"> </a>
                  </div>
                </div>
              </div>
              <div className="search-landing container" id="search-cinema-block">
                <div className="align-items-end d-flex justify-content-between search-title text-black" >
                  <h2 id="filmTitle"> </h2>
                </div>

                <div className="categories row justify-content-start" id="ulFilms" style={{ display: 'none' }}>
                  <div className='col-6 col-sm-4 col-md-3 col-lg-3 mb-3 pe-2' id="liFilms">
                    <div className="movie-block position-relative" id="movieblock">
                      {/* <div className="movie-img"> */}
                      <a className="img-wrap d-flex movie-img-new justify-content-center align-items-center mb-2 me-1 me-lg-2" href="" >
                        <img className='"w-100 border-8 movie-img-new' src="" alt="" id="liImgFilms" />
                        <span className="picto icon-srv-cinema" id="liSpanFilms" style={{ display: 'none' }}></span>
                      </a>

                      {/* </div> */}
                      <div className="movie-title-new text-capitalize text-start">
                        <a href="" className="d-block" id="liDescriptionAnchorMovie"></a>
                      </div>
                      <a href="#" className="d-block movie-version-new language text-start" id="liDescriptionAnchorOffer"></a>
                      {/* <div className="offer-tap" id="liDescriptionAnchorOffer"></div> */}
                    </div>
                  </div>
                  {/* <div id="liDistributor">
                            <div className="block movie-wrap with-logo">
                              <div className="movie-img">
                                <img src="" alt="" id="liImageDistributor1" />

                                <img src="" alt="" style={{ display: 'none' }} id="liImageDistributor2" />
                              </div>
                              <a className="movie-name stretched-link" href="" id="liDescriptionAnchorDistributor"></a>
                            </div>
                          </div> */}
                </div>

              </div>
              <div className="search-landing container" id="search-service-block">
                <div className="align-items-end d-flex justify-content-between search-title text-black" >
                  <h2 id="serviceTitle"></h2>
                  {/* <span id="serviceCountTitle"> </span> */}
                </div>
                <div className="categories row justify-content-start" id="ulServices" style={{ display: 'none' }}>
                  <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1" id="servicesresult">
                    <a className="block store-wrap d-flex justify-content-center border-8 bg-gray" href="" id="liServiceAnchor">
                      <div className="store-img d-flex justify-content-center border-8">
                        <span className="picto" id="liServicePicto"></span>
                        <span className="picto icon-servicesaspictosservices" id="liDefaultPicto" style={{ display: 'none' }}></span>
                      </div>
                    </a>
                    <a className="store-name d-flex text-start gtmSearchNavigation" href="" id="liServiceDescription"></a>
                  </div>
                </div>

              </div>
            </div>
            <div id="luxLanguage" style={{ display: 'none' }}></div>
            <div id="luxPass" style={{ display: 'none' }}></div>
          </>
        ) :
        (
          <>
            <div className="modal popin-search bg-white" role="dialog" id="searchPopin">
              <div className="modal-dialog modal-popin-search">
                <div className="modal-content">
                  <div className="modal-header justify-content-start d-lg-none pb-0 pt-4">
                    {/* <a className="close search-modal-header fs-5 ms-2" role="button" data-bs-toggle="modal"
                      data-bs-target="#searchPopin">
                      <span aria-hidden="true" className="picto icon-cross fs-1 text-black"></span>
                    </a> */}
                  </div>
                  <div className="modal-body bg-white">
                    <div className='searchbar'>
                      <a className="d-flex justify-content-end align-items-center search-close" role="button" data-bs-toggle="modal" data-bs-target="#searchPopin" id="searchExit" onClick={(e) => { fnSearchExit() }}>{searchData.searchExitText}
                        <div className="picto icon-close-circle-rw navbar-toggler-navbar d-flex justify-content-end ps-lg-4 ps-3 fs-1" data-testid='test1'
                          role="button" data-bs-toggle="modal" data-bs-target="#searchPopin"></div>
                      </a>

                      <form className="global-form search-form" noValidate="novalidate" data-testid='test2' onSubmit={e => { e.preventDefault(); }}>
                        <div className="container search-container ">
                          <div className="form-group">
                            <label htmlFor="SearchText" aria-label="search-text" className="picto icon-search-rw"></label>
                            <input type="text" name="focus" required="" className="form-control" id="SearchText" placeholder={searchData.searchPagePh} autoFocus />
                            {/* <button className="picto icon-close-search-btn" aria-label="search-close" type="reset" id="searchTextReset"></button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="search-wrapper search-result" id="searchContent" >
                    </div>

                    <div className="search-wrapper search-result" id="searchContent1">
                      <div className='container'>
                        <div className="search-landing container" id="searchNewStoresList" style={{ display: "none" }}>
                        </div>
                        <div className="search-landing container" id="search-no-results" style={{ display: "none" }}></div>
                      </div>
                      
                    </div>
                    <div className='container' id="getsearchresult">
              <div className="search-landing container" id="search-store-block">

                <div className="align-items-end d-flex justify-content-between search-title text-black" id="search-store-block">
                  <h2 className="" id="storeTitle">
                    {/* <span id="storeCountTitle"> </span> */}
                  </h2>
                </div>

                <div className="categories row justify-content-start" id="ulStores" style={{ display: 'none' }}>
                  <div id="storesresult" className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1 pb-0 px-2">
                    {/* <span className="new-tap pink" id="liSpanStoreName"></span> */}
                    <a className={"block store-wrap d-flex justify-content-center pb-0 border-8 bg-gray" + (baseModel.isEuropeanSite ? "" : "us-uk")} href="" id="liStoreAnchor">
                      <div className="store-img d-flex justify-content-center ">
                        <img src="" alt="" title="" className='top-logo' id="liImage" />
                        <span className="picto icon-ico-ph-shops" style={{ display: 'none' }} id="liSpanStoresDefaultPicto"></span>
                        <span className="picto icon-srv-restaurant" style={{ display: 'none' }} id="liSpanRestaurantsDefaultPicto"></span>
                      </div>
                    </a>
                    <a href="" className="d-flex store-name  text-start gtmSearchNavigation" id="liDescriptionAnchor"> </a>
                  </div>
                </div>
              </div>
              <div className="search-landing container" id="search-cinema-block">
                <div className="align-items-end d-flex justify-content-between search-title text-black" >
                  <h2 id="filmTitle"> </h2>
                </div>

                <div className="categories row justify-content-start" id="ulFilms" style={{ display: 'none' }}>
                  <div className='col-6 col-sm-4 col-md-3 col-lg-3 mb-3 pe-2' id="liFilms">
                    <div className="movie-block position-relative" id="movieblock">
                      {/* <div className="movie-img"> */}
                      <a className="img-wrap d-flex movie-img-new justify-content-center align-items-center mb-2 me-1 me-lg-2" href="" >
                        <img className='"w-100 border-8 movie-img-new' src="" alt="" id="liImgFilms" />
                        <span className="picto icon-srv-cinema" id="liSpanFilms" style={{ display: 'none' }}></span>
                      </a>

                      {/* </div> */}
                      <div className="movie-title-new text-capitalize text-start">
                        <a href="" className="d-block" id="liDescriptionAnchorMovie"></a>
                      </div>
                      <a href="#" className="d-block movie-version-new language text-start" id="liDescriptionAnchorOffer"></a>
                      {/* <div className="offer-tap" id="liDescriptionAnchorOffer"></div> */}
                    </div>
                  </div>
                  {/* <div id="liDistributor">
                            <div className="block movie-wrap with-logo">
                              <div className="movie-img">
                                <img src="" alt="" id="liImageDistributor1" />

                                <img src="" alt="" style={{ display: 'none' }} id="liImageDistributor2" />
                              </div>
                              <a className="movie-name stretched-link" href="" id="liDescriptionAnchorDistributor"></a>
                            </div>
                          </div> */}
                </div>

              </div>
              <div className="search-landing container" id="search-service-block">
                <div className="align-items-end d-flex justify-content-between search-title text-black" >
                  <h2 id="serviceTitle"></h2>
                  {/* <span id="serviceCountTitle"> </span> */}
                </div>
                <div className="categories row justify-content-start" id="ulServices" style={{ display: 'none' }}>
                  <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1" id="servicesresult">
                    <a className="block store-wrap d-flex justify-content-center border-8 bg-gray" href="" id="liServiceAnchor">
                      <div className="store-img d-flex justify-content-center border-8">
                        <span className="picto" id="liServicePicto"></span>
                        <span className="picto icon-servicesaspictosservices" id="liDefaultPicto" style={{ display: 'none' }}></span>
                      </div>
                    </a>
                    <a className="store-name d-flex text-start gtmSearchNavigation" href="" id="liServiceDescription"></a>
                  </div>
                </div>

              </div>
            </div>
                  </div>
                  <div id="luxLanguage" style={{ display: 'none' }}></div>
                  <div id="luxPass" style={{ display: 'none' }}></div>
                </div>
              </div>
            </div>
          </>
        )
    );
  } else {
    return <></>;
  }
}