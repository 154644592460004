import { Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect } from "react";
import { CommonBookmarkallpage } from "../Js-CommonFunctions";

export default function StoreMapInfoLeft({ fields }) {
  useEffect(() => {
    CommonBookmarkallpage();
    // if(fields !== null && fields !== "" && fields !== undefined && fields.redirectToParentPage){
    //   URWindowLocation(fields.urlToRedirect);
    // }
  }, []);
  if (fields.storeMapInfoLeft != null) {
    return (
      <div
        className={`position-relative bg-white ${fields.storeMapInfoLeft.isNewBrandedSite ? "" : " px-3 py-4 "
          } ${fields.storeMapInfoLeft.hasCurbside ? "curbside-block" : ""
          } left-top-content`}
      >
        {fields.storeMapInfoLeft.hasCurbside && (
          <div className="curbside position-absolute fw-bold text-uppercase notation left">
            <Text field={fields.storeMapInfoLeft.curbsidePickupText} />
          </div>
        )}
        {fields.storeMapInfoLeft.isLUXNewStore && (
          <div className="position-absolute fw-bold text-uppercase notation left">
            <Text field={fields.storeMapInfoLeft.newStoreTag} />
          </div>
        )}
        {fields.storeMapInfoLeft.isComingSoon && (
          <div className="position-absolute fw-bold text-uppercase notation left">
            <Text field={fields.storeMapInfoLeft.comingSoonStoreTag} />
          </div>
        )}
        <a
          href="javascript:void(0);"
          className="position-absolute text-reset fs-4 open-popin bookmark ava-wishlist  picto icon-heart-unfill-rw"
          role="button"
          aria-label={fields.storeMapInfoLeft?.itemId}
          aria-labelledby={fields.storeMapInfoLeft.storeType.value == "restaurant" ? "restaurant" : "store"}
          data-itemlabel={fields.storeMapInfoLeft.name.value}
          data-templatetype={fields.storeMapInfoLeft.storeType.value == "store" ? "Store Detail" : "Restaurant Detail"}
        ></a>
        {fields.storeMapInfoLeft.storeLogoUrl !== null &&
          fields.storeMapInfoLeft.storeLogoUrl !== "" && (
            <div className="text-center header d-none d-sm-block">
              <img
                className="store-logo-img "
                src={fields.storeMapInfoLeft.storeLogoUrl}
                alt={
                  fields.storeMapInfoLeft.StoreLogoAlt !== null &&
                    fields.storeMapInfoLeft.StoreLogoAlt !== undefined
                    ? fields.storeMapInfoLeft.StoreLogoAlt.value
                    : ""
                }
              />
            </div>
          )}
        <div
          className={`store-heading-text text-start ${fields.storeMapInfoLeft.isNewBrandedSite
            ? ""
            : ""
            } m-0`}
        >
          <Text field={fields.storeMapInfoLeft.name} />
        </div>
        {((fields.storeMapInfoLeft.openingHours !== null &&
          fields.storeMapInfoLeft.openingHours !== undefined &&
          fields.storeMapInfoLeft.openingHours.length > 0) ||
          (fields.storeMapInfoLeft.openingHoursList !== null &&
            fields.storeMapInfoLeft.openingHoursList !== undefined &&
            fields.storeMapInfoLeft.openingHoursList.length > 0)) &&
          !fields.storeMapInfoLeft.isComingSoon && (
            <p
              className={`time-text fw-bold text-start ${fields.storeMapInfoLeft.isNewBrandedSite
                ? ""
                : ""
                })`}
            >
              <Text field={fields.storeMapInfoLeft.todayOpenOrClosedLabel} />
              <br></br>
              {fields.storeMapInfoLeft.isCinema ? (
                <span className="time">
                  <Text field={fields.storeMapInfoLeft.todayTimes} />
                </span>
              ) : (
                fields.storeMapInfoLeft.timelst.map((time) => {
                  return (
                    <>
                      <span className="time">
                        <Text field={time} />
                      </span>
                      <br></br>
                    </>
                  );
                })
              )}
            </p>
          )}
        <div className="weblink-text d-lg-block d-none">
          {fields.storeMapInfoLeft.websiteUrl.value !== null &&
            fields.storeMapInfoLeft.websiteUrl.value !== "" && (
              <>
                <span className="picto icon-website"></span>
                <a
                  aria-label={fields?.storeMapInfoLeft?.websiteUrl?.value}
                  target="_blank"
                  className="text-reset"
                  rel="noopener noreferrer"
                  href={fields.storeMapInfoLeft.websiteUrl.value}
                >
                  <Text field={fields.storeMapInfoLeft.websiteText} />
                </a>
              </>
            )}
        </div>
        {fields.storeMapInfoLeft.telephone.value !== null &&
          fields.storeMapInfoLeft.telephone.value !== "" && (
            <div className="phone-text d-lg-block d-none">
              <span
                className="picto icon-srv-telephone-point"
                style={{ paddingRight: "5.5px" }}
              ></span>
              <a href="#" className="text-reset"><Text field={fields.storeMapInfoLeft.telephone} /></a>
            </div>
          )}
        <div className="mt-3">
          {fields.storeMapInfoLeft.isMapExist && (
            <div id="mapofthemallcta">
              <a
                aria-label={fields?.storeMapInfoLeft?.mapLocation}
                className="w-100 mw-100 text-reset link-button btn-white-new mb-lg-0 mb-3 scrollToHidden"
                href="#mapofthemall"
              >
                <Text field={fields.storeMapInfoLeft.mapLocation} />
              </a>
            </div>
          )}
          {!fields.storeMapInfoLeft.isCinema && (
            <div id="storesservices" className="hidden">
              <a
                aria-label={fields?.storeMapInfoLeft?.availableServicesText}
                className="w-100 mw-100 text-reset link-button btn-white-new mb-lg-0 mb-3 scrollToHidden"
                href="#availableservices"
              >
                <Text field={fields.storeMapInfoLeft.availableServicesText} />
              </a>
            </div>
          )}
          {fields.storeMapInfoLeft.websiteUrl.value !== null &&
            fields.storeMapInfoLeft.websiteUrl.value !== "" && (
              <div className="weblink-text d-lg-none d-block">
                <span className="picto icon-website"></span>
                <a
                  aria-label={fields?.storeMapInfoLeft?.websiteUrl?.value}
                  className="text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={fields.storeMapInfoLeft.websiteUrl.value}
                >
                  <Text field={fields.storeMapInfoLeft.websiteText} />
                </a>
              </div>
            )}
          {fields.storeMapInfoLeft.telephone.value !== null &&
            fields.storeMapInfoLeft.telephone.value !== "" && (
              <div className="phone-text d-lg-none d-block">
                {fields.storeMapInfoLeft.telephoneHasOnlyNumbers ? (
                  <>
                    <span className="picto icon-srv-telephone-point"></span>
                    <a
                      aria-label={fields?.storeMapInfoLeft?.telephone?.value}
                      className="text-reset"
                      href={`tel:${fields.storeMapInfoLeft.telephone.value}`}
                    >
                      <Text field={fields.storeMapInfoLeft.telephone} />
                    </a>
                  </>
                ) : (
                  <>
                    <span className="picto icon-srv-telephone-point"></span>
                    <a className="text-reset" aria-label={fields?.storeMapInfoLeft?.telephone}>
                      <Text field={fields.storeMapInfoLeft.telephone} />
                    </a>
                  </>
                )}
              </div>
            )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
