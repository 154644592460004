import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from "react";
import Slider from "react-slick";
import { lazyLoadingImg } from '../Js-CommonFunctions.js';
import { articleConstant } from '../SitecoreFieldConstants.js';

export default function ArticleCarousel({ fields }) {
    useEffect(() => {
        lazyLoadingImg();
    }, []);

    var model = fields.articleViewModel;
    if (model != null && model.items.length > 0 || (model?.articleItem.carousel_Description.trim() !== "" || model?.articleItem.carousel_Hashtag.trim() !== "")) {
        let CarouselDescription = { value: '' };
        let CarouselHashTag = { value: '' };
        if (!model?.isExperienceEditor) {
            CarouselDescription.value = model.articleItem.carousel_Description;
            CarouselHashTag.value = model.articleItem.carousel_Hashtag;
        }

        var settings = {
            customPaging: function (i) {
                return (
                    <button type="button" className="bg-main" data-role="none" role="button" tabIndex="0" />
                );
            },
            arrows: true,
            dots: true,
            autoplay: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: () => { lazyLoadingImg(); }
        };

        return (
            <div className="container article-detail">
                <div className="article-carousel">
                    {model.items.length > 0 &&
                        <Slider {...settings} className="carousel-article border-8" id="carouselArticle">
                            {model.items.map((item, index) => {
                                let carouselContent = { value: item.article_Carousel_Content };
                                return (
                                    <div key={index}>
                                        <a href={item.article_Carousel_Link?.value?.url} aria-label={item.article_Carousel_Link?.value} target={item.article_Carousel_Link?.value?.target}>
                                            {item.article_Carousel_Content !== null && item.article_Carousel_Content.toString().toLowerCase().includes(model.iFrame) ?
                                                (
                                                    <RichText field={carouselContent} />
                                                ) :
                                                (
                                                    <img src={model.defaultImage} data-alt={item.article_Carousel_Image?.value?.src} alt={item.article_Carousel_Image?.value?.alt}></img>
                                                )
                                            }
                                        </a>
                                    </div>
                                )
                            })}
                        </Slider>
                    }
                    {model.isExperienceEditor ?
                        (
                            <><div className="description">
                                {(fields.content !== null) && <RichText field={fields.content[articleConstant.carousel_Description]} />}
                            </div>
                                <div className="hashtag">
                                    {(fields.content !== null) && <RichText field={fields.content[articleConstant.carousel_Hashtag]} />}
                                </div></>
                        ) :
                        (
                            <><div className="description">
                                <RichText field={CarouselDescription} />
                            </div>
                                <div className="hashtag">
                                    <RichText field={CarouselHashTag} />
                                </div></>
                        )
                    }
                </div>
            </div>
        )
    }
    else {
        return (<></>)
    }
}
