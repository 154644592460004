import React, { useEffect } from 'react';

export default function LoyaltyCardBarCodePopin({ model }) {
  var image;
  if (model !== null) {
    image = "/api/sitecore/VisitorApi/BarcodeImage?strBarCode=" + model;
  }

  useEffect(() => {
    var ele = document.getElementById("barCodeModalPopin");
    if (ele !== null && ele !== undefined) {
      var popinClick = document.getElementById("popinBarCodeClick");
      if (popinClick !== null && popinClick !== undefined && popinClick !== "") {
        popinClick.click();
      }
    }
  }, []);

  if (model !== null) {
    return (
      <div className="modal-dialog popinbarcode" id="barCodeModalPopin">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="picto icon-close-circle-rw border-0 bg-white text-black fs-3 click-catcher" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body text-center transform-modal text-center">
            <img id="barCode" src={image} alt={model} title={model} />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>;
  }
}
