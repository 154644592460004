import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { CommonBookmarkallpage, URWindowLocation, fnClickNavigation, fnGtmCategorySearchFilter, fnUpdatePageNameInDataAttr, isMobile, lazyLoadingImg } from '../Js-CommonFunctions';

const MosaicAll = loadable(() => import("../MosaicAll"));

export default function Mosaic({ fields }) {
  const [visibleEvents, setvisibleEvents] = useState(0);

  let EventsPerOther = 9;
  let EventsPerMobile = 3;
  let EventsPerTab = 9;

  useEffect(() => {

    if (isMobile.any())
      setvisibleEvents(3);
    else if (isMobile.isTablet())
      setvisibleEvents(9);
    else
      setvisibleEvents(9);

  }, []);

  useEffect(() => {

    if (fields.newsItems != null) {

      var totalcount = fields.newsItems.customItems?.length;

      var eleViewMore = document.getElementById('whatshotviewmoreDiv');
      if (eleViewMore !== null) {
        if (totalcount > visibleEvents) {
          document.getElementById('whatshotviewmoreDiv').style.display = "block";
        } else {
          document.getElementById('whatshotviewmoreDiv').style.display = "none";
        }
      }
      CommonBookmarkallpage();
      lazyLoadingImg();

      if (fields.newsItems.redirectUrl != null && fields.newsItems.redirectUrl != "") {
        URWindowLocation(fields.newsItems.redirectUrl);
      }

      //When navigated by clicking on internal links, create clickPosition & clickLabel cookies - to be used for Page Load dataLayer variables
      if (document.querySelectorAll('.gtmClickNavigation') !== null && document.querySelectorAll('.gtmClickNavigation') !== undefined) {
        document.querySelectorAll('.gtmClickNavigation').forEach(x => {
          x.addEventListener('click', function (e) {
            if (e.target.classList.contains("inner-toggler")) { //In Mobile - Header navigation, there is span for just showing sub-navigations. This span will not actually navigate away to another page. So, do not store GTM navigation data when this span is clicked.
              return;
            }
            fnClickNavigation(e.target);
          })
        })
      }

    }

  }, [visibleEvents]);




  //Function to get more events/news when View More is clicked
  function eventsViewMore(e, perPageItem, ishref) {
    if (ishref) {
      e.preventDefault();
    }
    var itemsToDisplay = visibleEvents + perPageItem;
    setvisibleEvents(itemsToDisplay);
    document.getElementById('viewmorepageother').setAttribute('data-gtmpagenumber', '');
    let pagenumber = Math.ceil(visibleEvents / perPageItem);
    document.getElementById('viewmorepageother').setAttribute('gtmpagenumber', pagenumber);


    //Add click navigation analytics changes for news mosaic block
    var ele = document.querySelector('#row-isflex-flexgrid-clearfix .gtmClickNavigation');
    if (ele !== null) {
      ele.addEventListener('click', function (event) {
        fnUpdatePageNameInDataAttr(event, 'gtmclickposition');
        fnClickNavigation(event);
      });
    }
    var totalcount = document.getElementById('totalresult') !== null && document.getElementById('totalresult') !== undefined ? parseInt(document.getElementById('totalresult').value) : 0;
    var eleViewMore = document.getElementById('whatshotviewmoreDiv');
    if (eleViewMore !== null) {
      if (totalcount > itemsToDisplay) {
        document.getElementById('whatshotviewmoreDiv').style.display = "block";

      } else {
        document.getElementById('whatshotviewmoreDiv').style.display = "none";

      }
    }
    CommonBookmarkallpage();
    lazyLoadingImg();
    return false;
  }



  if (fields.newsItems != null) {

    let destinationTitle = fields.newsItems.destinationTitle;
    let isNewBrandedSite = fields.newsItems.isRebranded;
    let urlPrefix = fields.newsItems.urlPrefix;
    let title = destinationTitle !== "" ? fields.newsItems.newsTitle + fields.newsItems.symbolHyphen + destinationTitle : fields.newsItems.newsTitle;
    fields.newsItems.headingLeftLink = (fields.newsItems.headingLeftLink) !== null && fields.newsItems.headingLeftLink !== "" ? fields.newsItems.headingLeftLink : (isNewBrandedSite ? urlPrefix + fields.newsItems.slash + fields.newsItems.whatsHotPage : urlPrefix + fields.newsItems.slash + fields.newsItems.whatsHotPage + "/periodicity/Today");
    fields.newsItems.headingRightLink = (fields.newsItems.headingRightLink) ? fields.newsItems.headingRightLink : urlPrefix + fields.newsItems.slash + fields.newsItems.whatsHotPage;
    let stylePerOther = (fields.newsItems.items.length > fields.newsItems.newsPagenumber * fields.newsItems.perOther) ? "" : " display:none !important"
    let stylePerMobile = (fields.newsItems.items.length > fields.newsItems.newsPagenumber * fields.newsItems.perMobile) ? "" : " display:none !important"
    let stylePerTab = ((fields.newsItems.items.length > fields.newsItems.newsPagenumber * fields.newsItems.perTab) ? "" : " display:none !important")

    return fields.newsItems.items != null && fields.newsItems.items.length > 0 ? (
      <section
        className={
          !fields.newsItems.isEventsPage
            ? 'filter-area new news-events three-block'
            : 'filter-area new news-events three-block'
        }
      >
        <div className={'container ' + (isNewBrandedSite ? ' ' : '')}>
          {fields.newsItems.isDestinationPage ? (
            <>
              <div className="header-title destination d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
                {fields.newsItems.whatsHotPage !== null &&
                  fields.newsItems.whatsHotPage !== null &&
                  fields.newsItems.totalNewsCount > 0 ? (
                  <>
                    <h2 className="title">
                      <a
                        href={fields.newsItems.headingLeftLink}
                        aria-label={fields?.newsItems?.newsTitle}
                        className="gtmClickNavigation"
                      >
                        {fields.newsItems.newsTitle}
                      </a>
                      {fields.newsItems.symbolHyphen} <span>{destinationTitle}</span>
                    </h2>
                    {/* <a className="action" href={fields.newsItems.headingRightLink}>
                      <strong>{fields.newsItems.newsLink}</strong>
                      {' (' + fields.newsItems.totalNewsCount + ')'}
                      <span className="picto icon-ico-chevron-right"></span>
                    </a> */}
                  </>
                ) : (
                  <a className="title" href="javascript:void(0)">
                    <h2>{title}</h2>
                  </a>
                )}
              </div>
              <div id="unexpected">
                <div id="row-isflex-flexgrid-clearfix">
                  <MosaicAll
                    model={fields.newsItems}
                    commonValues={fields.newsItems}
                    visibleItemCount={visibleEvents}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
               {/* <h2 className="content-title d-flex text-start news-title ms-0">
                {fields.newsItems.findUnexpectedSomethingDict}
              </h2>  */}
              <div className="row filter-wrap new" id="unexpected">
                <div className="col-12 col-lg-3 filter-category-wrap mb-4">
                  <div
                    className="action btn-show-filter collapsed"
                    id="categoryfilter"
                    data-bs-toggle="collapse"
                    data-bs-target="#brandFilter"
                    aria-expanded="false"
                    aria-controls="brandFilter"
                  >
                    <a className="link-button text-uppercase btn-see-more link-expandable">
                      {fields.newsItems.filterBy} <span className="picto icon-go-down-arrow"></span>
                    </a>
                  </div>
                  <div id="brandFilter" className="brandfilter collapse">
                    <h2 className="filter-wrap-title fw-bold text-uppercase">{fields.newsItems.findUnexpectedSomethingDict}</h2>
                    <ul
                      className={'filter-category-list ' + fields.newsItems.allSelected}
                      id="filter-category-list"
                    >
                      <li>
                        <a
                          href={urlPrefix + fields.newsItems.slash + fields.newsItems.strPageItemName}
                          aria-label={fields?.newsItems?.strPageItemName}
                          className={fields.newsItems.allSelected}
                          aria-expanded="false"
                          data-testid="testClick1"
                          onClick={(e) =>
                            fnGtmCategorySearchFilter(
                              e.target,
                              fields.newsItems.allEventsGTM,
                              '',
                              fields.newsItems.totalNewsCount,
                              'News',
                              'News List'
                            )
                          }
                        >
                          {fields.newsItems.allEvents + ' (' + fields.newsItems.totalNewsCount + ')'}
                        </a>
                      </li>
                      <>
                        {fields.newsItems.periodicityCategories !== null &&
                          fields.newsItems.periodicityCategories.length > 0 && (
                            <>
                              <hr />
                              <h2 className="filter-wrap-title text-uppercase">
                                {fields.newsItems.periodicityTitle}
                              </h2>
                              {fields.newsItems.periodicityCategories.map((newsGroup, index) => {
                                return (
                                  <li key={index}>
                                    <a                                      
                                      href={
                                        urlPrefix +
                                        fields.newsItems.slash +
                                        fields.newsItems.strPageItemName +
                                        fields.newsItems.slash +
                                        fields.newsItems.periodicitySegment +
                                        fields.newsItems.slash +
                                        newsGroup.categoryUrl
                                      }
                                      className={newsGroup.class}
                                      data-testid="testClick2"
                                      onClick={(e) =>
                                        fnGtmCategorySearchFilter(
                                          e.target,
                                          '',
                                          newsGroup.perodicityItemName,
                                          newsGroup.newsCount,
                                          'News',
                                          'News List'
                                        )
                                      }
                                      aria-expanded="false"
                                    >
                                      {newsGroup.categoryName + ' (' + newsGroup.newsCount + ')'}
                                    </a>
                                  </li>
                                );
                              })}
                            </>
                          )}
                        {fields.newsItems.commercialCategories !== null &&
                          fields.newsItems.commercialCategories.length > 0 && (
                            <>
                              <hr />
                              <h2 className="filter-wrap-title text-uppercase">
                                {fields.newsItems.commercialCategoryTitle}
                              </h2>
                              {fields.newsItems.commercialCategories.map((newsGroup, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      href={
                                        urlPrefix +
                                        fields.newsItems.slash +
                                        fields.newsItems.strPageItemName +
                                        fields.newsItems.slash +
                                        fields.newsItems.categorySegment +
                                        fields.newsItems.slash +
                                        newsGroup.categoryUrl
                                      }
                                      className={newsGroup.class}
                                      aria-expanded="false"
                                      data-testid="testClick3"
                                      onClick={(e) =>
                                        fnGtmCategorySearchFilter(
                                          e.target,
                                          newsGroup.categoryName,
                                          '',
                                          newsGroup.newsCount,
                                          'News',
                                          'News List'
                                        )
                                      }
                                    >
                                      {newsGroup.categoryName + ' (' + newsGroup.newsCount + ')'}
                                    </a>
                                  </li>
                                );
                              })}
                            </>
                          )}
                      </>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-9 filter-result-wrap" id="filter-result-wrap-news">
                  <MosaicAll
                    model={fields.newsItems}
                    commonValues={fields.newsItems}
                    visibleItemCount={visibleEvents}
                  />
                </div>
              </div>
            </>
          )}
          {fields.newsItems.items != null && isNewBrandedSite && (
            <div id="whatshotviewmoreDiv">
              <div
                className="text-end black-view-more my-2 d-none d-lg-block"
                id="whatshotviewmore"
                style={{ display: stylePerOther }}
              >
                <div
                  id="viewmorepageother"
                  className="grid-new-cta gtmViewMoreClick"
                  data-testid="testClick4"
                  onClick={(e) => eventsViewMore(e, EventsPerOther, false)}
                >
                  {fields.newsItems.viewMoreDict}
                  <span className="picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5"></span>
                </div>
              </div>
              <div
                className="view-more-unexpected d-block d-sm-none"
                id="whatshotviewmoremobile"
                style={{ display: stylePerMobile }}
              >
                <div
                  id="viewmorepagemobile"
                  className="gtmViewMoreClick"
                  data-testid="testClick5"
                  onClick={(e) => eventsViewMore(e, EventsPerMobile, false)}
                >
                  {fields.newsItems.viewMoreDict}
                </div>
              </div>
              <div
                className="view-more-unexpected d-md-block d-none d-xl-none"
                id="whatshotviewmoretab"
                style={{ display: stylePerTab }}
              >
                <div
                  id="viewmorepagetab"
                  className="gtmViewMoreClick"
                  data-testid="testClick6"
                  onClick={(e) => eventsViewMore(e, EventsPerTab, false)}
                >
                  {fields.newsItems.viewMoreDict}
                </div>
              </div>
            </div>
          )}
        </div>

        {fields.newsItems.items != null && !isNewBrandedSite && (
          <div id="whatshotviewmoreDiv">
            <div
              className="text-end black-view-more my-2 d-none d-lg-block"
              id="whatshotviewmore"
              style={{ display: stylePerOther }}
            >
              <a
                id="viewmorepageother"
                href="#whatshotviewmore"
                className="grid-new-cta gtmViewMoreClick"
                data-testid="testClick7"
                onClick={(e) => eventsViewMore(e, EventsPerOther, true)}
              >
                {fields.newsItems.viewMoreDict}
                {/* <br /> */}
                <span className="picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5"></span>
              </a>
            </div>
            <div
              className="view-more-unexpected gtmViewMoreClick d-block d-sm-none"
              id="whatshotviewmoremobile"
              style={{ display: stylePerMobile }}
            >
              <a
                id="viewmorepagemobile"
                href="#whatshotviewmoremobile"
                className="gtmViewMoreClick"
                data-testid="testClick8"
                onClick={(e) => eventsViewMore(e, EventsPerMobile, true)}
              >
                {fields.newsItems.viewMoreDict}
                <br />
                <i className="picto icon-go-down-arrow"></i>
              </a>
            </div>
            <div
              className="view-more-unexpected  d-md-block d-none d-xl-none"
              id="whatshotviewmoretab"
              style={{ display: stylePerTab }}
            >
              <a
                href="#whatshotviewmoretab"
                aria-label={fields?.newsItems?.viewMoreDict}
                id="viewmorepagetab"
                className="gtmViewMoreClick"
                data-testid="testClick9"
                onClick={(e) => eventsViewMore(e, EventsPerTab, true)}
              >
                {fields.newsItems.viewMoreDict}
                <br />
                <i className="picto icon-go-down-arrow"></i>
              </a>
            </div>
          </div>
        )}
      </section>
    ) : fields.newsItems.isDestinationPage ? (
      <section className="whats-hot-mosiac bg-2">
        <div className="container">
          <div className="header-title destination d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
            {fields.newsItems.whatsHotPage !== null &&
              fields.newsItems.whatsHotPage !== '' &&
              fields.newsItems.totalNewsCount > 0 ? (
              <>
                <h2 className="title">
                  <a
                    href={fields.newsItems.HeadingLeftLink}
                    aria-label={fields?.newsItems?.newsTitle}
                    className="gtmClickNavigation"
                  >
                    {fields.newsItems.newsTitle}
                  </a>{' '}
                  {fields.newsItems.symbolHyphen} <span>{destinationTitle}</span>
                </h2>
                {/* <a className="action" href={fields.newsItems.headingRightLink}>
                  <strong>{fields.newsItems.newsLink}</strong>{' '}
                  {'(' + fields.newsItems.totalNewsCount + ')'}
                  <span className="picto icon-ico-chevron-right"></span>
                </a> */}
              </>
            ) : (
              <a className="title" href="javascript:void(0)">
                <h2>{title}</h2>
              </a>
            )}
          </div>
          <div className="picto-wrap emptystate">
            <span
              className={
                'picto' +
                (isNewBrandedSite ? ' icon-servicesaspictosservices' : 'iconloyalty-event')
              }
            ></span>
            <p className="text-uppercase">{fields.newsItems.emptyText}</p>
          </div>
        </div>
      </section>
    ) : (
      <></>
    );
  } else {
    return <></>;
  }
}
