import axios from 'axios';
import Enumerable from "linq";
import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { baseModel } from '../../RouteHandler';
import { AddFavCenterDatalayer, sortCenterList } from '../Js-CommonFunctions';
import TermsAndConditionPopin from '../TermsAndConditionPopin';



export default function SelectShoppingCenter({ fields, consent, preferredCenters, newBenefitsAndServices, updateLegalTextId, legalTextIdInfo, visitorInfo }) {
  const [centerList, setCenterList] = useState(fields.centersList);


  useEffect(() => {
    setCenterList(sortCenterList(Enumerable.from(centerList).orderBy(x => x.label).toArray()));
    loadcountryscript();
  }, []);

  function callbackfromconsentpopin(centerName, pcCode, countryCode, legalTextId) {
    updateLegalTextId(legalTextId)
    fnAddCenter(centerName, pcCode, countryCode, true);
  }
  function btnAddClick(centerName, pcCode, countryCode) {
    // if (document.getElementById('loaderSC')) {
    //   document.getElementById('loaderSC').classList.add('d-block');
    //   document.getElementById('loaderSC').classList.remove('d-none');
    // }
    var countryExists = preferredCenters.filter(c => c.countryCode === countryCode);
    var showConsentPopin = countryExists.length === 0 && legalTextIdInfo.hasCommerctialOptIn && legalTextIdInfo.latestlegalTextId !== legalTextIdInfo.visitorOptedLegalTextId ? true : false;
    if (showConsentPopin) {
      axios({
        method: "post",
        url: "/api/sitecore/VisitorApi/GetVisitorRequiredConsent", //"/api/sitecore/VisitorApi/GetVisitorRequiredConsent?pcCode=" + pcCode + "&latestlegalTextId=" +legalTextIdInfo.latestlegalTextId,
        data: {
          pcCode: pcCode,
          latestlegalTextId: legalTextIdInfo.latestlegalTextId,
          visitorId: visitorInfo.visitorId,
          accessToken: visitorInfo.accessToken,
          refreshToken: visitorInfo.refreshToken,
        },
        async: false,
      })
        .then(function (response) {
          if (response.data[0]) {
            //close popin
            var popinClick = document.getElementById("popinPreferredSCClick");
            popinClick.click();
            ReactDOM.render(<TermsAndConditionPopin model={consent} preferredCenters={preferredCenters} pcCode={pcCode} centerName={centerName} countryCode={countryCode} legalTextId={legalTextIdInfo.latestlegalTextId} callbackfromconsentpopin={callbackfromconsentpopin} isFromBenefits={true} />,
              document.getElementById("VisitorTermsAndConditionPopin"))
            // Triggers the Popin
            var popinClick = document.getElementById("VisitorTermsAndConditionPopinClick");
            popinClick.click();
          }
          else {
            fnAddCenter(centerName, pcCode, countryCode, false)
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
    else {
      fnAddCenter(centerName, pcCode, countryCode, false)
    }

  }

  function fnAddCenter(centerName, pcCode, countryCode, showConsentPopin) {
    axios({
      method: "post",
      url: "/api/sitecore/VisitorApi/CreateVisitorCenter", //?pcCode=" + pcCode,
      data: {
        pcCode: pcCode,
        visitorId: visitorInfo.visitorId,
        accessToken: visitorInfo.accessToken,
        refreshToken: visitorInfo.refreshToken,
      },
      async: false,
    })
      .then(function (response) {
        if (response.data[0] != undefined && response.data[0] != null && response.data[0] != "") {
          if (response.data[2] !== null && response.data[2] !== undefined && response.data[2] !== "") {
            AddFavCenterDatalayer(response.data[2])
          }
          newBenefitsAndServices(centerName, response.data[0], countryCode);

          var ele = document.getElementById("preferredSC");
          if (fields.isMobileView) {
            document.getElementById('add-preferred-sc').classList.remove('d-none')
            if (document.getElementById('selectshoppingcenterview'))
              document.getElementById('selectshoppingcenterview').remove();
          }
          else {
            if (!showConsentPopin) {
              if (ele !== null && ele !== undefined) {

                var popinClick = document.getElementById("popinPreferredSCClick");
                popinClick.click();
                // document.getElementById('loaderSC').classList.remove('d-block');
              }
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function CountrySelect(countryCode) {
    if (countryCode !== null && countryCode !== "") {
      axios({
        method: "post",
        url:
          "/api/sitecore/VisitorApi/GetCenterListWithCountryCode", //?ursite=" + baseModel.urContextSite + "&urlang=" +baseModel.contextLang +"&countryCode=" +countryCode,
        data: {
          ursite: baseModel.urContextSite,
          urlang: baseModel.contextLang,
          countryCode: countryCode,
          accessToken: visitorInfo.accessToken,
          refreshToken: visitorInfo.refreshToken,
        },
        async: false,
      })
        .then(function (response) {
          if (response.data[1] === "Success") {
            var centerlist = response.data[0];
            centerlist = Enumerable.from(centerlist).orderBy(x => x.label).toArray();
            centerlist = sortCenterList(centerlist);
            setCenterList(centerlist);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  function loadcountryscript() {
    var x, i, j, l, ll, selElmnt, selSpan, a, b, c, sp;
    /*look for any elements with the class "custom-select":*/
    x = document.getElementsByClassName("custom-select");
    l = x.length;
    for (i = 0; i < l; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      selSpan = x[i].getElementsByTagName("span")[0];
      ll = selElmnt.length;
      /*for each element, create a new DIV that will act as the selected item:*/
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex]?.innerHTML;

      sp = document.createElement("SPAN");
      sp.setAttribute("class", "icon-go-down-arrow dropdown-icon");
      //sp.innerHTML = selSpan.options[selSpan.selectedIndex].innerHTML;
      a.appendChild(sp);
      x[i].appendChild(a);
      //x[i].appendChild(sp);
      /*for each element, create a new DIV that will contain the option list:*/
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 0; j < ll; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        c = document.createElement("DIV");
        c.setAttribute("data-countrycode", selElmnt.options[j]?.getAttribute('data-countrycode'))
        if (j % 2 === 0) {
          c.setAttribute("class", "bg-dropdown");
        }

        c.innerHTML = selElmnt.options[j]?.innerHTML;
        c.addEventListener("click", function (e) {
          /*when an item is clicked, update the original select box,
          and the selected item:*/
          var countrycode = e.currentTarget.getAttribute('data-countrycode');
          CountrySelect(countrycode);
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              h.appendChild(sp)
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              //this.setAttribute("class", "same-as-selected");

              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-selected-box-shadow");
      });
    }

    /*if the user clicks anywhere outside the select box,
    then close all select boxes:*/
    document.addEventListener("click", closeAllSelect);
  }
  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, xl, yl, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  function closePopin() {
    if (fields.isMobileView) {
      document.getElementById('add-preferred-sc').classList.remove('d-none')
      if (document.getElementById('selectshoppingcenterview'))
        document.getElementById('selectshoppingcenterview').remove();
    } else {
      if (document.getElementById("popinPreferredSCClick")) {
        var popinClick = document.getElementById("popinPreferredSCClick");
        popinClick.click();
      }
    }

  }
  if (fields.isMobileView) {
    return (
      <>
        <div className="account-main d-block" id="preferredSC">
          <div className="select-shopping-center modal-content ">
            <div className=" d-block modal-header ">
              <div className="account-main d-lg-none d-block d-xl-none">
                <div className="basic-benefits-container d-md-none d-lg-none d-xl-none bg-white">
                  <div className="container modalContainer p-0 ">
                    <nav className="benefits-header-height px-lg-1 row m-0 py-2">
                      <div className="col-2 text-center px-0">
                        <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                          href="javascript:void(0);" onClick={() => { closePopin() }}></a>
                      </div>
                      <div className="col-8 p-0 text-center">
                        <div className=" text-center benefits-subtitle ">{fields.preferredCenterSelTitle}</div>
                      </div>
                      <div className="col-2 text-center px-0">
                      </div>
                    </nav>
                  </div>
                </div>
              </div>

              <div className="m-auto text-center px-4" >
                <nav className="navbar col-sm-12 col-md-12 col-lg-12 py-0">
                  <div className="col-md-1 col-sm-1 p-0 col-lg-1 text-center">
                  </div>
                  <div className="col-md-10 col-sm-10 p-0 col-lg-10 text-center">
                    <div className="shopping-center-title">{fields.selectSCTitle}</div>
                  </div>

                  <div className="col-md-1 col-sm-1 col-lg-1 align-items-center d-flex text-center px-0">
                    <a href="#" className="icon-cross no-underline text-black close-icon-select-shopping-center" data-bs-dismiss="modal" aria-label="Close"></a>
                  </div>
                </nav>
              </div>
            </div>

            <div className="modal-body text-center px-lg-5 py-1">
              <div className="m-auto text-center">
                <div className="select-shopping-container">

                  <div className="d-flex communications-profile-title-content mb-2">{fields.changeCountryTitle}
                  </div>
                  <div className="container preferred-sc-container1 w-100 px-0">
                    <div className="row custom-select countries-list-box mx-0">
                      <select name="countries-list" id="countries">
                        {fields.countryList !== undefined && fields.countryList !== null && fields.countryList !== "" && fields.countryList.length > 0 &&
                          fields.countryList.map((item, index) => {
                            return (
                              <option value={item.countryName} id={"Country" + index} data-countrycode={item.countryCode} key={index} >{item.countryName}</option>
                            )
                          })}

                      </select>
                    </div>
                  </div>
                  <div className="container preferred-sc-container2 w-100 px-0">
                    {/* <div className="loadersmall asyncloader d-none" id="loaderSC"   ></div> */}
                    {centerList !== undefined && centerList !== null && centerList !== "" &&
                      centerList.length > 0 && centerList.map((center, centerindex) => {
                        return (
                          <div key={centerindex} className="row mx-0">
                            {/* <div className="text-center text-decoration-none shopping-center-container ">
                              <div className="d-flex align-items-center">
                                <div className="flex-start shopping-center-country">{center.label}  </div>
                                <a href="javascript:void(0);" onClick={(e) => { btnAddClick(center.label, center.pcCode, center.country) }} className="flex-grow-1 text-end  text-decoration-underline add-shopping-center" >
                                  {fields.btnAdd}
                                </a>
                              </div>
                            </div> */}
                            <a href="javascript:void(0);" onClick={(e) => { btnAddClick(center.label, center.pcCode, center.country) }}
                              className="text-center text-decoration-none shopping-center-container">
                              <div className="d-flex align-items-center">
                                <div className="flex-start shopping-center-country">{center.label}</div>
                                <span className="flex-grow-1 text-end text-decoration-underline add-shopping-center">
                                  {fields.btnAdd}
                                </span>
                              </div>
                            </a>
                          </div>
                        )
                      })}

                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </>
    )
  }
  else {
    return (
      <>
        <div className="my-lg-2 m-lg-auto m-0 modal-dialog account-main h-100 d-block" id="preferredSC">
          <div className="select-shopping-center modal-content h-100 p-0">
            <div className=" d-block modal-header p-0">
              <div className="account-main d-lg-none d-block d-xl-none">
                <div className="basic-benefits-container d-md-none d-lg-none d-xl-none bg-white">
                  <div className="container modalContainer p-0 ">
                    <nav className="benefits-header-height px-lg-1 row m-0   py-2">
                      <div className="col-2 text-center px-0">
                        <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                          href="javascript:void(0);" onClick={() => { closePopin() }}></a>
                      </div>
                      <div className="col-8 p-0 text-center">
                        <div className=" text-center benefits-subtitle ">{fields.preferredCenterSelTitle}</div>
                      </div>
                      <div className="col-2 text-center px-0">
                      </div>
                    </nav>
                  </div>
                </div>
              </div>

              <div className="m-auto text-center px-4" >
                <nav className="navbar col-sm-12 col-md-12 col-lg-12 py-0">
                  <div className="col-md-1 col-sm-1 p-0 col-lg-1 text-center">
                  </div>
                  <div className="col-md-10 col-sm-10 p-0 col-lg-10 text-center">
                    <div className="shopping-center-title">{fields.selectSCTitle}</div>
                  </div>

                  <div className="col-md-1 col-sm-1 col-lg-1 align-items-center d-flex text-center px-0">
                    <a href="#" className="icon-cross no-underline text-black close-icon-select-shopping-center" data-bs-dismiss="modal" aria-label="Close"></a>
                  </div>
                </nav>
              </div>
            </div>

            <div className="modal-body text-center px-lg-5 py-1">
              <div className="m-auto text-center">
                <div className="select-shopping-container">

                  <div className="d-flex communications-profile-title-content mb-2">{fields.changeCountryTitle}
                  </div>
                  <div className="container preferred-sc-container1 w-100 px-0">
                    <div className="row custom-select countries-list-box mx-0">
                      <select name="countries-list" id="countries">
                        {fields.countryList !== undefined && fields.countryList !== null && fields.countryList !== "" && fields.countryList.length > 0 &&
                          fields.countryList.map((item, index) => {
                            return (
                              <option value={item.countryName} id={"Country" + index} data-countrycode={item.countryCode} key={index}>{item.countryName}</option>
                            )
                          })}

                      </select>
                    </div>
                  </div>
                  <div className="container preferred-sc-container2 w-100 px-0">
                    {/* <div className="loadersmall asyncloader d-none" id="loaderSC"   ></div> */}
                    {centerList !== undefined && centerList !== null && centerList !== "" &&
                      centerList.length > 0 && centerList.map((center, centerindex) => {
                        return (
                          <div key={centerindex} className="row mx-0">
                            {/* <div className="text-center text-decoration-none shopping-center-container ">
                              <div className="d-flex align-items-center">
                                <div className="flex-start shopping-center-country">{center.label}  </div>
                                <a href="javascript:void(0);" onClick={(e) => { btnAddClick(center.label, center.pcCode, center.country) }} className="flex-grow-1 text-end  text-decoration-underline add-shopping-center" >
                                  {fields.btnAdd}
                                </a>
                              </div>
                            </div> */}
                            <a href="javascript:void(0);" aria-label={center?.label} onClick={(e) => { btnAddClick(center.label, center.pcCode, center.country) }}
                              className="text-center text-decoration-none shopping-center-container">
                              <div className="d-flex align-items-center">
                                <div className="flex-start shopping-center-country">{center.label}</div>
                                <span className="flex-grow-1 text-end text-decoration-underline add-shopping-center">
                                  {fields.btnAdd}
                                </span>
                              </div>
                            </a>
                          </div>
                        )
                      })}

                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </>

    )
  }
  // } else {
  //   return <></>
  // }
}





