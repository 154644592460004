import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';

export default function SubscriptionMessagePopin({ model }) {
  useEffect(() => {
    var ele = document.getElementById("SubscriptionSuccessFailure");
    if (ele !== null && ele !== undefined) {
      var popinClick = document.getElementById("popinClick");
      if (popinClick !== null)
        popinClick.click();
    }
  }, []);

  if (model !== null) {
    return (
      <div className="modal-dialog popin-reset" id="SubscriptionSuccessFailure">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="picto icon-close-circle-rw border-0 fs-4 bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body text-center">
            <div className="popin-wrap">
              {(model.management_Introduction_Title !== "" && model.management_Introduction_Title !== null) &&
                <Text className="msg-title text-uppercase" field={model.management_Introduction_Title} tag="h3" />
              }
              <div className="msg-text">{model.management_Introduction}</div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}