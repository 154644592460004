import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { fnGtmOfferClick, offersClick } from "../Js-CommonFunctions";
import { baseModel } from "../../RouteHandler";

export default function Deals({ model, landingOfferModel, values }) {
  let isDetailPage = model?.isStoreOrRestaurantDetailPage;
  let isOffersPage =
    landingOfferModel.url ===
    landingOfferModel.urlPrefix + landingOfferModel.slash + landingOfferModel.loyaltyOfferPage;
  let isConnected = landingOfferModel.uID != null && landingOfferModel.uID != '';
  let isNewBrandedSite = model?.isNewBrandedSite;
  let strStyleclassName = '';
  let offerIndex = 0;

  if (model !== null && model !== undefined && model !== "") {
    if (
      model != null &&
      model.totalOffersCount == 2 &&
      !isOffersPage &&
      !isDetailPage &&
      model.destinationType != null &&
      model.destinationType === ''
    ) {
      //block size do not change for Offers page, store/rest. detail pages and destination pages
      strStyleclassName = 'col-lg-6';
    } else if (isDetailPage || model.offerTypeParameter === model.newsDetailOffer) {
      strStyleclassName = isNewBrandedSite ? 'col-lg-4' : 'col-lg-3';
    } else if (model != null && model.destinationType !== null && model.destinationType !== '') {
      strStyleclassName = isNewBrandedSite ? 'col-lg-4' : 'col-lg-3';
    } else {
      strStyleclassName = 'col-lg-4';
    }

    return (
      <>
        {model.offerItems !== null && model.offerItems.slice(0, values).map((offeritems, index) => {
          if (offeritems != null) {
            offerIndex++;
            let storeName = '';
            let offerRetailerName = '';
            let offerAnchor = '';
            let isOfferLogo = true;
            let logoSource = '';
            let logoAltText = '';
            let offerLink = '';
            let OfferStoreName = '';
            let retailerName = '';
            let offerStickerName = '';
            let strCommercialOperation =
              offeritems.commercialOperation != null &&
                offeritems.commercialOperation.name != null &&
                offeritems.commercialOperation.name != ''
                ? offeritems.commercialOperation.name
                : '';
            if (offeritems.offer != null) {
              if (
                offeritems.offer.featured &&
                (offeritems.offer == null ||
                  (offeritems.offer.categoryIds == null &&
                    offeritems.offer.categoryIds.length > 0 &&
                    !offeritems.offer.categoryIds[0].value ===
                    landingOfferModel.commercialTypeOffer))
              ) {
                offerAnchor = landingOfferModel.offerAnchorTopOffer;
              } else {
                if (offeritems.offer !== null && offeritems.offer.categoryIds != null) {
                  if (strCommercialOperation !== null && strCommercialOperation !== '') {
                    let offerAnchorHref = strCommercialOperation.toLowerCase().replace(' ', '');
                    offerAnchor = offerAnchorHref;
                  }
                }
              }
            }

            if (offeritems.storeitem != null) {
              storeName =
                offeritems.storeitem != null &&
                  offeritems.storeitem.name != null &&
                  offeritems.storeitem.name != ''
                  ? offeritems.storeitem.name
                  : '';
              isOfferLogo = offeritems.imgLogoURL != null && offeritems.imgLogoURL != '';
              logoSource = offeritems.imgLogoURL;
              logoAltText = offeritems.imgLogoAltText;
              offerLink = offeritems.storeLinkURL.toString().startsWith('.')
                ? offeritems.storeLinkURL.toString().trimStart('.')
                : offeritems.storeLinkURL;
              OfferStoreName =
                offeritems.storeitem != null &&
                  offeritems.storeitem.name != null &&
                  offeritems.storeitem.name != ''
                  ? offeritems.storeitem.name
                  : '';
              offerStickerName =
                offeritems != null && offeritems.offer != null ? offeritems.offer?.sticker : '';
              retailerName = OfferStoreName;
              offerRetailerName = offeritems.storeitem != null &&
                offeritems.storeitem.retailerName != null &&
                offeritems.storeitem.retailerName != ''
                ? offeritems.storeitem.retailerName
                : '';
            } else {
              isOfferLogo =
                offeritems.externalOfferLogo != null && offeritems.externalOfferLogo != '';
              logoSource = offeritems.externalOfferLogo.includes(landingOfferModel.imgCloudinary)
                ? offeritems.scaleExternalOfferLogo
                : offeritems.maxExternalOfferLogo;
              logoAltText = offeritems.externalOfferLogoAlt;
              offerLink =
                landingOfferModel.uID != null && landingOfferModel.uID != ''
                  ? offeritems.externalOfferLink + '?FidID=' + landingOfferModel.uID
                  : offeritems.externalOfferLink;
              offerStickerName =
                offeritems != null && offeritems.offer != null ? offeritems.offer.sticker : '';
              OfferStoreName =
                offeritems.offer != null &&
                  offeritems.offer.title != null &&
                  offeritems.offer.title != ''
                  ? offeritems.offer.title
                  : '';
              retailerName =
                offeritems != null &&
                  offeritems.offer != null &&
                  offeritems.offer.title != null &&
                  offeritems.offer.title != ''
                  ? offeritems.offer.title
                  : '';
            }

            var offerdesc = { value: offeritems.offer?.description };
            var offerterms = { value: offeritems.offer?.termsAndConditions };
            var subtitle = { value: offeritems.offer.subtitle };
            var offerImageClass =
              model.isOffersComponent && offeritems.offer?.image != ''
                ? 'offers-header-back-img h-auto'
                : 'offers-header';
            var tooltipClass =
              model.isOffersComponent && offeritems.offer?.image != ''
                ? 'offers-tooltip-btn-wrap-back'
                : 'offers-tooltip-btn-wrap';
            var offerImage =model.isOffersComponent && offeritems.offer.image != "" ?  "d-flex offer-img-new w-100 p-0" :"d-flex p-0 home-offers-image"

            return (
              <div key={index}
                className={'col-12 ' + strStyleclassName + ' flip-card contentBlock'}
                data-link={offerLink}
                data-testid='testclick1'
                onClick={(e) => {
                  fnGtmOfferClick(offeritems.offer.title, storeName, offerRetailerName, offeritems.eligibilityText, false, model.isNewBrandedSite);
                  offersClick(
                    e,
                    offerLink,
                    'offers',
                    offerAnchor,
                    isDetailPage,
                    landingOfferModel.siteUrlPrefix
                  )
                }
                }
                aria-label={offeritems.offer?.dealId}
                data-istop={offeritems.offer?.featured}
                data-letterfilter={offeritems.letterForFilter}
              >
                <div
                  className={
                    'offers-thumbnail flip-front position-relative border-0 rounded-0 p-0 shadow-none' +
                    (model.isUSUK ? landingOfferModel.uS_UK_StoreLogo : '')
                  }
                >
                  <div className="add-wishlist pull-right">
                    <a
                      className="ava-wishlist bookmark-news-new bookmark picto icon-heart-unfill-rw "
                      role="button"
                      href="javascript:void(0);"
                      aria-label={offeritems.offer?.dealId}
                      aria-labelledby="deal"
                      data-itemlabel={offeritems.offer?.title}
                      data-templatetype="offers"
                      data-storename={storeName}
                      data-retailerName={offerRetailerName}
                      data-testid='testclick2'
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    ></a>
                  </div>

                  {offeritems.offer !== null && offeritems.offer.termsAndConditions != null &&
                    offeritems.offer.termsAndConditions != '' && (
                      <div className={tooltipClass + ' position-absolute rounded-circle js-tooltip'}>
                        {model.isOffersComponent ? (
                          <span className="picto icon-infos gtmFlipOffer js-tooltip"></span>
                        ) : (
                          <button
                            type="button"
                            className="js-tooltip tooltip-btn border-0 bg-transparent p-0 text-center position-relative gtmFlipOffer"
                          >
                            i
                          </button>
                        )}
                      </div>
                    )}

                  <a aria-label={logoAltText} className={offerImageClass + ' d-flex position-relative'}>
                    {/* <span className="helper"></span> */}
                    {isOfferLogo && offeritems.offer?.featured ? (
                      <img
                        src={logoSource}
                        className={offerImage}
                        alt={logoAltText}
                        title={logoAltText}
                      ></img>
                    ) : isOfferLogo ? (
                      <img
                        src={landingOfferModel.defaultImage}
                        className={offerImage}
                        data-alt={logoSource}
                        alt={logoAltText}
                        title={logoAltText}
                      ></img>
                    ) : offeritems.storeitem != null &&
                      offeritems.storeitem.storeTypeIds != null ? (
                      <span className="picto icon-ico-ph-shops"></span>
                    ) : (
                      <></>
                    )}
                    {model.isNewBrandedSite ?
                      (offeritems.eligibilityText != null && offeritems.eligibilityText != "" && baseModel.westfieldClubLogo?.value !== null && baseModel.westfieldClubLogo?.value !== "" && baseModel.westfieldClubLogo?.value.src !== null && baseModel.westfieldClubLogo?.value?.src !== "" &&
                        <div className='westfield-club-logo d-flex align-items-center justify-content-center w-100'>
                          <img className="d-flex wclub-img" src={baseModel.westfieldClubLogo?.value?.src} loading="lazy"
                            alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                        </div>
                      ) :
                      (offeritems.eligibilityText != null && offeritems.eligibilityText != "" && baseModel.clubLogo?.value !== null && baseModel.clubLogo?.value !== "" && baseModel.clubLogo?.value.src !== null && baseModel.clubLogo?.value?.src !== "" &&
                        <div className='westfield-club-logo d-flex align-items-center justify-content-center w-100'>
                          <img className="d-flex wclub-img" src={baseModel.clubLogo?.value?.src} loading="lazy"
                            alt={baseModel.clubLogo?.value?.alt} title="club" />
                        </div>
                      )
                    }
                  </a>
                  <div className="offers-caption">
                    <div className='text-start retailer-name my-1' onClick={(e) => {
                      e.preventDefault();
                      return this.closest('.flip-card').click(this);
                    }}>{OfferStoreName}
                    </div>
                    <div className={'offer-title-new ' + (model.isNewBrandedSite ? "" : " mb-2")} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>{offeritems?.offer?.title}</div>
                    <div className='date-offer text-start my-1'>{offeritems.validity}</div>
                    <div className='d-flex align-items-start discount-block my-2'>
                      {offeritems.offer.sticker !== null && offeritems.offer.sticker !== "" && offeritems.offer.sticker !== undefined &&
                        <div className='discount-new rounded-3'>{offeritems.offer.sticker}</div>
                      }
                      {offeritems.offer.subtitle !== null && offeritems.offer.subtitle !== "" && offeritems.offer.subtitle !== undefined &&
                        <div className={'discount-description text-start' + (offeritems.offer.sticker !== null && offeritems.offer.sticker !== "" && offeritems.offer.sticker !== undefined ? "" : " p-0")}><RichText field={subtitle} /></div>
                      }
                    </div>
                    {(!model.isEuropeanSite && offeritems.offer.description !== null && offeritems.offer.description !== "") &&
                      <div className={'discount-description text-start p-0 my-1'}><RichText field={offerdesc} /></div>
                    }
                    {offeritems.commercialOperationData !== null ?
                      (
                        <>
                          {offeritems.commercialOperationData?.map((category, index) => {
                            return (
                              <a key={index} className={"offer-status p-0" + (model.isNewBrandedSite ? "" : " text-uppercase text-emmet fw-bold")}>
                                {(category != null && category.categoryPicto != null && category.categoryPicto != "") &&
                                  <img src={landingOfferModel.defaultImage} data-alt={category.categoryPicto} alt={category.categoryName}></img>
                                }
                                <span className={(model.isNewBrandedSite ? "text-uppercase" : "")}>{category.categoryName}</span>
                              </a>
                            )
                          })
                          }

                          {(offeritems.commercialOperationNoPicto != null && offeritems.commercialOperationNoPicto != "") &&
                            <a className={"offer-status p-0" + (model.isNewBrandedSite ? "" : " text-uppercase text-emmet fw-bold")}>
                              <span className={(model.isNewBrandedSite ? "text-uppercase" : "")}>{offeritems.commercialOperationNoPicto}</span>
                            </a>
                          }
                        </>

                      ) :
                      (
                        <a className={"offer-status " + (model.isNewBrandedSite ? "" : "text-uppercase text-emmet fw-bold")}><span className={(model.isNewBrandedSite ? "text-uppercase" : "")}></span></a>
                      )
                    }
                  </div >
                </div >
                <div
                  className={
                    'offers-thumbnail bg-white flip-back position-relative border-0 rounded-0 ' +
                    (model.isNewBrandedSite ? 'p-1' : 'p-1') +
                    (model.isUSUK ? landingOfferModel.uS_UK_StoreLogo : '')
                  }
                  style={{ display: 'none' }}
                >
                  {model.isNewBrandedSite ? (
                    <span className="flip-message">
                      <RichText field={offerterms} />
                    </span>
                  ) : (
                    <div className="flip-message">
                      <RichText field={offerterms} />
                    </div>
                  )}
                  <span className="flip-close picto icon-close-circle-rw"> </span>
                </div>
              </div >
            );
          }
        })}

        {
          model != null && (model.offerItems == null || model.offerItems.length < 1) && (
            <div className="picto-wrap emptystate">
              <span className="picto icon-servicesaspictosoffers"></span>
              <p className="text-uppercase">{landingOfferModel.offerEmptyText}</p>
            </div>
          )
        }

        {
          model != null && model.showViewMore && (
            <>
              <input type="hidden" id="firstviewmorecount" value={model.offerItems.length} />
              <input type="hidden" id="totalviewmorecount" value={model.totalOffersCount} />
            </>
          )
        }
      </>
    );
  }
}
