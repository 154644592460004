import Enumerable from "linq";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler.js';
import {
    HeaderScripts, changeBurgerMenuIconForCenter, checkWebViewEqualsOne, closeBurgerMenu,
    fnConnectRedirectTodetailPage, fnDiscoverAnotherCenterPopin, fnRedirectToConnectLogin,
    fnRedirectToMyAccount, fngtmExtraLiveClick,
    gtmFuncClickAccountHeader,
    handlePageScrollBarOnMenuOpened, isMobile,
    logoutFromGS, loyaltyProgramDataLayer, menuDataLayer, menuElementDataLayer,
    menuLoyaltyProgramDataLayer
} from '../Js-CommonFunctions';

export var antiForgeryToken = {};
export default function Header({ fields, rendering }) {
    let i = 0;
    antiForgeryToken = rendering;
    Object.freeze(antiForgeryToken);
    const [visitorInitials, setVisitorInitials] = useState(''); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render

    var isSearchPage = (baseModel.currentPageItemName != undefined && baseModel.currentPageItemName != null &&
        baseModel.currentPageItemName.toLowerCase() == global.searchPage);
    var isHomePage = (baseModel.currentPageItemName != undefined && baseModel.currentPageItemName != null &&
        baseModel.currentPageItemName.toLowerCase() == "homepage");
    var hasWebviewEqualsOne = checkWebViewEqualsOne();
    var initials = useSelector((state) => state.user.initials);


    useEffect(() => {
        if (!hasWebviewEqualsOne) {
            closeBurgerMenu();
            HeaderScripts();
            // disable page scrollbar when burgermenu opens
            handlePageScrollBarOnMenuOpened();
            var timeoutId;

            var menulist = document.querySelectorAll(".MenuMegaDrop");
            if (menulist !== null && menulist !== undefined && menulist.length > 0) {
                menulist.forEach(function (divEl, ind) {

                    divEl.addEventListener('mouseover', function () {
                        document.querySelectorAll('.MenuMegaDrop.hover')
                            .forEach(element => {
                                element.classList.remove('hover');
                            });
                        divEl.classList.add('hover');
                        if (divEl.childNodes[1])
                            divEl.childNodes[1].classList.add("d-lg-block")

                        if (timeoutId) {
                            clearTimeout(timeoutId);
                        }
                    });
                    divEl.addEventListener('mouseout', function () {
                        timeoutId = setTimeout(function () {
                            divEl.classList.remove('hover');
                            if (divEl.childNodes[1])
                                divEl.childNodes[1].classList.remove("d-lg-block")
                        }, 1000);

                    });
                })

            }
            var headerLogoClass = document.getElementById("HeaderLogoBlock");
            if (isMobile.any() && headerLogoClass !== null) {
                if (initials === null || initials === undefined || initials === "") {
                    headerLogoClass.classList.add("mobile-loggedout");
                }
                else {
                    headerLogoClass.classList.add("mobile-loggedin");
                }
            }
            setVisitorInitials(initials);
        }

    }, []);
    function bannerToggle() {
        var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
        if (!isMobileView) {
            if (document.getElementsByTagName("body")[0].classList.contains("on-search")) {
                document.getElementById("mainSearchIcon").click();
            }
        }
        if (document.getElementById("NoticeBlock")) {
            if (!document.getElementById("NoticeBlock").classList.contains("d-none")) {
                document.getElementById("NoticeBlock").classList.add("d-none");
                document.body.classList.remove("emergency-notice-show");
            }
            else {
                if (!document.getElementById("NoticeBlock").getAttribute("data-bannerCookie")) {
                    document.getElementById("NoticeBlock").classList.remove("d-none");
                    document.body.classList.add("emergency-notice-show");
                }
            }
        }

        //Hide Smart App Banner
        // if (document.getElementsByClassName("smartbanner")[0])
        //   document.getElementsByClassName("smartbanner")[0].classList.add('d-none');
        //Hide Smart App Banner
        if (document.getElementsByClassName("smartbanner")[0]) {
            if (document.getElementsByClassName("smartbanner")[0].classList.contains("d-none"))
                document.getElementsByClassName("smartbanner")[0].classList.remove('d-none');
            else
                document.getElementsByClassName("smartbanner")[0].classList.add('d-none');
        }
        if (document.getElementById("headerBlock")) {
            if (document.getElementById("headerBlock").classList.contains("preheader-frontindex")) {
                document.getElementById("headerBlock").classList.remove("preheader-frontindex");
            }
            else {
                document.getElementById("headerBlock").classList.add("preheader-frontindex");
            }
        }
    }
    if (fields.mainHeader !== undefined && fields.mainHeader !== null && fields.mainHeader !== "" && !hasWebviewEqualsOne) {
        var hasUpcomingLiveEvent = false;
        var localeDateTime = new Date();
        let counter = 0;
        let mobileLinksValue = fields.mainHeader.isLoyaltyprogramExist ? 4 : 5;
        if (fields.mainHeader.videoList !== null && fields.mainHeader.videoList !== undefined && fields.mainHeader.videoList.length > 0) {
            for (var li = 0; i < fields.mainHeader.videoList.length; i++) {
                if (Enumerable.from(fields.mainHeader.videoList[li].centres).any() && Enumerable.from(fields.mainHeader.videoList[li].centres).where(centre => centre.centreId === fields.mainHeader.centreID)
                    && new Date(fields.mainHeader.videoList[i].startsAt).getTime() <= localeDateTime.getTime() && new Date(fields.mainHeader.videoList[i].finishesAt).getTime() >= localeDateTime.getTime()) {
                    hasUpcomingLiveEvent = true;
                }
            }
        }
        return (
            <>
                <div
                    id="headerBlock"
                    className="preheader">
                    <div>
                        <nav className={"navbar navbar-expand-lg p-0 justify-content-start justify-content-sm-between nav-height collapsed  " + (fields.mainHeader.isNewBrandedSite ? "bg-white" : "")} id="navbarHeader">
                            <a className={"picto icon-essentional-rw navbar-toggler d-block d-sm-block fs-4 collapsed "
                                + (fields.mainHeader.isNewBrandedSite ? " bg-white" : " ")}
                                data-bs-toggle="collapse" data-bs-target="#navbar" data-bs-auto-close="outside" aria-expanded="true" aria-controls="navbar" href="#navbarHeader"
                                aria-label="Toggle navigation" data-testid="testclick1" onClick={() => { bannerToggle() }}>
                            </a>
                            <ul className="navbar-nav nav-picto flex-row d-lg-none">
                                <li className="search">
                                    <a href="#" aria-label={fields?.mainHeader} className={"picto " + (fields.mainHeader.isNewBrandedSite ? " fs-3" : " fs-3") + " fs-4 icon-search-rw text-center gtmSearchSite"}
                                        data-bs-toggle="modal" data-bs-target="#searchPopin" id="stickySearchIcon"></a>
                                </li>
                            </ul>
                            <div id="HeaderLogoBlock" className="logo d-block d-lg-none ml-auto mr-auto justify-content-center mobile-loggedin">
                                <a aria-label={fields?.mainHeader} href={(fields.mainHeader.urlPrefix != null && fields.mainHeader.urlPrefix != ""
                                    ? fields.mainHeader.urlPrefix : "/")} className="navbar-brand navbar-mobile-logo p-0 m-0">
                                    {(fields.mainHeader.logo != null) &&
                                        <img className={"" + (fields.mainHeader.isMobileView ? "d-flex align-items-center" : "")} src={fields.mainHeader.logo} alt={fields.mainHeader.centerName}
                                            title={fields.mainHeader?.headerTitle?.replace("{CENTER}", fields.mainHeader.centerName)} />
                                    }
                                </a>
                            </div>
                            <ul className="navbar-nav nav-picto flex-row align-items-center d-lg-none pe-3">
                                {((visitorInitials !== null && visitorInitials !== undefined && visitorInitials !== "")) ?
                                    (
                                        <li className="collapsed user-mobile-header " data-bs-toggle="collapse" data-bs-target="#accountmenu"
                                            aria-controls="accountmenu" aria-expanded="false" aria-label="Toggle navigation" data-testid="testclick2" onClick={() => { gtmFuncClickAccountHeader(); fnRedirectToMyAccount(fields.mainHeader.myAccountLink?.link?.value?.url) }}>
                                            <a href={fields.mainHeader.myAccountLink?.link?.value?.url} aria-label={fields?.mainHeader?.myAccountLink?.link} className="text-center fs-5  picto icon-users-rw d-inline-block" data-toggle="modal" data-target="#registerPopin"></a>
                                            <a href={fields.mainHeader.myAccountLink?.link?.value?.url} aria-label={fields?.mainHeader?.myAccountLink?.link} className="text-center user-mobile-header-branded">{visitorInitials}</a>
                                        </li>

                                    ) :
                                    (
                                        <li className="d-flex">
                                            <a href="#" aria-label={fields?.mainHeader?.myAccountLink?.link} data-testid="testclick3" onClick={(e) => { e.preventDefault(); gtmFuncClickAccountHeader(); fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl); }}
                                                className={"text-center " + (fields.mainHeader.isNewBrandedSite ? "" : " ") + " fs-5 picto icon-users-rw d-inline-block"}></a>
                                        </li>
                                    )
                                }
                            </ul>

                            <div className={"container-fluid p-0 d-none d-sm-none d-lg-flex justify-content-between  bg-white" + (fields.mainHeader.isNewBrandedSite ? " " : " ")}>
                                <div className="col-md-5 ps-0 d-block">
                                    <ul className={"navbar-nav nav-picto align-items-center flex-row ms-auto"}>
                                        <li className={"search ps-2 pe-3 d-none d-sm-block gtmSearchSite"}>
                                            {(isSearchPage) ?
                                                (
                                                    <a href={baseModel.siteUrlPrefix} className={"picto icon-search-rw d-inline-block text-center fs-4" + (fields.mainHeader.isNewBrandedSite ? "" : " ")}
                                                        aria-label="header-search" id="mainSearchIcon"></a>
                                                ) : (
                                                    <a href="#" className={"picto icon-search-rw d-inline-block text-center fs-4 mt-1" + (fields.mainHeader.isNewBrandedSite ? "" : " ")}
                                                        role="button" aria-label="header-search" data-bs-toggle="modal" data-bs-target="#searchPopin" id="mainSearchIcon"></a>
                                                )
                                            }
                                        </li>
                                        {fields.mainHeader.rightLinks != null && fields.mainHeader.rightLinks.length > 0 &&
                                            fields.mainHeader.rightLinks.map((link, index) => {
                                                let strCount = "";
                                                let pageLink = "";
                                                let strPicto = "";
                                                let splitTxt = '';
                                                let isLiveShopping = "";
                                                let left = "";
                                                let right = "";
                                                let strLength = "";
                                                let leftString = "";
                                                let rightString = "";
                                                let middleString = "";
                                                let strMiddle = "";
                                                let strMid = "";
                                                let showotherLink = false;

                                                let viewClass = link.display_In_Desktop ? "" : " d-lg-none";

                                                if (link.itemId === fields.mainHeader.storesMainHeader) {
                                                    strCount = "(" + fields.mainHeader.storesCount + ")";
                                                    pageLink = fields.mainHeader.shopLanding_Page;
                                                }
                                                else if (link.itemId === fields.mainHeader.restaurantsMainHeader) {
                                                    strCount = "(" + fields.mainHeader.restaurantsCount + ")";
                                                    pageLink = fields.mainHeader.restaurant_Landing_Page;
                                                }
                                                else if (link.itemId === fields.mainHeader.offersMainHeader) {
                                                    strCount = "(" + fields.mainHeader.offersCount + ")";
                                                    pageLink = fields.mainHeader.loyaltyOffers_Page;
                                                }
                                                else if (link.itemId === fields.mainHeader.newsMenu) {
                                                    strCount = "(" + fields.mainHeader.newsCount + ")";
                                                    pageLink = fields.mainHeader.news_Page;
                                                }
                                                else if (link.itemId === fields.mainHeader.cinemaMainHeader) {
                                                    strCount = "(" + fields.mainHeader.moviesCount + ")";
                                                    pageLink = fields.mainHeader.cinema_Page;
                                                }
                                                else if (link.itemId === fields.mainHeader.liveShoppingMainHeader) {
                                                    splitTxt = (link?.link?.value?.text === null || link?.link?.value?.text === "") ? link?.itemName : link?.link?.value?.text;
                                                    // if (splitTxt !== null && splitTxt !== "" && splitTxt !== undefined) {
                                                    //     left = splitTxt.indexOf("[");
                                                    //     right = splitTxt.indexOf("]") + 1;
                                                    //     strLength = splitTxt.length
                                                    //     leftString = splitTxt.substring(right, strLength);
                                                    //     rightString = splitTxt.substring(0, left);
                                                    //     middleString = splitTxt.substring(left, right);
                                                    //     strMiddle = middleString.replace("[", "");
                                                    //     strMid = strMiddle.replace("]", "");
                                                    // }
                                                    strPicto = "icon-live";
                                                    strCount = "(2)";

                                                    isLiveShopping = fields.mainHeader.isLiveEventEnabled ? true : false;
                                                    if (isLiveShopping !== true) {
                                                        return <></>;
                                                    }
                                                    pageLink = fields.mainHeader.liveShopping_Page;
                                                }
                                                else {
                                                    showotherLink = true
                                                }
                                                if ((strCount !== "" && strCount !== "(0)") || showotherLink) {
                                                    return (
                                                        (!isLiveShopping) ?
                                                            (<li key={index} className={" d-flex align-items-center " + (fields.mainHeader.isNewBrandedSite ? " navbar-stores " : " navbar-stores-unbranded ") + viewClass}>
                                                                <a aria-label={link?.link?.value?.text} className={"title-limiting   text-uppercase gtmHeaderCommon" + (fields.mainHeader.isNewBrandedSite ? " " : " text-black")} href={fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + pageLink}>
                                                                    {(link.link.value.text === null || link.link.value.text === "") ? link.itemName : link.link.value.text}
                                                                </a>
                                                            </li>) :
                                                            (<li key={index} className={"d-flex align-items-center extralive text-uppercase navbar-shoppping " + (fields.mainHeader.isNewBrandedSite ? " navbar-stores " : " navbar-stores-unbranded ") + viewClass}>
                                                                <a className={"title-limiting gtmHeaderCommon d-flex align-items-center text-black  extra-live-weight" + (fields.mainHeader.isNewBrandedSite ? " " : " ")}
                                                                    href={fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + pageLink}
                                                                    data-testid="testclick4"
                                                                    onClick={() => fngtmExtraLiveClick("click_extra_live_cta")}>
                                                                    {splitTxt !== null && splitTxt !== "" && (<span className="mt-1 m-lg-0 d-inline-block pe-2">{splitTxt}</span>)}
                                                                    {/* {strMid !== null && strMid !== "" && (<span className={"mt-1 m-lg-0 d-inline-block navbar-shoppping-live ms-lg-1"}>{strMid}</span>)}
                                                                    {leftString !== null && leftString !== "" && (<span className="mt-1 m-lg-0 d-inline-block ms-lg-1">{leftString}</span>)} */}
                                                                    {hasUpcomingLiveEvent &&
                                                                        <span className={"picto icon-record-rw  d-inline-flex align-middle " + (fields.mainHeader.isNewBrandedSite ? " fs-5 " : " fs-6 ") + " rounded-circle"}></span>}                                                                </a>
                                                            </li>)
                                                    )
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="col">
                                    <div className={"d-flex justify-content-center p-0 align-items-center logo-centering bg-white" + (fields.mainHeader.isNewBrandedSite ? " " : " ")}>
                                        <div className="navbar-header w-100 w-lg-auto">
                                            {(isHomePage && !fields.mainHeader.isMobileView) ? (
                                                <h1 className={"logo d-none text-center d-lg-block m-0"}>
                                                    <a aria-label={fields?.mainHeader?.centerName} href={(fields.mainHeader.urlPrefix != null && fields.mainHeader.urlPrefix != "")
                                                        ? fields.mainHeader.urlPrefix : "/"} className="d-flex">
                                                        {(fields.mainHeader.logo != null) &&
                                                            <img src={fields.mainHeader.logo} alt={fields.mainHeader.centerName}
                                                                title={fields.mainHeader?.headerTitle?.replace("{CENTER}", fields.mainHeader.centerName)} />
                                                        }
                                                    </a>
                                                </h1>) : (
                                                <div className={"logo d-none text-center d-lg-block m-0"}>
                                                    <a aria-label={fields?.mainHeader?.centerName} href={(fields.mainHeader.urlPrefix != null && fields.mainHeader.urlPrefix != "")
                                                        ? fields.mainHeader.urlPrefix : "/"} className="d-flex">
                                                        {(fields.mainHeader.logo != null) &&
                                                            <img src={fields.mainHeader.logo} alt={fields.mainHeader.centerName}
                                                                title={fields.mainHeader?.headerTitle?.replace("{CENTER}", fields.mainHeader.centerName)} />
                                                        }
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-5 py-3 d-none d-sm-flex d-flex justify-content-end align-items-center header-right-padding bg-white" + (fields.mainHeader.isNewBrandedSite ? " " : " ")}>
                                    <div className={"justify-content-end opening-hours gtmHeaderCommon"}>
                                        <div>
                                            <a className="area-map d-flex align-items-center justify-content-center text-end pe-1 p-lg-2"
                                                aria-label={fields?.mainHeader?.goThere_Url}
                                                href={fields.mainHeader.goThere_Url}>
                                                {/* <span className={"picto icon-right-arrow-square d-inline-block align-middle p-lg-1 fs-5"}></span> */}
                                                <span className={"go-in pe-3 p-lg-1"}>{fields.mainHeader.location}</span></a>
                                        </div>
                                    </div>
                                    {
                                        fields.mainHeader.isLoyaltyprogramExist &&
                                        <a href={fields.mainHeader.loyalty?.value?.url} aria-label={fields?.mainHeader?.loyalty?.value?.text} target={fields.mainHeader.loyalty?.value?.target}
                                            className={"p-3 d-inline-flex one-westfield-club-logo align-items-center gtmHeader"} data-testid="testclick5"
                                            onClick={(e) => { loyaltyProgramDataLayer() }}>
                                            {/* //{fields.mainHeader.loyalty.value.text} */}
                                            <img className="westfield-club-logo" src={fields.mainHeader.westfieldClubLogo?.value?.src} alt={fields.mainHeader.westfieldClubLogo?.value?.alt}></img>
                                        </a>
                                    }
                                    <div className={"navbar-nav nav-pictoheader flex-row float-end px-lg-2 fs-4 d-inline-flex dropdown-login"}>
                                        {((visitorInitials !== null && visitorInitials !== undefined && visitorInitials !== "")) ?
                                            (
                                                <>
                                                    <a data-cs-mask="true" href="#" aria-label={visitorInitials}
                                                        className={"text-center profile-account-item d-flex align-items-end justify-content-center position-relative dropdown-toggle "}
                                                        data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true" data-testid="testclick6" onClick={(e) => { gtmFuncClickAccountHeader() }}>
                                                        <span className={"fs-4 picto icon-users-rw pe-1" + (fields.mainHeader.isNewBrandedSite ? " text-black" : " text-black")}></span>
                                                        <span className={"circle menu-user text-uppercase" + (fields.mainHeader.isNewBrandedSite ? " text-black" : " text-black")}>{visitorInitials}</span>
                                                    </a>
                                                    <div className="dropdown-menu icon-profile-dropdown mt-0 py-0" >
                                                        <a href={fields.mainHeader.myAccountLink?.link?.value?.url} aria-label={fields?.mainHeader?.myAccountLink?.link?.value} target=""
                                                            className=" text-capitalize dropdown-item"
                                                        >{fields.mainHeader.myAccountLink?.link?.value?.text}</a>
                                                        <a href="#" aria-label={fields?.mainHeader?.link?.value} onClick={(e) => { e.preventDefault(); return logoutFromGS(false) }}
                                                            className=" text-capitalize dropdown-item">{fields.mainHeader.logoutLink?.link?.value?.text}</a>
                                                    </div>
                                                </>
                                            ) :
                                            (
                                                // <ul className="m-0 p-0" >
                                                <li className="px-0 d-flex">
                                                    <a href="#" aria-label={fields?.mainHeader?.centerName} data-testid="testclick8" onClick={(e) => { e.preventDefault(); gtmFuncClickAccountHeader(); fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl); }}
                                                        className={"text-center fs-4 picto d-inline-block icon-users-rw  text-black" + (fields.mainHeader.isNewBrandedSite ? " " : " ")}></a>
                                                </li>
                                                //</ul>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={"navbar-container-desktop m-0" + (fields.mainHeader.isNewBrandedSite ? " " : "")}>
                                <div id="navbar" className={"navbar-collapse slidemenu slidemenu-new collapse bg-gray navbar-toggler-container-fluid"
                                    + (fields.mainHeader.isNewBrandedSite ? " " : " ")} role="navigation" aria-expanded="false">
                                    <div className={"desktop-overlay desktop-overlay-new col-md-4 px-0 bg-gray position-relative" + (fields.mainHeader.isNewBrandedSite ? " " : " ")}>
                                        {/* <a className={"picto icon-close-circle-rw navbar-toggler-navbar d-none d-lg-block ps-3 fs-3 collapsed"
                                            + (fields.mainHeader.isNewBrandedSite ? " text-white" : "")}
                                            data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar"
                                            aria-label="Toggle navigation" id="burgerIcon" >
                                        </a> */}

                                        {/* <div className="navbar-header mb-1 w-100 w-lg-auto">
                                            <div className="logo d-none text-center d-lg-block m-0">
                                                <a aria-label={fields?.mainHeader?.headerTitle} href={(fields.mainHeader.urlPrefix != null && fields.mainHeader.urlPrefix != ""
                                                    ? fields.mainHeader.urlPrefix : "/")}>
                                                    {(fields.mainHeader.logo != null) &&
                                                        <img src={fields.mainHeader.logo} alt={fields.mainHeader.centerName}
                                                            title={fields.mainHeader?.headerTitle?.replace("{CENTER}", fields.mainHeader.centerName)} className="top-logo" />
                                                    }
                                                </a>
                                            </div>
                                        </div> */}

                                        <ul className="navbar-nav submenu submenu-new position-relative mt-lg-4">
                                            {
                                                (fields.mainHeader.isLoyaltyprogramExist && fields.mainHeader.loyalty != null &&
                                                    fields.mainHeader.loyalty?.value?.url != null && fields.mainHeader.loyalty.value.url != "") &&
                                                <li className=" multi-level-new MenuMegaDrop" >
                                                    <a href={fields.mainHeader.loyalty.value.url} aria-label={fields?.mainHeader?.loyalty?.value?.url}
                                                        className={"d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list multi-level-list-new premium-text"
                                                            + (fields.mainHeader.isNewBrandedSite ? " " : " ")} data-testid="testclick9" onClick={(e) => { menuLoyaltyProgramDataLayer() }}>{fields.mainHeader.loyalty.value.text}
                                                    </a>
                                                </li>
                                            }

                                            {fields.mainHeader.rightLinks !== null && fields.mainHeader.rightLinks.length > 0 &&
                                                fields.mainHeader.rightLinks.map((link, index) => {

                                                    var itemCount = "(0)";
                                                    var itemName = "";
                                                    var pageLink = ""
                                                    var allItems = "";
                                                    var uriCategoryPart = "";
                                                    var categoryLinks;
                                                    var submenu = true;
                                                    var eligibilityFilter = false;
                                                    let splitTxt = '';
                                                    let isLive = "";
                                                    let left = "";
                                                    let right = "";
                                                    let strLength = "";
                                                    let leftString = "";
                                                    let rightString = "";
                                                    let middleString = "";
                                                    let strMiddle = "";
                                                    let strMid = "";
                                                    let isExtraLive = false;
                                                    let showotherLink = false;

                                                    if (link.itemId === fields.mainHeader.storesMainHeader) {
                                                        itemCount = "(" + fields.mainHeader.storesCount + ")";
                                                        itemName = (link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text;
                                                        pageLink = fields.mainHeader.urlPrefix + "/" + fields.mainHeader.shopLanding_Page;
                                                        allItems = fields.mainHeader.allStores;
                                                        categoryLinks = fields.mainHeader.shopNavigationCategoryLinks;
                                                    }
                                                    else if (link.itemId === fields.mainHeader.restaurantsMainHeader) {
                                                        itemCount = "(" + fields.mainHeader.restaurantsCount + ")";
                                                        itemName = (link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text;
                                                        pageLink = fields.mainHeader.urlPrefix + "/" + fields.mainHeader.restaurant_Landing_Page;
                                                        allItems = fields.mainHeader.allRestaurants;
                                                        categoryLinks = fields.mainHeader.restaurantsCategoryLinks;
                                                    }
                                                    else if (link.itemId === fields.mainHeader.offersMainHeader) {
                                                        itemCount = "(" + fields.mainHeader.offersCount + ")";
                                                        itemName = (link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text;
                                                        pageLink = fields.mainHeader.urlPrefix + "/" + fields.mainHeader.loyaltyOffers_Page;
                                                        allItems = fields.mainHeader.allOffers;
                                                        categoryLinks = fields.mainHeader.offersCategoryLinks;
                                                        eligibilityFilter = (fields.mainHeader.loyaltyCardOffersCount > 0 || fields.mainHeader.workerCardOffersCount > 0);
                                                        uriCategoryPart = fields.mainHeader.uriCategorySegment;
                                                    }
                                                    else if (link.itemId === fields.mainHeader.newsMenu) {
                                                        itemCount = "(" + fields.mainHeader.newsCount + ")";
                                                        itemName = (link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text;
                                                        pageLink = fields.mainHeader.urlPrefix + "/" + fields.mainHeader.news_Page;
                                                        allItems = fields.mainHeader.allNews;
                                                        categoryLinks = fields.mainHeader.newsCategoryLinks;
                                                        uriCategoryPart = fields.mainHeader.uriCategorySegment;
                                                    }
                                                    else if (link.itemId === fields.mainHeader.cinemaMainHeader) {
                                                        itemCount = "(" + fields.mainHeader.moviesCount + ")";
                                                        itemName = (link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text;
                                                        pageLink = fields.mainHeader.urlPrefix + "/" + fields.mainHeader.cinema_Page;
                                                        submenu = false;
                                                    }
                                                    else if (link.itemId === fields.mainHeader.liveShoppingMainHeader) {
                                                        splitTxt = (link?.link?.value?.text == null || link?.link?.value?.text == "") ? link.itemName : link?.link?.value?.text;
                                                        if (splitTxt !== null && splitTxt !== undefined && splitTxt !== "") {
                                                            // left = splitTxt.indexOf("[");
                                                            // right = splitTxt.indexOf("]") + 1;
                                                            // strLength = splitTxt.length
                                                            // leftString = splitTxt.substring(right, strLength);
                                                            // rightString = splitTxt.substring(0, left);
                                                            // middleString = splitTxt.substring(left, right);
                                                            // strMiddle = middleString.replace("[", "");
                                                            // strMid = strMiddle.replace("]", "");
                                                            isExtraLive = true;
                                                        }
                                                        itemCount = "(2)";
                                                        submenu = false;

                                                        isLive = fields.mainHeader.isLiveEventEnabled ? true : false;
                                                        if (isLive !== true) {
                                                            return <></>;
                                                        }
                                                        pageLink = fields.mainHeader.urlPrefix + "/" + fields.mainHeader.liveShopping_Page;
                                                    }
                                                    else {
                                                        showotherLink = true;
                                                        itemName = (link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text;
                                                        pageLink = link.link?.value?.url;
                                                        submenu = false;
                                                    }

                                                    if (itemCount !== "(0)" || showotherLink) {
                                                        return (

                                                            <li key={index} className={"multi-level  multi-level-new MenuMegaDrop" + (submenu ? " dropdown" : "")}>
                                                                {submenu ?
                                                                    (<>
                                                                        <a href={submenu ? "#" : pageLink} aria-label={itemCount}
                                                                            className="d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list multi-level-list-new"
                                                                            data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-testid="testclick10"
                                                                            onClick={(e) => submenu ? null : menuElementDataLayer(pageLink)}>{itemName}
                                                                            <span className="ms-1 count">{itemCount}</span>
                                                                        </a>
                                                                        <div className="megadrop megadrop-nonbrand d-lg-block dropdown-menu dropdown-menu-nonbrand desktop-mega">
                                                                            <a className="picto icon-close-circle-rw navbar-toggler-navbar navbar-toggler-navbar-new d-none d-lg-block ps-3 fs-1 "
                                                                                data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar"
                                                                                aria-label="Toggle navigation" onClick={() => { bannerToggle() }}></a>
                                                                            <div className="container megadrop-header p-0 ms-lg-0">
                                                                                <div className="row">
                                                                                    <div className="col-sm-12 d-flex align-items-center">
                                                                                        <h3 className="category-stores-new">{itemName}<span className="ms-1 category-stores-new">{itemCount}</span>
                                                                                        </h3>
                                                                                        {allItems != "" &&
                                                                                            <a href={pageLink} aria-label={itemCount}
                                                                                                className="ps-2 category-info text-decoration-underline gtmHeader" data-testid="testclick11"
                                                                                                onClick={(e) => { menuElementDataLayer(pageLink) }}>
                                                                                                {"(" + allItems + ")"}</a>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="container megadrop-list-new  megadrop-list p-1 m-0">
                                                                                <ul className="list-group megadrop-list-items">
                                                                                    {/* {allItems != "" &&
                                                                                        <li className="list-unstyled">
                                                                                            <a href={pageLink} aria-label={itemCount}
                                                                                                className="list-group-item list-group-item-action dropdown-item gtmHeader" data-testid="testclick11"
                                                                                                onClick={(e) => { menuElementDataLayer(pageLink) }}>
                                                                                                {allItems}</a>
                                                                                        </li>
                                                                                    } */}
                                                                                    {eligibilityFilter &&
                                                                                        <>
                                                                                            {
                                                                                                fields.mainHeader.loyaltyCardOffersCount > 0 &&
                                                                                                <li key={index} className="list-unstyled">
                                                                                                    <a href={pageLink + fields.mainHeader.loyaltyURL}
                                                                                                        aria-label={fields?.mainHeader?.loyaltyURL}
                                                                                                        className="list-group-item list-group-item-action dropdown-item gtmHeader" data-testid="testclick12"
                                                                                                        onClick={(e) => { menuElementDataLayer(pageLink + fields.mainHeader.loyaltyURL) }}>
                                                                                                        {fields.mainHeader.eligibilityLoyalty}</a>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                (fields.mainHeader.isWorker && fields.mainHeader.workerCardOffersCount > 0) &&
                                                                                                // <div className="">
                                                                                                <li key={index} className="list-unstyled">
                                                                                                    <a href={pageLink + fields.mainHeader.workerURL}
                                                                                                        aria-label={fields?.mainHeader?.loyaltyURL}
                                                                                                        className="list-group-item list-group-item-action dropdown-item gtmHeader" data-testid="testclick13"
                                                                                                        onClick={(e) => { menuElementDataLayer(pageLink + fields.mainHeader.workerURL) }}>
                                                                                                        {fields.mainHeader.eligibilityWorker}</a>
                                                                                                </li>
                                                                                                //</div>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    {categoryLinks != null && categoryLinks.item != null && categoryLinks.item.length > 0 &&
                                                                                        categoryLinks.item.map((category, key) => {
                                                                                            return (
                                                                                                // <div key={key} className="">
                                                                                                <li key={key} className="list-unstyled">
                                                                                                    <a aria-label={category?.name} href={pageLink + uriCategoryPart?.toLowerCase() + "/" + category.urlName?.toLowerCase()}
                                                                                                        className="list-group-item list-group-item-action dropdown-item gtmHeader" data-testid="testclick14"
                                                                                                        data-categoryname={category.name} onClick={(e) => { menuElementDataLayer(pageLink + uriCategoryPart + "/" + category.urlName) }}>
                                                                                                        {category.name}</a>
                                                                                                </li>
                                                                                                // </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    )
                                                                    :
                                                                    (
                                                                        isExtraLive ?
                                                                            <a href={submenu ? "#" : pageLink}
                                                                                className="d-block multi-level-list multi-level-list-new gtmHeader premium-text" data-testid="testclick15"
                                                                                onClick={submenu ? "" : (e) => { fngtmExtraLiveClick("click_extra_live_cta"); menuElementDataLayer(pageLink) }}>
                                                                                {splitTxt}
                                                                                {/* {isLive &&
                                                                                    // <>
                                                                                    //     {rightString !== null && rightString !== "" && (<span className={"" + (hasUpcomingLiveEvent ? "extra-live ms-3" : "d-inline-block")}>{rightString}</span>)}
                                                                                    //     {strMid !== null && strMid !== "" &&
                                                                                    //         (<span className="mx-1 bg-liveshopping px-1">{strMid}</span>)
                                                                                    //     }
                                                                                    //     {leftString !== null && leftString !== "" && (<span className="d-inline-block">{leftString}</span>)}
                                                                                    // </>
                                                                                } */}
                                                                                {/* {hasUpcomingLiveEvent ? <span className="picto icon-record-rw d-inline-flex align-middle fs-5 pb-2 ps-1 rounded-circle"></span> : <span className="ms-1">{itemCount}</span>} */}
                                                                            </a>
                                                                            :
                                                                            <a href={submenu ? "#" : pageLink}
                                                                                className=" d-block multi-level-list multi-level-list-new gtmHeader" data-testid="testclick16"
                                                                                onClick={submenu ? "" : (e) => { menuElementDataLayer(pageLink) }}>
                                                                                {itemName}
                                                                                {itemCount !== "(0)" &&
                                                                                    <span className="ms-1 count">{itemCount}</span>
                                                                                }
                                                                            </a>
                                                                    )
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }

                                            {fields.mainHeader.otherLinks != null && fields.mainHeader.otherLinks.length > 0 &&
                                                fields.mainHeader.otherLinks.map((otherLink, key) => {

                                                    var itemName = (otherLink.headerLink.link.value.text == null || otherLink.headerLink.link.value.text == "")
                                                        ? otherLink.headerLink.link.itemName : otherLink.headerLink.link.value.text

                                                    switch (otherLink.headerLink.itemId) {
                                                        case fields.mainHeader.global_Menu_Center:
                                                            {
                                                                var subMenuExists = otherLink.headerNavigationModel.centerAnchorsList != null
                                                                    && otherLink.headerNavigationModel.centerAnchorsList.menuAnchors != null
                                                                    && otherLink.headerNavigationModel.centerAnchorsList.menuAnchors.length > 0;

                                                                return (
                                                                    <li key={key} className={"multi-level multi-level-new MenuMegaDrop" + (subMenuExists ? " dropdown" : "")}>
                                                                        {subMenuExists ?
                                                                            (<>
                                                                                <a href="#"
                                                                                    aria-label={otherLink?.headerNavigationModel?.centerAnchorsList}
                                                                                    className=" d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list multi-level-list-new "
                                                                                    data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{itemName}
                                                                                </a>
                                                                                <div className="megadrop megadrop-nonbrand d-lg-block dropdown-menu dropdown-menu-nonbrand desktop-mega">
                                                                                    <a className="picto icon-close-circle-rw navbar-toggler-navbar navbar-toggler-navbar-new d-none d-lg-block ps-3 fs-1 collapsed"
                                                                                        data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar" onClick={() => { bannerToggle() }}
                                                                                        aria-label="Toggle navigation" ></a>
                                                                                    <div className="container megadrop-header p-0 ms-lg-0">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12 d-flex align-items-center">
                                                                                                <h3 className="category-stores-new">{itemName}</h3>
                                                                                                {/* <span className="ps-2 category-info">(3)</span> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="container megadrop-list-new  megadrop-list p-1 m-0">
                                                                                        <ul className="list-group megadrop-list-items">
                                                                                            {otherLink.headerNavigationModel?.centerAnchorsList?.menuAnchors?.length > 0 &&
                                                                                                otherLink.headerNavigationModel.centerAnchorsList.menuAnchors.map((centerLink, index) => {
                                                                                                    return (
                                                                                                        // <div  className="">
                                                                                                        <li key={index} className="list-unstyled">
                                                                                                            <a href={centerLink?.links?.value?.url?.toLowerCase()}
                                                                                                                aria-label={centerLink?.links?.value}
                                                                                                                className="list-group-item list-group-item-action dropdown-item  gtmHeader" data-testid="testclick17"
                                                                                                                onClick={(e) => { menuElementDataLayer(centerLink.links.value.url) }}>
                                                                                                                {centerLink.name?.value}</a>
                                                                                                        </li>
                                                                                                        // </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            ) : (
                                                                                <a href={otherLink.headerLink.link.value.url}
                                                                                    aria-label={otherLink?.headerLink?.link?.value}
                                                                                    className=" d-block gtmHeader multi-level-list multi-level-list-new" data-testid="testclick18"
                                                                                    onClick={(e) => { menuElementDataLayer(otherLink.headerLink.link.value.url) }}>{itemName}
                                                                                </a>
                                                                            )
                                                                        }
                                                                    </li>
                                                                )
                                                            }
                                                        case fields.mainHeader.global_Menu_Access:
                                                            {
                                                                var subMenuExists = otherLink.headerNavigationModel.accessAnchorsList != null
                                                                    && otherLink.headerNavigationModel.accessAnchorsList.menuAnchors != null
                                                                    && otherLink.headerNavigationModel.accessAnchorsList.menuAnchors.length > 0;

                                                                return (
                                                                    <li key={key} className={"multi-level multi-level-new MenuMegaDrop" + (subMenuExists ? " dropdown" : "")}>
                                                                        {subMenuExists ?
                                                                            (<>
                                                                                <a href="#"
                                                                                    aria-label={otherLink?.headerNavigationModel?.accessAnchorsList?.menuAnchors}
                                                                                    className="text-uppercase  dropdown-toggle dropdown-toggle-new d-inline-block gtmHeader multi-level-list  multi-level-list-new"
                                                                                    data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{itemName}
                                                                                </a>
                                                                                <div className="megadrop megadrop-nonbrand d-lg-block dropdown-menu dropdown-menu-nonbrand desktop-mega">
                                                                                    <a className="picto icon-close-circle-rw navbar-toggler-navbar navbar-toggler-navbar-new d-none d-lg-block ps-3 fs-1 collapsed"
                                                                                        data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar"
                                                                                        aria-label="Toggle navigation" onClick={() => { bannerToggle() }}></a>
                                                                                    <div className="container megadrop-header p-0 ms-lg-0">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12 d-flex align-items-center">
                                                                                                <h3 className="category-stores-new">{itemName}</h3>
                                                                                                {/* <div className="ps-2 category-info">(voir toutes les boutiques)</div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="container megadrop-list megadrop-list-new p-1 m-0">
                                                                                        <ul className="list-group megadrop-list-items ">
                                                                                            {otherLink.headerNavigationModel?.accessAnchorsList?.menuAnchors?.length > 0 &&
                                                                                                otherLink.headerNavigationModel.accessAnchorsList.menuAnchors.map((accessLink, index) => {
                                                                                                    return (
                                                                                                        //<div key={index} className="">
                                                                                                        <li key={index} className="list-unstyled">
                                                                                                            <a aria-label={accessLink?.name?.value} href={accessLink?.links?.value?.url?.toLowerCase()}
                                                                                                                className="list-group-item list-group-item-action dropdown-item gtmHeader" data-testid="testclick19"
                                                                                                                onClick={(e) => { menuElementDataLayer(accessLink.links.value.url) }}>
                                                                                                                {accessLink.name?.value}</a>
                                                                                                        </li>
                                                                                                        // </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            ) : (
                                                                                <a href={otherLink.headerLink.link.value.url}
                                                                                    aria-label={otherLink?.headerLink?.link?.value}
                                                                                    className=" d-block gtmHeader multi-level-list multi-level-list-new" data-testid="testclick20"
                                                                                    onClick={(e) => { menuElementDataLayer(otherLink.headerLink.link.value.url) }}>{itemName}
                                                                                </a>
                                                                            )
                                                                        }
                                                                    </li>
                                                                )
                                                            }
                                                        case fields.mainHeader.global_Menu_Services:
                                                            {
                                                                var subMenuExists = otherLink.headerNavigationModel.servicesAnchorsList != null
                                                                    && otherLink.headerNavigationModel.servicesAnchorsList.menuAnchors != null
                                                                    && otherLink.headerNavigationModel.servicesAnchorsList.menuAnchors.length > 0;

                                                                return (
                                                                    <li key={key} className={"multi-level MenuMegaDrop multi-level-new" + (subMenuExists ? " dropdown" : "")}>
                                                                        {subMenuExists ?
                                                                            (<>
                                                                                <a href="#"
                                                                                    aria-label={otherLink?.headerNavigationModel?.servicesAnchorsList}
                                                                                    className="d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list multi-level-list-new"
                                                                                    data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{itemName}
                                                                                </a>
                                                                                <div className="megadrop megadrop-nonbrand d-lg-block dropdown-menu dropdown-menu-nonbrand desktop-mega">
                                                                                    <a className="picto icon-close-circle-rw navbar-toggler-navbar navbar-toggler-navbar-new d-none d-lg-block ps-3 fs-1 collapsed"
                                                                                        data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar"
                                                                                        aria-label="Toggle navigation" onClick={() => { bannerToggle() }}></a>
                                                                                    <div className="container megadrop-header p-0 ms-lg-0">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12 d-flex align-items-center">
                                                                                                <h3 className="category-stores-new">{itemName}</h3>
                                                                                                {/* <div className="ps-2 category-info">(voir toutes les boutiques)</div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="container megadrop-list megadrop-list-new p-1 m-0">
                                                                                        <ul className="list-group megadrop-list-items ">
                                                                                            {otherLink.headerNavigationModel?.servicesAnchorsList?.menuAnchors?.length > 0 &&
                                                                                                otherLink.headerNavigationModel.servicesAnchorsList.menuAnchors.map((serviceLink, index) => {
                                                                                                    return (
                                                                                                        // <div key={index} className="">
                                                                                                        <li key={index} className="list-unstyled">
                                                                                                            <a aria-label={serviceLink.name?.value} href={serviceLink?.links?.value?.url?.toLowerCase()}
                                                                                                                className="list-group-item list-group-item-action dropdown-item gtmHeader" data-testid="testclick21"
                                                                                                                onClick={(e) => { menuElementDataLayer(serviceLink.links.value.url) }}>
                                                                                                                {serviceLink.name?.value}</a>
                                                                                                        </li>
                                                                                                        //</div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            ) : (
                                                                                <a href={otherLink.headerLink.link.value.url}
                                                                                    aria-label={otherLink?.headerLink?.link?.value}
                                                                                    className="d-block gtmHeader multi-level-list multi-level-list-new" data-testid="testclick22"
                                                                                    onClick={(e) => { menuElementDataLayer(otherLink.headerLink.link.value.url) }}>{itemName}
                                                                                </a>
                                                                            )
                                                                        }
                                                                    </li>
                                                                )
                                                            }
                                                        default:
                                                            {
                                                                var subMenuExists = otherLink.headerNavigationModel.manageableLinksAnchorsList != null
                                                                    && otherLink.headerNavigationModel.manageableLinksAnchorsList.menuAnchors != null
                                                                    && otherLink.headerNavigationModel.manageableLinksAnchorsList.menuAnchors.length > 0;

                                                                return (
                                                                    <li key={key} className={"multi-level multi-level-new MenuMegaDrop" + (subMenuExists ? " dropdown" : "")}>
                                                                        {subMenuExists ?
                                                                            (<>
                                                                                <a href="#"
                                                                                    aria-label={otherLink?.headerNavigationModel?.manageableLinksAnchorsList}
                                                                                    className="d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list multi-level-list-new"
                                                                                    data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{itemName}
                                                                                </a>
                                                                                <div className="megadrop megadrop-nonbrand d-lg-block dropdown-menu dropdown-menu-nonbrand desktop-mega">
                                                                                    <a className="picto icon-close-circle-rw navbar-toggler-navbar navbar-toggler-navbar-new d-none d-lg-block ps-3 fs-1 collapsed"
                                                                                        data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar"
                                                                                        aria-label="Toggle navigation" onClick={() => { bannerToggle() }}></a>
                                                                                    <div className="container megadrop-header p-0 ms-lg-0">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12 d-flex align-items-center">
                                                                                                <h3 className="category-stores-new">{itemName}</h3>
                                                                                                {/* <div className="ps-2 category-info">(voir toutes les boutiques)</div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="container megadrop-list-new megadrop-list p-1 m-0">
                                                                                        <ul className="list-group megadrop-list-items ">
                                                                                            {otherLink.headerNavigationModel?.manageableLinksAnchorsList?.menuAnchors != null &&
                                                                                                otherLink.headerNavigationModel?.manageableLinksAnchorsList?.menuAnchors.length > 0 &&
                                                                                                otherLink.headerNavigationModel.manageableLinksAnchorsList.menuAnchors.map((dynamicLink, index) => {
                                                                                                    return (
                                                                                                        //<div key={index} className="">
                                                                                                        <li key={index} className="list-unstyled">
                                                                                                            <a href={dynamicLink?.links?.value?.url?.toLowerCase()}
                                                                                                                aria-label={dynamicLink?.links?.value}
                                                                                                                className="list-group-item list-group-item-action dropdown-item gtmHeader" data-testid="testclick23"
                                                                                                                onClick={(e) => { menuElementDataLayer(dynamicLink.links.value.url) }}>
                                                                                                                {dynamicLink.name?.value}</a>
                                                                                                        </li>
                                                                                                        //</div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            ) : (
                                                                                <a href={otherLink.headerLink.link.value.url} aria-label={otherLink?.otherLink?.link?.value}
                                                                                    className=" d-block gtmHeader multi-level-list multi-level-list-new" data-testid="testclick24"
                                                                                    onClick={(e) => { menuElementDataLayer(otherLink.headerLink.link.value.url) }}>{itemName}
                                                                                </a>
                                                                            )
                                                                        }
                                                                    </li>
                                                                )
                                                            }
                                                    }
                                                })
                                            }
                                            {(fields.mainHeader.discoverAnotherCenter?.link?.value?.url != null && fields.mainHeader.discoverAnotherCenter.link.value.url != "") &&
                                                <li className="multi-level multi-level-new dropdown MenuMegaDrop">
                                                    {fields.mainHeader.discoverAnotherCenter.link.value.url.includes("javascript") ?
                                                        (
                                                            <a href="#" className=" d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list discover-center-icon multi-level-list-new" aria-label={fields?.mainHeader?.discoverAnotherCenter?.link?.value}
                                                                data-testid="testclick25" onClick={(e) => { e.preventDefault(); menuDataLayer(); fnDiscoverAnotherCenterPopin("Center") }}>
                                                                {fields.mainHeader.discoverAnotherCenter.link.value.text}
                                                            </a>
                                                        ) :
                                                        (
                                                            <a href={fields.mainHeader.discoverAnotherCenter.link.value.url} aria-label={fields?.mainHeader?.discoverAnotherCenter?.link?.value} className=" d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list multi-level-list-new">
                                                                {fields.mainHeader.discoverAnotherCenter.link.value.text}
                                                            </a>
                                                        )
                                                    }
                                                </li>
                                            }


                                            {/* <div className="navbar-nav mt-3 navbar-nav-new"> */}
                                            {/* {fields.mainHeader.subscribeLink?.link !== null && fields.mainHeader.subscribeLink?.link !== "" && fields.mainHeader.subscribeLink?.link?.value?.text !== "" && fields.mainHeader.subscribeLink?.link?.value?.text !== null &&
                                                <li className=" multi-level multi-level-new mt-4">
                                                    <a href={fields.mainHeader.subscribeLink?.link?.value?.url} target=""
                                                        className=" d-block gtmHeader multi-level-list multi-level-list-new multi-level-list-section-2"
                                                        data-gtmclickposition="header" data-gtmclicklabel="header-sliding-menu">{fields.mainHeader.subscribeLink?.link?.value?.text}</a>
                                                </li>
                                            } */}
                                            {/* <li className=" multi-level multi-level-new">
                                                <a href={fields.mainHeader.serviceLink?.link?.value?.url} target=""
                                                    className=" d-block gtmHeader multi-level-list multi-level-list-new multi-level-list-section-2"
                                                    data-gtmclickposition="header" data-gtmclicklabel="header-sliding-menu">{fields.mainHeader.serviceLink?.link?.value?.text}</a>
                                            </li> */}
                                            {/* </div> */}
                                        </ul>
                                        {(fields.languageData !== null && fields.languageData.languages !== null) &&
                                            <footer className="footer my-2">
                                                <div className="text-center menu-language-switcher">
                                                    <ul className="lang-links lang-links-new d-flex text-uppercase">
                                                        {
                                                            fields.languageData.languages.map((item, index) => {

                                                                const url = ",-w-,";
                                                                const wlUrl = item.destinationUrl.replace(url, item.endUrl);

                                                                return (
                                                                    (item.destinationUrl.includes(url)) ?
                                                                        (
                                                                            <li key={index} className={item.cssState ? "active" : ""}>
                                                                                <a href={wlUrl} className='' aria-label={item?.language?.title}>
                                                                                    {item.language.title}
                                                                                </a>
                                                                            </li>
                                                                        ) :
                                                                        (
                                                                            <li key={index} className={item.cssState ? "active" : ""}>
                                                                                <a href={item.destinationUrl} className='' aria-label={item?.language?.title}>
                                                                                    {item.language.title}
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </footer>
                                        }

                                        {((visitorInitials !== null && visitorInitials !== undefined && visitorInitials !== "")) ?
                                            (
                                                <div className="w-100  my-3 m-auto  text-center loyalty menu-logout">
                                                    <a href={fields.mainHeader.myAccountLink?.link?.value?.url} aria-label={fields?.mainHeader?.myAccountLink?.link?.value} target={fields.mainHeader.myAccountLink?.link?.value?.target}
                                                        className={"nav-link px-2 text-center text-white w-100  gtmHeader" + (fields.mainHeader.isNewBrandedSite ? " btn-dark" : " bg-blue")}>
                                                        {fields.mainHeader.myAccountLink?.link?.value?.text}
                                                    </a>
                                                </div>
                                            ) :
                                            (
                                                <div className="w-100 px-5 my-3 px-sm-3 m-auto  text-center loyalty menu-logout">
                                                    <a href="#" aria-label={fields?.mainHeader?.loginLink?.link?.value} data-testid="testclick26" onClick={(e) => { e.preventDefault(); fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }} target={fields.mainHeader.loginLink?.link?.value?.target}
                                                        className={"nav-link px-2 text-center text-white w-100  gtmHeader" + (fields.mainHeader.isNewBrandedSite ? " btn-dark" : " bg-blue")}>
                                                        {fields.mainHeader.loginLink?.link?.value?.text}
                                                    </a>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </nav >

                        <div className="d-flex align-items-center flex-no-wrap justify-content-between nav-mobile-subbuttons bg-white d-lg-none" id="MainHeader">
                            {
                                fields.mainHeader.rightLinks != null && fields.mainHeader.rightLinks.length > 0 &&
                                fields.mainHeader.rightLinks.map((link, index) => {
                                    let strPicto = "";
                                    let strCount = "";
                                    let pageLink = "";
                                    let splitTxt = '';
                                    let isLiveShopping = "";
                                    let left = "";
                                    let right = "";
                                    let strLength = "";
                                    let leftString = "";
                                    let rightString = "";
                                    let middleString = "";
                                    let strMiddle = "";
                                    let strMid = "";
                                    let showotherLink = false;
                                    let viewClass = link.display_In_Mobile ? "" : " d-none";

                                    if (link.itemId === fields.mainHeader.storesMainHeader) {
                                        strPicto = "icon-ico-ph-shops";
                                        strCount = "(" + fields.mainHeader.storesCount + ")";
                                        pageLink = fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + fields.mainHeader.shopLanding_Page;
                                    }
                                    else if (link.itemId === fields.mainHeader.restaurantsMainHeader) {
                                        strPicto = "icon-srv-restaurant";
                                        strCount = "(" + fields.mainHeader.restaurantsCount + ")";
                                        pageLink = fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + fields.mainHeader.restaurant_Landing_Page;
                                    }
                                    else if (link.itemId === fields.mainHeader.offersMainHeader) {
                                        strPicto = "icon-servicesaspictosoffers";
                                        strCount = "(" + fields.mainHeader.offersCount + ")";
                                        pageLink = fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + fields.mainHeader.loyaltyOffers_Page;
                                    }
                                    else if (link.itemId === fields.mainHeader.newsMenu) {
                                        strPicto = "icon-servicesaspictosevent";
                                        strCount = "(" + fields.mainHeader.newsCount + ")";
                                        pageLink = fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + fields.mainHeader.news_Page;
                                    }
                                    else if (link.itemId === fields.mainHeader.cinemaMainHeader) {
                                        strPicto = "icon-srv-cinema";
                                        strCount = "(" + fields.mainHeader.moviesCount + ")";
                                        pageLink = fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + fields.mainHeader.cinema_Page;
                                    }
                                    else if (link.itemId === fields.mainHeader.liveShoppingMainHeader) {
                                        splitTxt = (link.link.value.text === null || link.link.value.text === "") ? link.itemName : link.link.value.text;
                                        left = splitTxt.indexOf("[");
                                        right = splitTxt.indexOf("]") + 1;
                                        strLength = splitTxt.length
                                        leftString = splitTxt.substring(right, strLength);
                                        rightString = splitTxt.substring(0, left);
                                        middleString = splitTxt.substring(left, right);
                                        strMiddle = middleString.replace("[", "");
                                        strMid = strMiddle.replace("]", "");
                                        strPicto = "icon-live";
                                        strCount = "(2)";

                                        isLiveShopping = fields.mainHeader.isLiveEventEnabled ? true : false;
                                        if (isLiveShopping !== true) {
                                            return <></>;
                                        }
                                        pageLink = fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + fields.mainHeader.liveShopping_Page;
                                    }
                                    else {
                                        showotherLink = true;
                                        pageLink = link.link.value.url;
                                    }

                                    if ((strCount !== "" && strCount !== "(0)") || showotherLink) {
                                        counter++;
                                        if (counter < mobileLinksValue) {
                                            return (
                                                (!isLiveShopping) ?
                                                    <a key={index} href={pageLink} className={"d-flex align-items-center flex-wrap justify-content-center p-2 " + viewClass}>{link.link.value.text}</a>
                                                    : (
                                                        <a key={index} href={pageLink} className={"d-flex align-items-center flex-wrap justify-content-center p-2 " + viewClass}>{link.link.value.text}</a>
                                                    )

                                                // <div key={index} className="d-flex align-items-center flex-wrap justify-content-center p-2">{link.link.value.text}</div>

                                                // (!isLiveShopping) ?
                                                //     (<li key={index} className={"px-lg-2 py-2 m-lg-0 w-100 w-lg-auto dropdown limit-li"
                                                //         + (fields.mainHeader.isNewBrandedSite ? " pe-1" : " px-1 py-lg-0 me-1 bg-white rounded-1") + viewClass}>
                                                //         <a href={fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + pageLink} aria-label={fields?.mainHeader?.urlPrefix}
                                                //             className={"nav-link p-0 py-lg-4 text-center text-uppercase dropdown-toggle gtmHeaderCommon"}>
                                                //             <span className={"mb-2 picto " + strPicto + " d-block d-lg-none" + (fields.mainHeader.isNewBrandedSite ? "" : " fs-1 text-black")}></span>
                                                //             {(fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage)
                                                //                 ? (<h2 className="mt-1 m-lg-0 d-inline-block mainheader-mobile-menu">{(link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text}</h2>)
                                                //                 : (fields.mainHeader.isNewBrandedSite ? (<h2 className="mt-1 m-lg-0 d-inline-block">{(link.link.value.text == null || link.link.value.text == "")
                                                //                     ? link.itemName : link.link.value.text}</h2>) : (<span>{(link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text}</span>))
                                                //             }
                                                //             {(strCount != null && strCount != "" && !fields.mainHeader.isNewBrandedSite) &&
                                                //                 <span className="count d-block d-lg-inline-block">{strCount}</span>
                                                //             }
                                                //         </a>
                                                //     </li>) :
                                                //     (<li key={index} className={"px-lg-2 py-2 m-lg-0 w-100 w-lg-auto dropdown limit-li"
                                                //         + (fields.mainHeader.isNewBrandedSite ? " pe-1" : " px-1 py-lg-0 me-1 bg-white rounded-1") + viewClass}>
                                                //         <a href={fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + pageLink} aria-label={fields?.mainHeader?.urlPrefix}
                                                //             className={"nav-link p-0 py-lg-4 text-center text-uppercase dropdown-toggle gtmHeaderCommon"}>
                                                //             <span className={"mb-2 picto " + strPicto + " d-block d-lg-none" + (fields.mainHeader.isNewBrandedSite ? "" : " fs-1 text-black")}></span>
                                                //             {rightString !== null && rightString !== "" && (<h2 className={"mt-1 m-lg-0 d-inline-block" + (fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage ? " mainheader-mobile-menu" : "")}>{rightString}</h2>)}
                                                //             {strMid !== null && strMid !== "" && (<h2 className={"mt-1 m-lg-0 d-inline-block" + (fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage ? " mainheader-mobile-menu" : "") + " bg-live-shopping px-1 ms-lg-1"}>{strMid}</h2>)}
                                                //             {leftString !== null && leftString !== "" && (<h2 className={"mt-1 m-lg-0 d-inline-block ms-lg-1" + (fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage ? " mainheader-mobile-menu" : "")}>{leftString}</h2>)}
                                                //             {(strCount !== null && strCount !== "" && !fields.mainHeader.isNewBrandedSite) &&
                                                //                 <span className="count d-block d-lg-inline-block">{strCount}</span>
                                                //             }
                                                //         </a>
                                                //     </li>)
                                            )
                                        }
                                    }
                                })
                            }
                            {fields.mainHeader.isLoyaltyprogramExist &&
                                <div className="d-flex align-items-center flex-wrap justify-content-center p-2 "><a
                                    href={fields.mainHeader.loyalty?.value?.url} target=""
                                    className="d-inline-flex one-westfield-club-logo text-uppercase align-items-center">
                                    <img className="westfield-club-logo" src={fields.mainHeader.westfieldClubLogo?.value?.src} alt={fields.mainHeader.westfieldClubLogo?.value?.alt} />
                                </a></div>
                            }
                        </div>
                        <div className='header-alternatecolor'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div >
                </div >

                {/* <div className={"mainheader d-lg-none pb-0" + (fields.mainHeader.isNewBrandedSite ? " " : " ")} id="MainHeader">
                    {(baseModel.currentPageItemName != null && baseModel.currentPageItemName != "" &&
                        baseModel.currentPageItemName.toLowerCase() == "homepage") &&
                        <div className="topheader-mobile-bg d-lg-none" id="topheader-mobile-bg">
                            <img src={fields.mainHeader.centerImageurl} alt={fields.mainHeader.metaPageTitle} />
                        </div>
                    }
                    <div className={"container bg-overlay" + (fields.mainHeader.isNewBrandedSite ? " no-padding" : " p-0")}>
                        <nav className="navbar navbar-expand-lg p-0 m-0">
                            <div className={"navbar-header w-100 w-lg-auto me-lg-5"}>
                                {(fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage) ?
                                    (
                                        <>
                                            {(isHomePage && fields.mainHeader.isMobileView) ? (
                                                <h1 className="logo d-none d-lg-block">
                                                    <a href={(fields.mainHeader.urlPrefix != null && fields.mainHeader.urlPrefix != "") ? fields.mainHeader.urlPrefix : "/"} aria-label={fields?.mainHeader?.centerName} className="d-flex">
                                                        {(fields.mainHeader.logo != null) &&
                                                            <img src={fields.mainHeader.logo} alt={fields.mainHeader.centerName} title={fields.mainHeader?.headerTitle?.replace("{CENTER}", fields.mainHeader.centerName)} />
                                                        }
                                                    </a>
                                                </h1>
                                            ) : (
                                                <div className="logo d-none d-lg-block">
                                                    <a href={(fields.mainHeader.urlPrefix != null && fields.mainHeader.urlPrefix != "") ? fields.mainHeader.urlPrefix : "/"} aria-label={fields?.mainHeader?.centerName} className="d-flex">
                                                        {(fields.mainHeader.logo != null) &&
                                                            <img src={fields.mainHeader.logo} alt={fields.mainHeader.centerName} title={fields.mainHeader?.headerTitle?.replace("{CENTER}", fields.mainHeader.centerName)} />
                                                        }
                                                    </a>
                                                </div>
                                            )}

                                            <div className={"center-name d-sm-block w-100 w-lg-auto text-center text-uppercase text-white"
                                                + (fields.mainHeader.isNewBrandedSite ? " my-3" : " my-4")}>
                                                {fields.mainHeader.centerName}</div>
                                        </>
                                    )
                                    :
                                    (<div className="logo d-none d-lg-block">
                                        <a aria-label={fields?.mainHeader?.centerName} href={(fields.mainHeader.urlPrefix != null && fields.mainHeader.urlPrefix != "") ? fields.mainHeader.urlPrefix : "/"}>
                                            {(fields.mainHeader.logo != null) &&
                                                <img src={fields.mainHeader.logo} alt={fields.mainHeader.centerName} title={fields.mainHeader.centerName} />
                                            }
                                        </a>
                                    </div>
                                    )}
                            </div>

                            <div className={"w-100 w-lg-auto align-items-center text-center text-lg-start opening-hours gtmHeaderCommon"
                                + (fields.mainHeader.isNewBrandedSite ? "" : " d-flex")}>
                                {fields.mainHeader.isNewBrandedSite ?

                                    (
                                        <>
                                            <div>
                                                <a className="area-map d-block me-lg-4" aria-label={fields?.mainHeader?.goThere_Url} href={fields.mainHeader.goThere_Url}>
                                                    <span className="picto icon-right-arrow-square d-inline-block align-middle me-1"></span>
                                                    <span className="go-in">{fields.mainHeader.location}</span>
                                                </a>
                                            </div>
                                            <div className="description gradient d-block mt-3 mt-lg-0 d-lg-none">
                                                {fields.mainHeader.centerHoursCaption &&
                                                    <>
                                                        <div className="center-hours d-block">{fields.mainHeader.centerHoursCaption.label}</div>
                                                        <a href={fields.mainHeader.centerHoursCaption.url?.value?.url} aria-label={fields?.mainHeader?.centerHoursCaption} className="center-hours d-block link-underline">
                                                            {fields.mainHeader.centerHoursCaption.url?.value?.text}
                                                        </a>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    ) :

                                    (
                                        <div className="description w-100 w-lg-auto me-lg-2">
                                            <a className={"area-map d-block mb-3" + (fields.mainHeader.isNewBrandedSite ? " text-underline" : "")} href={fields.mainHeader.goThere_Url}>
                                                <span className="picto icon-right-arrow-square d-inline-block align-middle me-1"></span>
                                                <span className="go-in text-decoration-none">{fields.mainHeader.location}</span>
                                            </a>
                                            {fields.mainHeader.centerHoursCaption != null &&
                                                <>
                                                    <div className="center-hours d-block text-white">{fields.mainHeader.centerHoursCaption.label}</div>
                                                    <a href={fields.mainHeader.centerHoursCaption.url?.value?.url} aria-label={fields?.mainHeader?.centerHoursCaption} className="center-hours d-block text-white link-underline">
                                                        {fields.mainHeader.centerHoursCaption.url?.value?.text}
                                                    </a>
                                                </>
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            <ul className={"navbar-nav mainmenu flex-row w-100 align-items-lg-center" +
                                (fields.mainHeader.isNewBrandedSite ? " mt-lg-0 mb-lg-0 w-lg-auto ms-lg-auto" : " my-2 mx-3")} id="pictoHome">
                                {
                                    fields.mainHeader.rightLinks != null && fields.mainHeader.rightLinks.length > 0 &&
                                    fields.mainHeader.rightLinks.map((link, index) => {
                                        let strPicto = "";
                                        let strCount = "";
                                        let pageLink = "";
                                        let splitTxt = '';
                                        let isLiveShopping = "";
                                        let left = "";
                                        let right = "";
                                        let strLength = "";
                                        let leftString = "";
                                        let rightString = "";
                                        let middleString = "";
                                        let strMiddle = "";
                                        let strMid = "";

                                        let viewClass = link.display_In_Mobile ? "" : " d-none";

                                        if (link.itemId === fields.mainHeader.storesMainHeader) {
                                            strPicto = "icon-ico-ph-shops";
                                            strCount = "(" + fields.mainHeader.storesCount + ")";
                                            pageLink = fields.mainHeader.shopLanding_Page;
                                        }
                                        else if (link.itemId === fields.mainHeader.restaurantsMainHeader) {
                                            strPicto = "icon-srv-restaurant";
                                            strCount = "(" + fields.mainHeader.restaurantsCount + ")";
                                            pageLink = fields.mainHeader.restaurant_Landing_Page;
                                        }
                                        else if (link.itemId === fields.mainHeader.offersMainHeader) {
                                            strPicto = "icon-servicesaspictosoffers";
                                            strCount = "(" + fields.mainHeader.offersCount + ")";
                                            pageLink = fields.mainHeader.loyaltyOffers_Page;
                                        }
                                        else if (link.itemId === fields.mainHeader.newsMenu) {
                                            strPicto = "icon-servicesaspictosevent";
                                            strCount = "(" + fields.mainHeader.newsCount + ")";
                                            pageLink = fields.mainHeader.news_Page;
                                        }
                                        else if (link.itemId === fields.mainHeader.cinemaMainHeader) {
                                            strPicto = "icon-srv-cinema";
                                            strCount = "(" + fields.mainHeader.moviesCount + ")";
                                            pageLink = fields.mainHeader.cinema_Page;
                                        }
                                        else if (link.itemId === fields.mainHeader.liveShoppingMainHeader) {
                                            splitTxt = (link.link.value.text === null || link.link.value.text === "") ? link.itemName : link.link.value.text;
                                            left = splitTxt.indexOf("[");
                                            right = splitTxt.indexOf("]") + 1;
                                            strLength = splitTxt.length
                                            leftString = splitTxt.substring(right, strLength);
                                            rightString = splitTxt.substring(0, left);
                                            middleString = splitTxt.substring(left, right);
                                            strMiddle = middleString.replace("[", "");
                                            strMid = strMiddle.replace("]", "");
                                            strPicto = "icon-live";
                                            strCount = "(2)";

                                            isLiveShopping = fields.mainHeader.isLiveEventEnabled ? true : false;
                                            if (isLiveShopping !== true) {
                                                return <></>;
                                            }
                                            pageLink = fields.mainHeader.liveShopping_Page;
                                        }

                                        if (strCount !== "" && strCount !== "(0)") {
                                            return (
                                                (!isLiveShopping) ?
                                                    (<li key={index} className={"px-lg-2 py-2 m-lg-0 w-100 w-lg-auto dropdown limit-li"
                                                        + (fields.mainHeader.isNewBrandedSite ? " pe-1" : " px-1 py-lg-0 me-1 bg-white rounded-1") + viewClass}>
                                                        <a href={fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + pageLink} aria-label={fields?.mainHeader?.urlPrefix}
                                                            className={"nav-link p-0 py-lg-4 text-center text-uppercase dropdown-toggle gtmHeaderCommon"}>
                                                            <span className={"mb-2 picto " + strPicto + " d-block d-lg-none" + (fields.mainHeader.isNewBrandedSite ? "" : " fs-1 text-black")}></span>
                                                            {(fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage)
                                                                ? (<h2 className="mt-1 m-lg-0 d-inline-block mainheader-mobile-menu">{(link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text}</h2>)
                                                                : (fields.mainHeader.isNewBrandedSite ? (<h2 className="mt-1 m-lg-0 d-inline-block">{(link.link.value.text == null || link.link.value.text == "")
                                                                    ? link.itemName : link.link.value.text}</h2>) : (<span>{(link.link.value.text == null || link.link.value.text == "") ? link.itemName : link.link.value.text}</span>))
                                                            }
                                                            {(strCount != null && strCount != "" && !fields.mainHeader.isNewBrandedSite) &&
                                                                <span className="count d-block d-lg-inline-block">{strCount}</span>
                                                            }
                                                        </a>
                                                    </li>) :
                                                    (<li key={index} className={"px-lg-2 py-2 m-lg-0 w-100 w-lg-auto dropdown limit-li"
                                                        + (fields.mainHeader.isNewBrandedSite ? " pe-1" : " px-1 py-lg-0 me-1 bg-white rounded-1") + viewClass}>
                                                        <a href={fields.mainHeader.urlPrefix + fields.mainHeader.slashConstant + pageLink} aria-label={fields?.mainHeader?.urlPrefix}
                                                            className={"nav-link p-0 py-lg-4 text-center text-uppercase dropdown-toggle gtmHeaderCommon"}>
                                                            <span className={"mb-2 picto " + strPicto + " d-block d-lg-none" + (fields.mainHeader.isNewBrandedSite ? "" : " fs-1 text-black")}></span>
                                                            {rightString !== null && rightString !== "" && (<h2 className={"mt-1 m-lg-0 d-inline-block" + (fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage ? " mainheader-mobile-menu" : "")}>{rightString}</h2>)}
                                                            {strMid !== null && strMid !== "" && (<h2 className={"mt-1 m-lg-0 d-inline-block" + (fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage ? " mainheader-mobile-menu" : "") + " bg-live-shopping px-1 ms-lg-1"}>{strMid}</h2>)}
                                                            {leftString !== null && leftString !== "" && (<h2 className={"mt-1 m-lg-0 d-inline-block ms-lg-1" + (fields.mainHeader.contextUrlEqualsSlash || fields.mainHeader.contextUrlEqualsHomepage ? " mainheader-mobile-menu" : "")}>{leftString}</h2>)}
                                                            {(strCount !== null && strCount !== "" && !fields.mainHeader.isNewBrandedSite) &&
                                                                <span className="count d-block d-lg-inline-block">{strCount}</span>
                                                            }
                                                        </a>
                                                    </li>)
                                            )
                                        }
                                    })
                                }
                            </ul>

                            {
                                (fields.mainHeader.isLoyaltyprogramExist && fields.mainHeader.loyalty != null) &&
                                <>
                                    {
                                        (fields.mainHeader.loyalty?.value?.url != null && fields.mainHeader.loyalty.value.url != "") &&
                                        <>
                                            {
                                                (fields.mainHeader.userLoginStatus != null || fields.mainHeader.userLoginStatus != "") && (fields.mainHeader.userLoginStatus === "scan") ?
                                                    (<ul className={"navbar-nav loyaltymenu w-100 w-lg-auto shadow-sm m-0 p-0 d-lg-none gtmHeader"
                                                        + (fields.mainHeader.isLPV2Site ? " premium-bg" : " loyalty-bg")
                                                        + (fields.mainHeader.loyalty.display_In_Mobile ? "" : " d-none")} >
                                                        <li className="w-100 w-lg-auto px-4 py-3">
                                                            <a href={fields.mainHeader.loyalty.value.url} aria-label={fields?.mainHeader?.loyalty.value} target={fields.mainHeader.loyalty.value.target}
                                                                className={"nav-link text-white m-0 p-0 d-flex justify-content-between align-items-center text-uppercase"
                                                                    + (fields.mainHeader.isNewBrandedSite ? "" : " fw-bold")} data-testid="testclick27" onClick={(e) => { loyaltyProgramDataLayer() }}>
                                                                <span className="picto icon-vip-card"></span>
                                                                <span className={"px-3 me-auto" + (fields.mainHeader.isNewBrandedSite ? " text-uppercase" : "")}>
                                                                    {fields.mainHeader.loyalty.value.text}</span>
                                                                <span className="picto icon-ico-chevron-right"></span>
                                                            </a>
                                                        </li>
                                                    </ul>)
                                                    :
                                                    (<ul className={"navbar-nav loyaltymenu w-100 w-lg-auto shadow-sm m-0 p-0 d-lg-none gtmHeader"
                                                        + (fields.mainHeader.isLPV2Site ? " premium-bg" : " loyalty-bg")
                                                        + (fields.mainHeader.loyalty.display_In_Mobile ? "" : " d-none")} >
                                                        <li className="w-100 w-lg-auto px-4 py-3">
                                                            <a href={fields.mainHeader.loyalty.value.url} aria-label={fields?.mainHeader?.loyalty.link.value} target={fields.mainHeader.loyalty.value.target}
                                                                className={"nav-link text-white m-0 p-0 d-flex justify-content-between align-items-center text-uppercase"
                                                                    + (fields.mainHeader.isNewBrandedSite ? "" : " fw-bold")} data-testid="testclick28" onClick={(e) => { loyaltyProgramDataLayer() }}>
                                                                <span className="picto icon-vip-card"></span>
                                                                <span className={"px-3 me-auto" + (fields.mainHeader.isNewBrandedSite ? " text-uppercase" : " programme-de-texte")}>
                                                                    {fields.mainHeader.loyalty.value.text}</span>
                                                                <span className="picto icon-ico-chevron-right"></span>
                                                            </a>
                                                        </li>
                                                    </ul>)
                                            }
                                        </>
                                    }
                                </>
                            }
                        </nav>
                    </div>
                </div> */}
            </>
        );
    }
    else {
        return <></>;
    }
}

