import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import { centerSelecterDataLayer, changeCountryDataLayer, closeMenu, enableAnnouncementBanner, fnDiscoverAnotherCountryPopin } from '../Js-CommonFunctions';

export default function CenterSelectorPopin({ model }) {

    useEffect(() => {
        var ele = document.getElementById("shoppingCenterSelection");
        if (ele !== null && ele !== undefined) {
            var heightHeader = "0px";
            // if (document.getElementById("MainHeader") && document.getElementById("headerBlock")) {
            //     if (
            //         document.getElementById("MainHeader").offsetWidth <= 0 &&
            //         document.getElementById("MainHeader").offsetHeight <= 0
            //     ) {
            //         heightHeader =
            //             document.getElementById("headerBlock").offsetHeight;
            //     } else {
            //         heightHeader =
            //             document.getElementById("headerBlock").offsetHeight +
            //             document.getElementById("MainHeader").offsetHeight;
            //     }
            // }
            // else 
            if (document.getElementById("headerBlock")) {
                heightHeader =
                    document.getElementById("headerBlock").offsetHeight;
            }
            //To show header portion
            document.getElementById("shoppingCenterSelection").style.top = heightHeader +"px";

            var popinClick = document.getElementById("popinCenterSelectorClick");
            if (popinClick !== null && popinClick !== undefined) { popinClick.click(); }
        }
    }, [model]);

    if (model !== null && model !== undefined && model !== "") {
        return (
            <div className="modal-dialog modal-popin-selector m-0" id="shoppingCenterSelection">
                <div className={"modal-content border-0 rounded" + (model.isBrandedSite ? " " : " ")}>
                    <div className="modal-header p-0">
                        {model.exitLinkText !== "" && model.exitLinkText !== null &&
                            <div className={"quitter-la-recherche d-lg-flex align-items-center"}>
                                <a data-testid="closeMenuOnclick6" aria-label={model?.exitLinkText} className="d-flex align-items-center text-decoration-none" href="javascript:void(0);" data-bs-dismiss="modal" onClick={(e) => { closeMenu(); enableAnnouncementBanner() }}>
                                    <span className="quit-content me-4">{model.exitLinkText}</span>
                                    <span className="picto icon-close-circle-rw text-center fs-1 text-black "></span>
                                </a>
                            </div>
                        }

                    </div>
                    <div className={"modal-body text-center p-0" + (model.isBrandedSite ? "" : "")}>
                        <div className="shoppingheader modal-popin-selector ">
                            <div className={"modalContainer container p-0"}>
                                <nav className={"navbar header-height bg-gray col-sm-12 col-md-12 col-lg-12 justify-content-lg-start justify-content-center" + (model.isBrandedSite ? " " : " ")}>
                                    {/* <div className={"col-sm-2 col-md-2 col-lg-1 text-center px-0"}>
                                        <a className={"picto icon-chevron-bottom fs-3" + (model.isBrandedSite ? "" : " text-black")} data-bs-dismiss="modal" aria-label="Close"></a>
                                    </div>
                                    <div className={"col-sm-8 col-md-8 col-lg-10 text-center p-0"}>
                                        <div className={"text-center find-shopping-center text-uppercase" + (model.isBrandedSite ? " text-white" : " text-black")}>
                                            {model.heading}
                                        </div>
                                    </div>
                                    <div className={"col-sm-2 col-md-2 col-lg-1 text-center px-0"}>
                                        <a data-testid="closeMenuOnclick" aria-label={model?.heading} className={"picto icon-close-circle-rw d-sm-block text-center fs-3 d-lg-none" + (model.isBrandedSite ? "" : " text-black")}
                                            href="javascript:void(0);" data-bs-dismiss="modal" onClick={() => { closeMenu(); enableAnnouncementBanner() }}></a>
                                    </div> */}
                                    <div className="p-0 d-flex align-items-center">
                                        {model.location?.value !== null && model.location?.value !== "" &&
                                            <span className="text-start shopping-center-choose-country mx-lg-3">
                                                <RichText field={model.location} className="" />
                                            </span>
                                        }
                                        {(model.changeConuntyLink?.value?.text !== null && model.changeConuntyLink?.value?.text !== "" &&
                                            model.changeConuntyLink?.value?.url !== null && model.changeConuntyLink?.value?.url !== "") &&
                                            <span className="flex-item-right ps-lg-2 ps-5 d-flex align-items-center">
                                                <a data-testid="closeMenuOnclick2" aria-label={model?.changeConuntyLink?.value?.text} className="shopping-center-change-country text-decoration-underline d-inline-block text-black align-items-center justify-content-center"
                                                    onClick={(e) => {
                                                        changeCountryDataLayer();
                                                        fnDiscoverAnotherCountryPopin(model.changeConuntyLink.value.url)
                                                    }}>
                                                    {model.changeConuntyLink.value.text}
                                                </a>
                                                <span className="picto icon-global-rw  ms-2 mt-1 d-flex align-items-center text-decoration-none d-inline-block align-middle"></span>
                                            </span>
                                        }
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className={"popin-wrap w-100 p-0" + (model.isBrandedSite ? "" : "")}>
                            <div className="shopping-center-selection position-relative" id="shoppingcenterSelector">

                                <section className={"country-selection-section py-4" + (model.isBrandedSite ? " odd" : " odd")}>
                                    <div className="wrapper container modalContainer">
                                        <div className="row m-0">
                                            {model.countryOrCenterList !== null && model.countryOrCenterList.length > 0 &&
                                                <div className="col-sm-4 p-lg-3 px-0 w-33 pb-0 ps-lg-0 pt-lg-0">
                                                    <ul className="list-group shopping-center-selection-list">
                                                        {model.countryOrCenterList.slice(0, model.column1).map((item, index) => {
                                                            if (item.link?.value?.url != null && item.link.value.url != "") {
                                                                return (
                                                                    <li key={index} className="list-group-item d-inline-block border-0">
                                                                        <a data-testid="closeMenuOnclick3" aria-label={item?.title?.value} onClick={(e) => centerSelecterDataLayer(item.title.value)} href={item.link.value.url} className="d-block text-decoration-none shopping-list-item">{item.title.value}</a>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {model.countryOrCenterList !== null && model.countryOrCenterList.length > 6 &&
                                                <div className="col-sm-4 p-lg-3 px-0 w-33 pt-lg-0 pb-0">
                                                    <ul className="list-group shopping-center-selection-list">
                                                        {model.countryOrCenterList.slice(model.column1, model.column1 + model.column2).map((item, index) => {
                                                            if (item.link?.value?.url != null && item.link.value.url != "") {
                                                                return (
                                                                    <li key={index} className="list-group-item d-inline-block border-0">
                                                                        <a data-testid="closeMenuOnclick4" aria-label={item?.title?.value} onClick={(e) => centerSelecterDataLayer(item.title.value)} href={item.link.value.url} className="d-block text-decoration-none shopping-list-item">{item.title.value}</a>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {model.countryOrCenterList !== null && model.countryOrCenterList.length > 12 &&
                                                <div className="col-sm-4 p-lg-3 px-0 w-33 pt-lg-0 pe-lg-0">
                                                    <ul className="list-group shopping-center-selection-list">
                                                        {model.countryOrCenterList.slice(model.column1 + model.column2).map((item, index) => {
                                                            if (item.link?.value?.url != null && item.link.value.url != "") {
                                                                return (
                                                                    <li key={index} className="list-group-item d-inline-block border-0">
                                                                        <a data-testid="closeMenuOnclick5" aria-label={item?.title?.value} onClick={(e) => centerSelecterDataLayer(item.title.value)} href={item.link.value.url} className="d-block text-decoration-none shopping-list-item">{item.title.value}</a>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {model.goToLinkText !== "" && model.goToLinkText !== null &&
                                        <div className="d-flex container modalContainer mt-4">
                                            <a aria-label={model?.goToLink} className={"d-flex mx-2 fw-bold go-to-westfield text text-decoration-underline" + (model.isBrandedSite ? "" : "")} href={model.goToLink}>{model.goToLinkText}
                                            </a>
                                            <span className='picto icon-arrow-right-rw text-center fs-5' />
                                        </div>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return <></>;
    }
}
