import { Placeholder, RichText, VisitorIdentification } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from 'react-router-dom';
import { baseModel } from "./RouteHandler";
import {
  AddGtmEventListeners,
  GenericBlocksMobile,
  ListofShopsMobileView,
  LoadPopins,
  SmartAppBannerScripts,
  brandFilterClass,
  categoryScrollTop,
  cookiesizing,
  documentReadyLoad,
  fnApplyPyjamaEffect,
  fnGTMViewEventTracking,
  fnInitializeVendorGTM,
  fnResetCookieExpiry,
  fnSearchIconClick,
  formReadyLoad,
  handleSuccessMessageUrls,
  isDesktopdevice,
  isElementPresentInViewport,
  isMobile,
  lazyLoadingImg,
  logoutFromGS,
  mainLoad,
  mobileValidation,
  readCookie,
  scrollToHidden,
  sectionBgClass,
  subAnchors,
  unibailOffersLoad,
  unibailscrollLoad,
  webViewEqualsOne,
} from "./components/Js-CommonFunctions.js";
import SearchRendering from "./components/SearchRendering";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes

var ccLoop;
var cookieListener = null;
var cookieRegistry = [];

function loadClobsScript() {
  if (typeof window !== "undefined") {
    if (window.cookieconsent) {
      window.cookieconsent("consent", "webAnalytics", function (accept) {
        switch (accept) {
          case "accept":
            // insert the javascript tag code here
            var js = document.createElement("script");
            js.type = "text/javascript";
            js.id = "clobscript";
            js.src = baseModel.clobsUrl;
            document.body.appendChild(js);
            clearInterval(ccLoop);
            break;
          case "refuse":
            clearInterval(ccLoop);
            break;
          default:
            break;
        }
      });
    }
  }
}

const GenericLayout = ({ routeData }) => {
  const location = useLocation();

  if (typeof document !== "undefined") {
    document.documentElement.setAttribute(
      "lang",
      routeData.Headless.baseModel.selectedLanguageISO
    );
    document.documentElement.classList.add("no-js");
  }

  useEffect(() => {
    webViewEqualsOne();

    //listofstores restaurants
    if (window.innerWidth < 991) {
      if (document.getElementById("brandFilter")) {
        brandFilterClass();
      }
    }

    if (routeData.Headless.baseModel.ipLabelEnabled) {
      ipLabelEnabled();
    }

    ListofShopsMobileView();

    if (baseModel.doPageReload) {
      window.location.reload();
    }

    window.addEventListener("scroll", function () {
      lazyLoadingImg();
      if (window.innerWidth < 991) {
        categoryScrollTop();
      }

      if (document.getElementById("newscarousel")) {
        const isInViewPort = isElementPresentInViewport(
          document.getElementById("newscarousel")
        );

        if (!isInViewPort) {
          var iframes = document.querySelectorAll("iframe");
          Array.prototype.forEach.call(iframes, (iframe) => {
            iframe.contentWindow.postMessage(
              JSON.stringify({ event: "command", func: "pauseVideo" }),
              "*"
            );
            iframe.contentWindow.postMessage(
              JSON.stringify({ method: "pause", value: "true" }),
              "*"
            );
          });

          var videoElements = document.querySelectorAll("video");
          Array.prototype.forEach.call(videoElements, (video) => {
            video.pause();
          });
        }

      }
    });

    if (typeof window !== "undefined") {
      if (routeData.Headless.baseModel.ipLabelEnabled) {
        ccLoop = setInterval(loadClobsScript, 1000);
      }
    }

    //Google Tag Manager
    fnInitializeVendorGTM(false);
 // explicitly for stores page to fix some bugs
 if (routeData.Headless.baseModel.currentPageItemName === 'stores' || routeData.Headless.baseModel.currentPageItemName === 'store' || routeData.Headless.baseModel.currentPageItemName === 'offers' || routeData.Headless.baseModel.isDestinationPage ) {
  document.body.classList.add(
    "on-homepage"
  );
}

    document.body.classList.add("breadcrumb-on", "new");
    if (routeData.Headless.baseModel.displayAnnouncementBanner)
      document.body.classList.add("emergency-notice-show");

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = routeData.Headless.scriptVariables;
    document.head.appendChild(s);

    if (routeData.Headless.baseModel.enableGoogleAntiFlickerScript) {
      const style = document.createElement("style");
      style.innerHTML = `.async-hide {
        opacity: 0 !important
        }`;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML =
        `(function (a, s, y, n, c, h, i, d, e) {
        s.className += ' ' + y; h.start = 1 * new Date;
          h.end = i = function () {s.className = s.className.replace(RegExp(' ?' + y), '')};
          (a[n] = a[n] || []).hide = h; setTimeout(function () {i(); h.end = null }, c); h.timeout = c;
      })(window, document.documentElement, 'async-hide', 'dataLayer', 4000,
          {'` +
        routeData.Headless.baseModel.gtmContainerId +
        `': true });`;
      document.head.appendChild(style);
      document.head.appendChild(script);
    }

    subAnchors();
    fnSearchIconClick();
    sectionBgClass();
    isDesktopdevice();
    //functions that needs to be called on document.ready()
    documentReadyLoad();
    cookiesizing();
    fnApplyPyjamaEffect();
    mainLoad();
    mobileValidation();
    scrollToHidden();
    unibailscrollLoad();
    AddGtmEventListeners();
    fnGTMViewEventTracking();
    formReadyLoad();
    handleSuccessMessageUrls();
    GenericBlocksMobile();
    unibailOffersLoad();

    //function to logout user session after 30 mins of idle time
    var cookieUIDValue = readCookie("UUID");
    if (
      cookieUIDValue != null &&
      cookieUIDValue != "" &&
      cookieListener == null
    ) {
      cookieRegistry["UUID"] = cookieUIDValue; //initialize the cookie registry array
      listenCookieChange("UUID", function () {
        logoutFromGS();
        clearInterval(cookieListener);
        cookieListener = null;
        cookieRegistry = [];
      });
    }

    fnResetCookieExpiry(); //Whenever a page is loaded, reset the cookie expiry limit
    //Listen to 'UID' cookie and when it expires, trigger a callback function to logout the user and clear other cookies
    function listenCookieChange(cookieName, callback) {
      cookieListener = setInterval(function () {
        var cookieValue = readCookie(cookieName);
        if (cookieRegistry[cookieName]) {
          if (
            cookieValue != cookieRegistry[cookieName] &&
            (cookieValue == "" || cookieValue == null)
          ) {
            // update registry so we dont get triggered again
            cookieRegistry[cookieName] = cookieValue;
            return callback();
          }
        } else {
          cookieRegistry[cookieName] = cookieValue;
        }
      }, 30000); //30 sec
    }

    if (document.getElementById("carouselPillars")) {
      document.getElementById("root").classList.add("loyalty-program");
    }

    if (baseModel.enableiAdvize && baseModel.iAdvizeScript.value != null && baseModel.iAdvizeScript.value != "") {
      const iAdvizeScript = document.createElement("script");
      iAdvizeScript.innerHTML =
        `window.idzCustomData = {
            "page_type":"`+ baseModel.pageType + `",
            "page_category":"`+ baseModel.pageCategory + `",
            "page_CommercialCenter":"`+ baseModel.shoppingCenterName + `",
            "page_country":"`+ baseModel.countryName + `",
            "status_loyaltyprogram":`+ baseModel.loginStatus + `
          };`;

      document.getElementById("iAdvizeBlock").prepend(iAdvizeScript);
    }

    if (isMobile.any()) {
      //smartappbanner scripts
      const js = document.createElement('script');
      js.src = routeData.Headless.assetServerUrl +
        "/dist/newunibailreactapp/js/smart-app-banner.min.js?version=" +
        routeData.Headless.jssCssVersion;
      js.addEventListener('load', () => { });
      document.body.appendChild(js);
      setTimeout(function () { SmartAppBannerScripts(); }, 2000);
      setTimeout(function () { OverlayZindex(); }, 1000);
    }

  }, []);

  function OverlayZindex() {
    var accessibility = document.getElementsByTagName('access-widget-ui');
    if (accessibility !== null && accessibility !== undefined && accessibility !== "") {
      var count = 0;
      for (let i of accessibility) {
        if (count == 2 && i.shadowRoot.querySelector('button') !== null && i.shadowRoot.querySelector('button') !== undefined && i.shadowRoot.querySelector('button') !== "") {
          i.shadowRoot.querySelector('button').style = "z-index:1300";
        }
        count = count + 1
      }
    }
  }

  function ipLabelEnabled() {
    const script = document.createElement("script");
    script.innerHTML =
      `var iplobserverstart = new Date(); var iplperformancetiming = 1; var iplcustomdim ='` +
      baseModel.striplabel +
      `';`;
    document.head.appendChild(script);
  }

  return (
    <React.Fragment>
      <RichText field={baseModel.scriptHeaderCode} />
      <RichText field={baseModel.scriptBodyCode} />
      <RichText field={baseModel.cookieBannerScript} />

      {baseModel.themeHtml !== null &&
        baseModel.themeHtml !== undefined &&
        baseModel.themeHtml.value !== null &&
        baseModel.themeHtml !== "" && <RichText field={baseModel.themeHtml} />}

      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}

      <Helmet>
        {/* DNS Prefetcing to reduce DNS lookup time */}
        {routeData.Headless.baseModel.dnsPrefetchingList?.map(
          (dnsName, key) => {
            return <link key={key} rel="dns-prefetch" href={"//" + dnsName} />;
          }
        )}

        {/* robots donot follow if it is web database */}
        {routeData.Headless.isMasterdatabase && (
          <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
        )}

        {routeData.Headless.isWebdatabase &&
          routeData.Headless.avoidSearchCrawling != null &&
          routeData.Headless.avoidSearchCrawling == "1" && (
            <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
          )}

        <meta charset="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta name="format-detection" content="telephone=no" />

        {routeData.Headless.baseModel.androidAppId != null &&
          routeData.Headless.baseModel.androidAppId != "" &&
          routeData.Headless.baseModel.androidAppIconUrl != null &&
          routeData.Headless.baseModel.androidAppIconUrl != "" && (
            <meta
              name="google-play-app"
              content={
                "smartban-id=" +
                routeData.Headless.baseModel.androidAppId
              }
            />
          )}

        {routeData.Headless.baseModel.iTunesAppId != null &&
          routeData.Headless.baseModel.iTunesAppId != "" &&
          routeData.Headless.baseModel.iTunesAppIconUrl != null &&
          routeData.Headless.baseModel.iTunesAppIconUrl != "" && (
            <meta
              name="apple-itunes-app"
              content={
                "smartban-id=" +
                routeData.Headless.baseModel.iTunesAppId
              }
            />
          )}

        <title>{routeData.Headless.baseModel.metaPageTitle}</title>
        {(routeData.Headless.baseModel.metaPageTitle != null &&
          routeData.Headless.baseModel.metaPageTitle != "") &&
          <meta
            name="title"
            content={routeData.Headless.baseModel.metaPageTitle}
          />
        }

        {(routeData.Headless.baseModel.metaDescription != null &&
          routeData.Headless.baseModel.metaDescription != "" &&
          routeData.Headless.baseModel.metaDescription.rich_Text != null &&
          routeData.Headless.baseModel.metaDescription.rich_Text != "") &&
          <meta
            name="description"
            content={routeData.Headless.baseModel.metaDescription.rich_Text}
          />
        }

        {routeData.Headless.baseModel.ogTitle != null &&
          routeData.Headless.baseModel.ogTitle != "" && (
            <meta
              property="og:title"
              content={routeData.Headless.baseModel.ogTitle}
            />
          )}

        {routeData.Headless.baseModel.ogDescription != null &&
          routeData.Headless.baseModel.ogDescription != "" &&
          routeData.Headless.baseModel.ogDescription.rich_Text != null &&
          routeData.Headless.baseModel.ogDescription.rich_Text != "" && (
            <meta
              property="og:description"
              content={routeData.Headless.baseModel.ogDescription.rich_Text}
            />
          )}

        {routeData.Headless.baseModel.appDomain !== null &&
          routeData.Headless.baseModel.appDomain !== "" &&
          <meta
            property="og:url"
            content={routeData.Headless.baseModel.appDomain + location.pathname}
          />
        }

        <meta property="og:type" content="website" />
        {
          routeData.Headless.baseModel.metaImageUrl != null &&
          routeData.Headless.baseModel.metaImageUrl != "" && (
            <meta
              property="og:image"
              content={routeData.Headless.baseModel.metaImageUrl}
            />
          )
        }

        <meta name="twitter:card" content="summary_large_image" />

        {routeData.Headless.baseModel.metaPageTitle != null &&
          routeData.Headless.baseModel.metaPageTitle != "" && (
            <meta
              name="twitter:title"
              content={routeData.Headless.baseModel.metaPageTitle}
            />
          )}

        {routeData.Headless.baseModel.ogDescription != null &&
          routeData.Headless.baseModel.ogDescription != "" &&
          routeData.Headless.baseModel.ogDescription.rich_Text != null &&
          routeData.Headless.baseModel.ogDescription.rich_Text != "" && (
            <meta
              name="twitter:description"
              content={routeData.Headless.baseModel.ogDescription.rich_Text}
            />
          )}

        {routeData.Headless.baseModel.metaImageUrl != null &&
          routeData.Headless.baseModel.metaImageUrl != "" && (
            <meta
              name="twitter:image:src"
              content={routeData.Headless.baseModel.newImageUrl}
            />
          )}

        {routeData.Headless.baseModel.appDomain !== null &&
          routeData.Headless.baseModel.appDomain !== "" &&
          <meta
            name="twitter:url"
            content={routeData.Headless.baseModel.appDomain + location.pathname}
          />
        }

        {routeData.Headless.baseModel.metaTagCollection?.map(
          (metatagcollection, key) => {
            return (
              <meta key={key}
                name={metatagcollection.name}
                httpEquiv={metatagcollection.httpEquiv}
                content={metatagcollection.content}
              />
            );
          }
        )}

        <link
          rel="shortcut icon"
          type="image/png"
          href={routeData.Headless.baseModel.favoriteIcon}
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href={routeData.Headless.baseModel.favoriteIcon}
        />
        <link
          rel="stylesheet"
          href={routeData.Headless.siteCss}
          async
          defer
        />
        {routeData.Headless.themeSelected != null &&
          routeData.Headless.themeSelected != "" && (
            <link
              rel="stylesheet"
              href={routeData.Headless.themecss}
              async
              defer
            />
          )}

      </Helmet>

      {<RichText field={baseModel.accesiBEScript} />}

      {baseModel.enableiAdvize &&
        <RichText field={baseModel.iAdvizeScript} id="iAdvizeBlock" />
      }

      <div id="wrapper">
        {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
        <VisitorIdentification />
        {/* root placeholder for the app, which we add components to using route data */}
        <>
          <Placeholder name="jss-main" rendering={routeData.route} />
          <SearchRendering searchData={routeData.Headless} />
        </>

        <div
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#globalPopin"
          id="popinClick">
        </div>
        <div
          className="modal"
          id="globalPopin"
          tabIndex="-1"
          aria-labelledby="globalPopin"
          aria-hidden="true">
          {LoadPopins()}
        </div>
        <div
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#popinAccDelcode"
          id="popinDelAccClick">
        </div>
        <div
          className="modal"
          id="popinAccDelcode"
          tabIndex="-1"
          aria-labelledby="popinAccDelcode"
          aria-hidden="true">
        </div>
        <div
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#popinCountrySelector"
          id="popinCountrySelectorClick">
        </div>
        <div
          className="modal"
          id="popinCountrySelector"
          tabIndex="-1"
          aria-labelledby="popinCountrySelector"
          aria-hidden="true">
        </div>
        <div
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#popinCenterSelector"
          id="popinCenterSelectorClick">
        </div>
        <div
          className="modal"
          id="popinCenterSelector"
          tabIndex="-1"
          aria-labelledby="popinCenterSelector"
          aria-hidden="true">
        </div>

        {baseModel.boolEnableMyFeelBack && (
          <RichText field={baseModel.myFeelBackContent} />
        )}

        {<RichText field={baseModel.scriptFooterCode} />}

        {baseModel.themeScript !== null &&
          baseModel.themeScript !== undefined &&
          baseModel.themeScript.value !== null &&
          baseModel.themeScript !== "" && (
            <RichText field={baseModel.themeScript} />
          )}

        <noscript>
          <iframe
            id="gtmIframe"
            title="gtmIframe"
            src={
              "https://www.googletagmanager.com/ns.html?id=" +
              routeData.Headless.baseModel.gtmContainerId
            }
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </div>

      <script
        src={
          routeData.Headless.assetServerUrl +
          "/dist/newunibailreactapp/js/unibailscripts.min.js?version=" +
          routeData.Headless.jssCssVersion
        }
      ></script>
    </React.Fragment>
  );
};

export default GenericLayout;
