import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from "react";
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler.js';
import { fnConnectRedirectTodetailPage, fnEventOccurrenceDetails, fnRedirectToConnectLogin, fngtmBookingTicket } from '../Js-CommonFunctions.js';

export default function NewsAndOffersInformation({ fields }) {

    var model = fields.heroNewsModel;
    if (model !== null && model !== undefined && model !== "" && (model.displayName.trim() !== "" || model.description.trim() !== "" || (model.date_Time_Info !== null && model.date_Time_Info?.length > 0))) {
        var desc = { value: '' };
        desc.value = model.description;
        let doesEventHaveOccurrencePeriod = (model.eventOccurrencePeriod.trim() !== "");
        let isLoggedIn = useSelector((state) => state.user.isLoggedIn);

        return (
            <>
                <section className="welcome" id="infos">
                    <div className="container">
                        <div className="opening-hours text-start">
                            {model.retailerList.trim() !== "" &&
                                <div className="retailer-name">{model.retailerList}</div>
                            }
                            <h1 className="content-title text-start text-uppercase">{model.displayName}</h1>
                            {model.eventOccurrencePeriod.trim() !== "" &&
                                <div className="date-time">{model.eventOccurrencePeriod}</div>
                            }
                            {(model.showExternalLinkCTA) &&

                                <a href={model.externalLinkCTALink} target="_blank" rel="noreferrer" className="link-button text-uppercase btn-dark">{model.externalLinkCTAText}</a>
                            }
                            {(model.showBookingBugCTA) &&
                                <div className="action">
                                    {(isLoggedIn) ?
                                        (
                                            <a href={model.bookingBugCTALink} className="link-button text-uppercase btn-dark" onClick={() => { fngtmBookingTicket(model); }}>{model.bookingBugCTAText}</a>
                                        ) :
                                        (
                                            <a href={baseModel?.connectRedirectUrl} className="link-button text-uppercase btn-dark" id="bookingbug_cta" onClick={(e) => { fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl); }}>{model.bookingBugCTATextNotLogin}</a>
                                        )
                                    }
                                </div>
                            }
                        </div>

                        <div className="row description">
                            <div className={"col-12 " + (doesEventHaveOccurrencePeriod ? "col-lg-7" : "col-lg-12 pe-lg-1") + " rich-text"}>
                                <RichText field={desc} />
                            </div>
                            {renderInfoTable(fields)}

                        </div>

                    </div>
                </section>
            </>
        )
    }
    else {
        return (<></>)
    }
}

function renderInfoTable(fields) {
    var model = fields.heroNewsModel;
    let countOfEventsFromCurrentDate = 0;
    if (model.date_Time_Info != null && model.date_Time_Info.length > 0) {
        return (
            <div className="col-12 col-lg-5 opening-hours">
                <div className="position-relative">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{model.day_Title}</th>
                                <th scope="col">{model.date_Title}</th>
                                <th scope="col">{model.hour_Title}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {model.date_Time_Info.map((date, index) => {
                                var endDateTime = new Date(date.endTimeValue).setHours(0, 0, 0, 0);
                                var localeDateTime = new Date(model.localeDateTime).setHours(0, 0, 0, 0);
                                if (endDateTime >= localeDateTime) {
                                    countOfEventsFromCurrentDate++;
                                    return (
                                        <tr key={index} id={"event" + (countOfEventsFromCurrentDate) + "_Details"}
                                            className={(countOfEventsFromCurrentDate > 2 ? "d-none" : "")}>
                                            <th scope="row">{date.day}</th>
                                            <td>{date.date + " " + date.month.toString().substring(0, 3)}</td>
                                            <td>
                                                {date.time + " - " + date.endTime}
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                    {countOfEventsFromCurrentDate > 2 &&
                        <div className="picto-wrap" data-testid='testClick' onClick={(e) => fnEventOccurrenceDetails(false)}>
                            <span className="picto icon-ico-chevron-down" id="eventdetailVM"></span>
                            <input type="hidden" id="displayedEventCount" value={countOfEventsFromCurrentDate} />
                        </div>
                    }
                </div>
            </div>
        )
    }
}