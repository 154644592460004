import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  CommonBookmarkallpage,
  fnClickNavigation,
  fnUpdateStoreSINameInDataAttr,
  lazyLoadingImg,
} from "../Js-CommonFunctions.js";

export default function RelatedItems({ fields }) {
  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        />
      );
    },
    arrows: false,
    dots: false,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    afterChange: () => {
      lazyLoadingImg();
    },
    responsive: [
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render
  useEffect(() => {
    setBoolUseEffect(true);
  }, []);

  useEffect(() => {
    lazyLoadingImg();
    CommonBookmarkallpage(); //Bind the bookmark click event and show already bookmarked items with active heart symbol

    // For the related shops component update the Retailer name in the attribute
    document
      .querySelectorAll("#related-shops .gtmClickNavigation")
      .forEach((x) => {
        x.addEventListener("click", function (e) {
          fnUpdateStoreSINameInDataAttr(e.currentTarget, "gtmclickposition");
          fnClickNavigation(e.currentTarget);
        });
      });
  }, [boolUseEffect]);
  if (fields?.storeItems !== null && fields?.storeItems?.relatedItemCount > 1) {
    var model = fields.storeItems;
    let cnt = 0;
    let generalLinkClickPosition =
      "CrossSelling-" + model.itemType + "-{storeSIName}";
    let bookMarkClickPostion = model.itemType + "/#CrossSelling/Bookmark";
    return (
      <section
        className={"related-shops " + (model.isNewBrandedSite ? " " : "")}
        id="related-shops"
      >
        <div className="wrapper container">
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
            {model.title.trim() !== "" && (
              <a
                className="title mb-1 mb-lg-0 gtmClickNavigation"
                href={model.urlString}
              >
                <h2>{model.title.trim() !== "" ? model.title : ""}</h2>
              </a>
            )}
            {!model.isNewBrandedSite && (
              <a
                className="action gtmClickNavigation"
                href={model.urlString}
              >
                <strong>{model.generalLink}</strong> ({model.totalCount})
                <span className="picto icon-ico-chevron-right"></span>
              </a>
            )}
          </div>

          <Slider {...settings} className="row carousel-related-shop">
            {model.items.map((store, index) => {
              model.storecategoryname = "";
              if (store.storeId !== model.itemId.toString() && cnt < 5) {
                cnt++;
                let storeItemClickPosition =
                  "CrossSelling-" +
                  cnt +
                  "-" +
                  model.itemType +
                  "-{storeSIName}";
                return (
                  <div className="col" key={index}>
                    <div
                      className={
                        "thumbnail " + (model.isUSUK ? model.usUkStoreLogo : "")
                      }
                    >
                      {store.isNewStore && (
                        <a
                          href="#"
                          className={
                            "new-tap " + (model.isNewBrandedSite ? "loyalty-text" : "pink")
                          }
                        >
                          {model.newTag}
                        </a>
                      )}
                      {store.isComingSoon && (
                        <a
                          href="#"
                          className={
                            "new-tap " + (model.isNewBrandedSite ? "" : "pink")
                          }
                        >
                          {model.comingSoon}
                        </a>
                      )}
                      <a
                        href="javascript:void(0);"
                        role="button"
                        className="open-popin picto bookmark icon-heart-unfill-rw  bookmark-news-new ava-wishlist "
                        aria-label={store.storeId}
                        aria-labelledby={model.itemType}
                        data-itemlabel={store.display_Name}
                        data-templatetype={(model.itemType === "store") ? "Store Detail" : "Restaurant Detail"}
                      ></a>

                      <a
                        href={model.urlPrefix + store.urlPath}
                        className="gtmClickNavigation"
                      >
                        <div className="header">
                          <span className="helper"></span>
                          <img
                            src={model.defaultImage}
                            data-alt={
                              store.imgUrl.trim() == "" ? "" : store.imgUrl
                            }
                            alt={store.imgAltText}
                          />
                        </div>
                      </a>
                      <div
                        className={
                          "caption " +
                          (model.isNewBrandedSite ? "border-0 text-start" : "")
                        }
                      >
                        <a
                          className="brand-name text-uppercase gtmClickNavigation"
                          href={model.urlPrefix + store.urlPath}
                        >
                          {store.display_Name}
                        </a>
                        {store.openingHours.length > 0 &&
                          store.isOpen &&
                          !store.isComingSoon
                          ? store.openingHours.map((opening, index2) => {
                            return (
                              <a
                                className="opening-hours gtmClickNavigation"
                                href={model.urlPrefix + store.urlPath}
                                key={index2}
                              >
                                {opening}
                              </a>
                            );
                          })
                          : !store.isOpen &&
                          !store.isComingSoon && (
                            <a
                              className="opening-hours gtmClickNavigation"
                              href={model.urlPrefix + store.urlPath}
                            >
                              {store.openingHours[0]}
                            </a>
                          )}

                        <a
                          className="area-map  gtmClickNavigation"
                          href={
                            model.urlPrefix +
                            store.urlPath +
                            (store.level.trim() === "" &&
                              store.zone.trim() === ""
                              ? ""
                              : "#topBar")
                          }
                        >
                          {store.level.trim() === "" && store.zone.trim() === ""
                            ? ""
                            : store.level + " " + store.zone}
                        </a>
                        <a
                          className={
                            "offer-tap loyalty-text gtmClickNavigation"
                          }
                          href={
                            model.urlPrefix +
                            store.urlPath +
                            (store.offersCount > 0
                              ? "#" + model.eventOffers
                              : "")
                          }
                        >
                          {store.offersCount > 0 && (
                            <>
                              <span
                                className={
                                  "picto " +
                                  (store.viPIconDisplay == true
                                    ? "icon-vip-card"
                                    : "")
                                }
                              ></span>
                              <span>
                                {store.offersCount +
                                  " " +
                                  store.offersDictionary}{" "}
                              </span>
                            </>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </Slider>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
