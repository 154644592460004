import React from 'react';




export default function ServicePillar({ model, constants, isClubPage }) {

  if (model !== null && model !== undefined && model !== "" &&
    ((model.title !== null && model.title !== undefined && model.title !== "") || (model.description !== null && model.description !== undefined && model.description !== ""))) {
    if (isClubPage) {
      return (
        <div className="col-12 col-lg-4 ps-lg-3 pe-lg-0">
          <div className="pillars-container p-lg-2 position-relative">
            <div className="d-flex">  
              <div className="card w-100 card-inverse pillars-card-border rounded-3 border-0">
                <div className="card-block rounded-3">
                  <div className="d-flex justify-content-center rounded-3">
                    {model.imageURL !== null && model.imageURL !== "" && model.imageURL !== undefined &&
                      <img src={model.imageURL} loading="lazy" alt={model.title} className="w-100 pillars-card-img">
                      </img>
                    }
                    {(model.imageURL === null || model.imageURL === "" || model.imageURL === undefined) &&
                      <span
                        className={"picto " + model.picto}
                      ></span>
                    }
                  </div>
                  <div className="d-flex justify-content-center position-relative align-items-center">
                    <span className="position-absolute pillars-card-title text-white">{model.title}</span>
                    {model.titleImage !== null && model.titleImage !== undefined && model.titleImage !== "" &&
                      <img src={model.titleImage} alt={model.title} loading="lazy" className="w-100 pillars-card-title-img"></img>
                    }
                  </div>
                  <div className="d-flex justify-content-center pillars-title">{model.description}
                  </div>
                  <a className="d-flex justify-content-center pillars-header text-black text-decoration-underline" href={model.buttonLink}>
                    {model.buttonText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div >
      )
    }
    else {
      return (
        <div className="col-12 col-lg-4 ps-lg-3 pe-lg-0">
          <div className={"loyalty-block bg-white" + (!constants.isNewBrandedSite ? " text-black" : " text-black") + " position-relative"}>
            <div className="loyalty-wrap bg-white d-flex justify-content-between flex-column">
              <a className="helper" href={model.buttonLink}></a>
              <div className="header">
                {model.imageURL !== null && model.imageURL !== "" && model.imageURL !== undefined &&
                  <img src={model.imageURL} alt={model.title} />
                }
                {(model.imageURL === null || model.imageURL === "" || model.imageURL === undefined) &&
                  <span
                    className={"picto loyalty-logo text-black " + model.picto}
                  ></span>
                }
              </div>
              <div className="loyalty-details d-flex align-items-start flex-column">
                <div className="title text-start fw-bold">{model.title}</div>
                <div className="description text-start mb-0">{model.description}</div>
                <div className='text-start'>
                <a className={"grid-new-cta "} href={model.buttonLink}>{model.buttonText}</a>
                <span className='picto icon-arrow-square-red-right-rw align-middle px-0 fs-5'/>
              </div>
              </div>
            </div>
          </div>
        </div >
      )
    }
  } else {
    return <></>;
  }

}
