import React, { useEffect, useState } from 'react';
import { isMobile, lazyLoadingImg } from '../Js-CommonFunctions';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';


export default function ArticleList({ fields }) {
  const [counter, setCounter] = useState(0);
  const [visible, setVisible] = useState(4);
  const [communityList, setCommunityList] = useState([]);

  var totalSlidesCount = fields !== null && fields.articleItems!== null && fields.articleItems.communityList !== null && fields.articleItems.communityList.length + 1;

  useEffect(() => {
   if( fields !== null && fields.articleItems!== null && fields.articleItems.communityList !== null && fields.articleItems.communityList.length>0) {
    setCommunityList(fields.articleItems.communityList);
  }
  if (isMobile.any()) {
    setVisible(1);
  }
  else if (isMobile.isTablet()) {
    setVisible(1);
  }
  // else if (window.screen.orientation != null && window.screen.orientation != "" && window.screen.orientation.type != null && window.screen.orientation.type != "") {
  //   if (window.screen.orientation.type == "landscape-primary" || window.screen.orientation.type == "landscape-secondary") {
  //     setVisible(1);
  //   }
  // }
  else {
    setVisible(4);
  }
  }, []);

  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        />
      );
    },
    arrows: false,
    dots: false,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    slidesToShow: 4.5,//totalSlidesCount > 5 ? 4.5 : totalSlidesCount,
    afterChange: () => {
      lazyLoadingImg();
    },
    responsive: [
      // {
      //   breakpoint: 1024, // a unrealistically big number to cover up greatest screen resolution
      //   settings: {
      //     slidesToShow: totalSlidesCount > 1 ? 1.5 : totalSlidesCount,
      //     slidesToScroll: 1,
      //   },
      // },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: totalSlidesCount > 1 ? 1.5 : totalSlidesCount,
          slidesToScroll: 1,
        },
      },
    ],
    // onInit: () => {
    //   setTimeout(() => {
    //     document.querySelectorAll('.live-events .slick-track').forEach(item => {
    //       item.style.transform = 'translate3d(0,0,0)';
    //     })
    //   }, 500)
    // }
  };
  var ct = 0;
  const fnViewrightClick = () => {
    if (visible >= communityList.length) {
      return false;
    }
    if (visible + 1 >= communityList.length) {
      setVisible(communityList.length)
    }
    else {
      setVisible(visible + 1)
    }
    setCounter(counter + 1)
    // setpgload(0);
  }

  const fnViewleftClick = () => {
    if (counter === 0) {
      return false;
    }
    if (visible === communityList.length) {
      setCounter(counter - 1)
      if (visible % 1 === 0) {
        setVisible(visible - 1)
      }
      else {
        setVisible(visible - visible % 1)
      }
    }
    else if (counter - 1 <= 0) {
      setCounter(0)
      setVisible(4)
    }
    else {
      setCounter(counter - 1)
      setVisible(visible - 1)
    }
    //setpgload(0);
  }

  if (fields !== undefined && fields !== "" && fields !== null &&
    fields.articleItems !== undefined && fields.articleItems !== null && fields.articleItems !== ""
    && fields.articleItems.heading?.value !== undefined && fields.articleItems.heading?.value !== null && fields.articleItems.heading?.value !== "") {
    return (
      <div className="olympic-nouvelle-page">
        <section className="olympic-page-nouvelle-card bg-white">
        <div className="container">
          <div className="olympic-page-nouvelle-title d-flex align-items-start">
            <RichText field={fields.articleItems.heading} />
          </div>
          <div className="olympic-page-nouvelle-sub-title d-sm-block d-md-none d-lg-none">
          {fields.articleItems.description}
          </div>
          <div className="olympic-page-arrow-marks d-flex align-items-start">
            <div className="py-3">
              <img src={fields.props.sliderLeftPicto} className="olympic-page-map-arrow img-fluid" alt="slider-left" data-testid="testclick1" onClick={(e) => fnViewleftClick()} />
              <img src={fields.props.sliderRightPicto} className="olympic-page-map-arrow img-fluid ps-3" alt="slider-right" data-testid="testclick2" onClick={(e) => fnViewrightClick()} />
            </div>
          </div>
          <div className="olympic-page-card-section">
            <Slider {...settings} className="card-deck row olympic-page-card-container">
              {communityList?.length > 0 &&
                communityList.slice(counter, visible + 1).map((item, index) => {
                  ct = counter + index;
                  return (
                    <div key={ct} className="col col-width">

                      <div className="card border-0">
                        <div className="view overlay">
                          <img className="card-img-top mb-3 rounded-3 olympic-page-map-image" src={item.communityImage?.value?.src} alt={item.communityImage?.value?.alt} />
                          <a className="stretched-link" href={item.link.value.url}>
                            <div className="mask rgba-white-slight"></div>
                          </a>
                        </div>
                        <div className="card-body p-0">
                          <p className="olympic-page-card-text fw-bold">{item.communityTitle}</p>
                          <p className="olympic-page-card-sub-text"><RichText field={item?.description} /></p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </Slider>
          </div>
          </div>
        </section>
      </div>
    )
  }
  else {
    return (<></>)
  }
}

