import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";

function AccessTransportConditions(fields, item) {
  if (item.value.byCarList.length !== 0) {
    return (
      <p className="center-access-address  text-start">
        {fields.accessToTheCentreTransport.coordonnes}
        <br />
        {fields.accessToTheCentreTransport.latitudeDis +
          " " +
          fields.accessToTheCentreTransport.latitude +
          " / " +
          fields.accessToTheCentreTransport.longitudeDis +
          " " +
          fields.accessToTheCentreTransport.longitude}
      </p>
    );
  }

  if (
    item.key.component_Title.value !== "" &&
    item.key.component_Title.value !== null
  ) {
    return (
      <RichText
        className="center-access-heading heading-items"
        field={item.key.component_Title}
      />
    );
  }
}

export default function AccessToTheCentreTransport({ fields }) {
  let count = 0;
  let count1 = 0;
  if (fields.accessToTheCentreTransport !== null) {
    return (
      <section className="opening-address-goto">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="how-togo-sc">
                <div className="content-title text-black text-uppercase text-start">
                  {fields.accessToTheCentreTransport.howToGetThere}
                </div>
                <div className="how-togo-wrap">
                  <div className="access-tabs">
                    <ul className="nav nav-pills nav-tabs" role="tablist">
                      {fields.accessToTheCentreTransport.transportItemIList.map(
                        (item, index) => {
                          count++;
                          if (item.key !== null && item.key !== "") {
                            return (
                              item.key.title?.value !== "" &&
                              item.key.title?.value !== null && (
                                <>
                                  {" "}
                                  <li
                                    role="presentation"
                                    className="nav-item gtmTransportDisplayEvent"
                                    data-gtmevent="transportDisplay"
                                    data-gtmtransportationmode={item.key.siName}
                                  >
                                    <a
                                      className={
                                        "nav-link link-dark " +
                                        (count === 1 ? " active" : "")
                                      }
                                      id={item.key.modifiedTitle + "-tab"}
                                      data-bs-toggle="pill"
                                      href={"#" + item.key.modifiedTitle}
                                      role="tab"
                                      aria-controls={item.modifiedTitle}
                                      aria-selected="false"
                                    >
                                      {!fields.accessToTheCentreTransport
                                        .isPageEdit ? (
                                        <RichText field={item.key.picto} />
                                      ) : (
                                        <RichText field={item.key.editPicto} />
                                      )}
                                      <span>
                                        <RichText field={item.key.title} />
                                      </span>
                                    </a>
                                  </li>
                                </>
                              )
                            );
                          }
                        }
                      )}
                    </ul>
                  </div>
                  <div
                    className="how-togo-wrap-content tab-content"
                    id="pills-tabContent"
                  >
                    {fields.accessToTheCentreTransport.transportItemIList.map(
                      (item, index) => {
                        if (item.key !== null && item.key !== "") {
                          count1++;
                          return (
                            item.key.title?.value !== "" &&
                            item.key.title?.value !== null && (
                              <>
                                <div key={index}
                                  className={
                                    "tab-pane fade" +
                                    (count1 === 1 ? " active show" : "")
                                  }
                                  id={item.key.modifiedTitle}
                                  role="tabpanel"
                                  aria-labelledby={
                                    item.key.modifiedTitle + "-tab"
                                  }
                                >
                                  <div className="description access-services">
                                    <div className="card border-0">
                                      {fields.accessToTheCentreTransport
                                        .accessInformation !== "" &&
                                        fields.accessToTheCentreTransport
                                          .accessInformation !== null && (
                                          <h3 className="center-access-heading  text-start">
                                            {
                                              fields.accessToTheCentreTransport
                                                .accessInformation
                                            }
                                          </h3>
                                        )}
                                      <p className="center-access-chapo  text-start m-0">
                                        <RichText
                                          field={item.key.information}
                                        />
                                      </p>
                                      {item.value !== null &&
                                        AccessTransportConditions(fields, item)}
                                      {item.value !== null &&
                                        item.value?.byCarList.length > 0 &&
                                        item.value.byCarList.map(
                                          (bycar, index) => {
                                            return (
                                              <div key={index} className="access-item row">
                                                <div className="col-md-3 left-col">
                                                  {fields
                                                    .accessToTheCentreTransport
                                                    .isPageEdit ? (
                                                    <RichText
                                                      field={bycar.logo}
                                                    />
                                                  ) : (
                                                    <RichText
                                                      field={bycar.editLogo}
                                                    />
                                                  )}
                                                  <RichText
                                                    className="access-name"
                                                    field={bycar.parkingName}
                                                  />
                                                  <div className="parking-variante"></div>
                                                  <div className="spaces">
                                                    {" "}
                                                    <RichText
                                                      field={
                                                        bycar.availablePlaces
                                                      }
                                                    />
                                                  </div>
                                                  {/* <div class="available-spaces "><span>3420</span> places disponibles</div> */}
                                                </div>
                                                <div className="col-md-9 right-col">
                                                  <div className="access-caracterisiques">
                                                    <RichText
                                                      field={bycar.introduction}
                                                    />
                                                  </div>
                                                  <div className="text-uppercase">
                                                    <strong>
                                                      {
                                                        fields
                                                          .accessToTheCentreTransport
                                                          .accessByCarSubtitle
                                                      }
                                                    </strong>
                                                  </div>
                                                  <RichText
                                                    field={bycar.access}
                                                  />
                                                  <div className="access-services-icon">
                                                    <div>
                                                      <RichText
                                                        field={bycar.services}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      {item.value !== null &&
                                        item.value?.notByCarList.length > 0 &&
                                        item.value.notByCarList.map(
                                          (bynotcar, index) => {
                                            return (
                                              ((bynotcar !== null &&
                                                bynotcar.logo_Part2.value !==
                                                null &&
                                                bynotcar.logo_Part2.value !==
                                                "" &&
                                                bynotcar.logo_Part2.value
                                                  .src !== null) ||
                                                (bynotcar.text_Part2.value !==
                                                  "" &&
                                                  bynotcar.text_Part2.value !==
                                                  null) ||
                                                (bynotcar.logo_Part1.value !==
                                                  null &&
                                                  bynotcar.logo_Part1.value !==
                                                  "" &&
                                                  bynotcar.logo_Part1.value
                                                    .src !== null) ||
                                                (bynotcar.text_Part1.value !==
                                                  "" &&
                                                  bynotcar.text_Part1.value !==
                                                  null)) && (
                                                <div key={index} className="access-item row">
                                                  {bynotcar !== null &&
                                                    ((bynotcar.logo_Part1.value !==
                                                      null &&
                                                      bynotcar.logo_Part1.value
                                                        .src !== "") ||
                                                      (bynotcar.text_Part1
                                                        .value !== "" &&
                                                        bynotcar.text_Part1
                                                          .value !== null)) &&
                                                    ((bynotcar.logo_Part2.value !==
                                                      null &&
                                                      bynotcar.logo_Part2.value
                                                        .src !== "") ||
                                                      (bynotcar.text_Part2
                                                        .value !== "" &&
                                                        bynotcar.text_Part2
                                                          .value !== null)) ? (
                                                    <> 
                                                    {/* doubt in this block */}
                                                      <div className="col-md-3 left-col">
                                                        <RichText
                                                          field={
                                                            bynotcar.logo_Title
                                                          }
                                                        />
                                                        <RichText
                                                          className="access-name"
                                                          field={
                                                            bynotcar.text_Title
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-md-5 center-col">
                                                        <div className="row">
                                                          <div className="col-md-3">
                                                            {bynotcar.logo_Part1
                                                              .value !== null &&
                                                              bynotcar
                                                                .logo_Part1
                                                                .value !==
                                                              "" && (
                                                                <img
                                                                  src={
                                                                    bynotcar
                                                                      .logo_Part1
                                                                      .value.src
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  alt={bynotcar.text_Title}
                                                                />
                                                              )}
                                                          </div>
                                                          <div className="col-md-9">
                                                            <RichText
                                                              field={
                                                                bynotcar.text_Part1
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-5 center-col">
                                                        <div className="row">
                                                          <div className="col-md-3">
                                                            {bynotcar.logo_Part2
                                                              .value !== null &&
                                                              bynotcar
                                                                .logo_Part2
                                                                .value !==
                                                              "" && (
                                                                <img
                                                                  src={
                                                                    bynotcar
                                                                      .logo_Part2
                                                                      .value.src
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  alt={bynotcar.text_Title}
                                                                />
                                                              )}
                                                          </div>
                                                          <div className="col-md-9">
                                                            <RichText
                                                              field={
                                                                bynotcar.text_Part2
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    BynotCarListInnerConditions(
                                                      fields,
                                                      bynotcar
                                                    )
                                                  )}
                                                </div>
                                              )
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                  <div className="cta-block text-lg-start text-center">
                                    <a
                                      href="#googlemaps"
                                      className="link-button btn-dark-transparent-2 scrollToHidden"
                                    >
                                      {
                                        fields.accessToTheCentreTransport
                                          .calculateMyItinerary
                                      }
                                    </a>

                                  </div>
                                </div>
                              </>
                            )
                          );
                        }
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
}

function BynotCarListInnerConditions(fields, bynotcar) {
  if (
    bynotcar !== null &&
    ((bynotcar.logo_Part1.value !== null &&
      bynotcar.logo_Part1.value !== "" &&
      bynotcar.logo_Part1.value.src !== "") ||
      (bynotcar.text_Part1.value !== "" && bynotcar.text_Part1.value !== null))
  ) {
    return (
      <>
        <div className="col-md-3 left-col">
          <RichText field={bynotcar.logo_Title} />
          <RichText className="access-name" field={bynotcar.text_Title} />
        </div>
        <div className="col-md-5 center-col">
          <div className="row">
            <div className="col-md-4 text-center">
              {bynotcar.logo_Part1.value !== null &&
                bynotcar.logo_Part1.value !== "" && (
                  <img
                    src={bynotcar.logo_Part1.value.src}
                    style={{ width: "100%" }}
                    alt={bynotcar.text_Title}
                  />
                )}{" "}
            </div>
            <div className="col-md-8">
              <RichText field={bynotcar.text_Part1} />
            </div>
          </div>
        </div>
      </>
    );
  } else if (
    bynotcar !== null &&
    ((bynotcar.logo_Part2.value !== null &&
      bynotcar.logo_Part2.value !== "" &&
      bynotcar.logo_Part2.value.src !== "") ||
      (bynotcar.text_Part2.value !== "" &&
        (bynotcar.text_Part2.value !== null) !== ""))
  ) {
    return (
      <>
        <div className="col-md-3 left-col">
          <RichText field={bynotcar.logo_Title} />
          <RichText className="access-name" field={bynotcar.text_Title} />
        </div>
        <div className="col-md-5 center-col">
          <div className="row">
            <div className="col-md-4 text-center">
              {bynotcar.logo_Part2.value !== null &&
                bynotcar.logo_Part2.value !== "" && (
                  <img
                    src={bynotcar.logo_Part2.value.src}
                    style={{ width: "100%" }}
                    alt={bynotcar.text_Title}
                  />
                )}
            </div>
            <div className="col-md-8">
              <RichText field={bynotcar.text_Part2} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
