import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  CommonBookmarkallpage,
  fnApplyPyjamaEffect,
  fnClickNavigation,
  fnUpdatePageNameInDataAttr,
  lazyLoadingImg,
  webViewEqualsOne,
} from "../Js-CommonFunctions";

const css = ``;
export default function HomePageEvents({ fields }) {
  var totalSlidesCount = fields.homePageEvents.customItems.length + 1;
  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        />
      );
    },
    arrows: false,
    dots: false,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    afterChange: () => {
      lazyLoadingImg();
    },
    responsive: [
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: totalSlidesCount > 1 ? 1.5 : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render
  useEffect(() => {
    setBoolUseEffect(true);
  }, []);
  useEffect(() => {
    HomePageEventsLoadComplete();
    lazyLoadingImg();
  }, [boolUseEffect]);
  var isNewBrandedSite = fields.homePageEvents.isNewBrandedSite;
  var newTypesIcons = fields.homePageEvents.newTypesItems;
  if (
    fields.homePageEvents != null &&
    fields.homePageEvents.items != null &&
    fields.homePageEvents.items.length > 0
  ) {
    return (
      <section className="back-gray  " id="homePageEvents">
        <div className="container">
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between">
            <a
              aria-label={fields?.homePageEvents?.headingLeftLink}
              className="title gtmClickNavigation"
              href={fields.homePageEvents.headingLeftLink}
            >
              {fields.homePageEvents.newsheading != null && (
                <>
                  {fields.homePageEvents.isNewBrandedSite === true ? (
                    <h2>
                      <span className="news-title">
                        {fields.homePageEvents.newsheading}
                      </span>
                    </h2>
                  ) : (
                    <h2>{fields.homePageEvents.newsheading}</h2>
                  )}
                </>
              )}
            </a>

            {/* <a
              className={
                "pull-right action" +
                (isNewBrandedSite ? " picto icon-plus-big" : "") +
                " gtmClickNavigation"
              }
              aria-label="news-link"
              href={fields.homePageEvents.headingRightLink}
            >
              {fields.homePageEvents.totalNewsCount != null &&
                fields.homePageEvents.isNewBrandedSite === false && (
                  <>
                    <strong>{fields.homePageEvents.newsLink}</strong> (
                    {fields.homePageEvents.totalNewsCount})
                    <span className="picto icon-ico-chevron-right"></span>
                  </>
                )} 
            </a> */}
          </div>
        </div>
        <div className="generic-three-block even-live position-relative bg-2 odd">
          <div className="container">
            <div className="row">
              <Slider {...settings} className="slider row d-flex slider-block px-lg-0" id="carouselEvent">
                {fields.homePageEvents.customItems.map((item, index) => {
                  return (
                    <div key={index}
                      className="col-9 col-sm-6 col-md-4  position-relative mb-3 ms-2 ms-lg-0 homePageEvents-col event-wrap"
                    >
                      <a
                        className={
                          "icon-heart-unfill-rw bookmark-news-new bookmark picto  ava-wishlist position-absolute"
                        }
                        role="button"
                        data-templatetype="Home Center"
                        href="javascript:void(0);"
                        aria-label={item.newsId}
                        aria-labelledby="news"
                        style={{ 'z-index': 2 }}
                        data-itemlabel={item.newsDisplayName}
                      ></a>

                      <div
                        className="grid-new-item grid-withpicture position-relative "
                        data-date=""
                      >
                        <div
                          className={
                            "position-relative grid-picture m-0 p-0 rounded-3 " + (item.hasVideo ? " play-icon" : "")
                          }
                        >
                          {
                            item.newsImgUrl != null && item.newsImgUrl != "" && (

                              <img
                                src={fields.homePageEvents.defaultImage}
                                className="rounded-3"
                                data-alt={item.newsImgUrl}
                                alt={item.newsImgAlt}
                                title={item.newsImgAlt}
                              />
                            )
                          }
                          {item.newsIsElibigible === false &&
                            fields.homePageEvents.NewTypesItems != null && (
                              <>
                                {fields.homePageEvents.newTypesItems.map(
                                  (itemnt, index) => {
                                    return <RichText field={itemnt.image} key={index} />;
                                  }
                                )}
                              </>
                            )}
                        </div>
                        <a
                          href={fields.homePageEvents.urlPrefix + item.newsUrlPath}
                          aria-label="news-detail"
                          className={"position-absolute w-100 h-100 block-link stretched-link"}
                        ></a>

                        <div className="grid-new-content grid-content-mobile">
                        {item.retailerName != null && item.retailerName != "" && (
                            <div className="retailer-name my-1">
                              {item.retailerName}
                            </div>
                          )}
                          {item.isEventsDisplayDate &&
                            item.newsProperDate != null &&
                            item.newsProperDate != "" &&
                            item.newsProperDate.value != null &&
                            item.newsProperDate.value != "" && (
                              <div className="date ">
                                <RichText field={item.newsProperDate} />
                              </div>
                            )}
                       
                          {item.newsDisplayName != null &&
                            item.newsDisplayName != "" && (
                              <div className="grid-new-title py-1">
                                {item.newsDisplayName}
                              </div>
                            )}
                          {/* <div className="bookmark-wrap">
                            <a
                              className={
                                "add-wishlist ava-wishlist bookmark picto icon-heart-unfill-rw"
                              }
                              role="button"
                              data-templatetype="Home Center"
                              href="javascript:void(0);"
                              aria-label={item.newsId}
                              aria-labelledby="news"
                              data-itemlabel={item.newsDisplayName}
                            ></a>
                          </div> */}

                          <div className="">
                            <a
                              aria-label={item?.externalUrlDescription}
                              href={
                                fields.homePageEvents.urlPrefix + item.newsUrlPath
                              }
                              className="grid-new-cta "
                            >
                              {item.externalUrlDescription}
                            </a>
                            <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
  else {
    return <></>;
  }
}

function HomePageEventsLoadComplete() {
  webViewEqualsOne();
  //Reusing Home page offers slick view
  var numberOfEventsDisplayed = document.querySelectorAll(
    "#homePageEvents .event-wrap"
  ).length;
  if (numberOfEventsDisplayed == 0) {
    if (document.getElementById("homePageEvents"))
      document.getElementById("homePageEvents").style.display = "none";
    fnApplyPyjamaEffect();
  } else {
    if (window.offsetWidth < 991) {
      //  activateHomePageEventsCarousel(numberOfEventsDisplayed);
    }
    CommonBookmarkallpage(); //Bind the bookmark click event and show already bookmarked items with active heart symbol

    document
      .querySelectorAll("#homePageEvents .gtmClickNavigation")
      .forEach((item) => {
        item.addEventListener("click", function (event) {
          //Bind the GTM click event for click navigations in this offers component
          fnUpdatePageNameInDataAttr(event.currentTarget, "gtmclickposition");
          fnClickNavigation(event.currentTarget);
        });
      });
  }
}
