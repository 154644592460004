import Enumerable from "linq";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Slider from 'react-slick';
import { carouselLoad, convertUTCTimeToClientTime, fnGTMCarouselViewEvent, fngtmModuleclick, lazyLoadingImg } from "../Js-CommonFunctions";
import NotifyMeEvent from "../NotifyMeEvent";

export default function CarouselEvent({ fields }) {
  const [windowinnerWidth, setwindowinnerWidth] = useState(1000);
  const [carouselEventData, setCarouselEventData] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    var localeDateTime = new Date();
    if (fields.liveEvent !== null && fields.liveEvent.videoList !== null && fields.liveEvent.videoList !== "" && fields.liveEvent.videoList !== undefined && fields.liveEvent.videoList.length > 0) {
      var caoruselData = Enumerable.from(fields.liveEvent.videoList).orderBy(x => new Date(x.startsAt).getTime()).where(x => (x.status === "upcoming" && new Date(x.finishesAt).getTime() >= localeDateTime.getTime()) || (x.status === "active" && new Date(x.finishesAt).getTime() >= localeDateTime.getTime())).toArray();
      var sortedData = Enumerable.from(fields.liveEvent.videoList).orderByDescending(x => new Date(x.finishesAt).getTime()).where(x => x.status === "archive" && new Date(x.finishesAt).getTime() <= localeDateTime.getTime()).toArray();
      var finalPushedData = caoruselData.push(...sortedData);
      setCarouselEventData(caoruselData);
    }
  }, []);

  useEffect(() => {
    carouselLoad();
  }, [windowinnerWidth, carouselEventData]);

  function notifyMeClick(item) {
    ReactDOM.render(<NotifyMeEvent fields={fields.liveEvent} item={item} />, document.getElementById("popinNotifyMe"));
    // Triggers the Popin
    var popinClick = document.getElementById("popinNotifyMeClick");
    popinClick.click();
  }

  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        >
          {i}
        </button>
      );
    },
    arrows: false,
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => {
      lazyLoadingImg();
      fnGTMCarouselViewEvent(
        document.getElementsByClassName("carousel-card")[0]
      );
    },
  };

  if (
    fields.liveEvent !== null &&
    carouselEventData !== null && carouselEventData !== undefined &&
    carouselEventData.length > 0
  ) {
    return (
      <>
        <section className="generic-three-block even-live">
          <div className="promotional-banner carousel-live">
            <Slider
              ref={ref}
              {...settings}
              className="wrapper container"
            >
              {carouselEventData.slice(0, 5).map((item, index) => {
                var finishAtTime = new Date(item.finishesAt);
                var startAtTime = new Date(item.startsAt);
                var localeDateTime = new Date();
                var formatedDateTime = "";

                if (finishAtTime !== null && finishAtTime !== undefined && finishAtTime !== "" && finishAtTime <= localeDateTime) {
                  formatedDateTime = convertUTCTimeToClientTime(item.startsAt, true, true)
                }
                else {
                  formatedDateTime = convertUTCTimeToClientTime(item.startsAt, true, false);
                }

                return (
                  <div key={index}>
                    <div className="carousel-card dark-overlay-active">
                      <div className="promo-img promo-img-carousel-live justify-content-start-carousel position-relative">
                        <img
                          src={fields.liveEvent.defaultImage}
                          data-alt={item.media?.rectangleImage}
                          alt={item.name}
                          title={item.name}
                          className="carousel-img carousel-img-live"
                          id={"carouselLoadingMobile" + index}
                        />
                      </div>
                      <div className="carousel-wrap-live justify-content-start justify-content-start-carousel position-absolute">
                        <div className="overlay-live carousel-overlay">
                          <div className="carousel-title-live">{item.name}</div>
                          <div className="date-carousel" id="date-carousel">{formatedDateTime}</div>
                          <div className="carousel-description" style={{ color: "white" }}></div>
                          {item.finishesAt != null && item.finishesAt != "" && (finishAtTime <= localeDateTime)
                            ? <div><a data-testid="moduleclickwatcnow1" aria-label={fields?.liveEvent?.see_Details} href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                              onClick={() => fngtmModuleclick(item, "click_watch_now")}
                              className="btn-white-transparent-live ">{fields.liveEvent.watch_Now}</a>
                              <a data-testid="moduleclickseedetail1" aria-label={fields?.liveEvent?.see_Details} href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                                onClick={() => fngtmModuleclick(item, "click_see_details", "Carousel")}
                                className="btn-white-transparent-live live-btn">{fields.liveEvent.see_Details}</a></div>
                            : startAtTime < localeDateTime && finishAtTime > localeDateTime
                              ? <><a data-testid="moduleclickseedetail2" aria-label={fields?.liveEvent?.see_Details} href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                                onClick={() => fngtmModuleclick(item, "click_see_details", "Carousel")}
                                className="btn-white-transparent-live ">{fields.liveEvent.see_Details}</a></>
                              : <><a data-testid="moduleclickwatcnow2" href="javascript:void(0);"
                                onClick={(e) => { notifyMeClick(item); fngtmModuleclick(item, "click_notify_me", "Carousel") }}
                                className="btn-white-transparent-live ">{fields.liveEvent.notifyMe}</a>
                                <a data-testid="moduleclickseedetail3" aria-label={fields?.liveEvent?.see_Details} href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                                  onClick={() => fngtmModuleclick(item, "click_see_details", "Carousel")}
                                  className={"btn-white-transparent-live live-btn"}>{fields.liveEvent.see_Details}</a></>}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
      </>
    );
  }
  else {
    return <></>;
  }
}
