
import loadable from "@loadable/component";
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import EasyParking from '../EasyParking';
import { updatePartnerDetail } from '../MyAccount/MyAccountScripts';
const ServicePillar = loadable(() => import("../ServicePillar"));
const HowToEnjoyIt = loadable(() => import("../HowToEnjoyIt"));

export default function ServiceDetails({ fields }) {
  useEffect(() => {

    if (fields !== null && fields !== undefined && fields.serviceDetail?.isParkingService && fields.serviceDetail?.easyParkingEligible &&
      fields.serviceDetail?.easyParking !== null && fields.serviceDetail?.easyParking !== "") {
      if (fields.serviceDetail.easyParking.activateEasyParking) {
        updatePartnerDetail(fields.serviceDetail.easyParking.pcCode, fields.serviceDetail.easyParking.parkingPartner,
          (fields.serviceDetail.easyParking.accountUrl?.link?.value?.url + "?ShowSuccessPopin=easy-parking&pcCode=" + fields.serviceDetail.easyParking.pcCode));
      }
    }
  }, []);

  if (fields?.serviceDetail != null && fields?.serviceDetail?.serviceInfo !== null) {
    var serviceInfoDescription = { value: fields.serviceDetail.serviceInfo.description }
    var serviceInfoPriceText = { value: fields.serviceDetail.priceText }
    var serviceInfoprice = { value: fields.serviceDetail.serviceInfo.price }
    var serviceInfolevelName = { value: fields.serviceDetail.serviceInfo.levelName }
    let loyaltyBenefitsDescription = { value: fields.serviceDetail.serviceInfo.loyaltyBenefitsDesc };
    return (
      <>
        {(fields.serviceDetail.serviceInfo.image !== null && fields.serviceDetail.serviceInfo.image !== "" && fields.serviceDetail.display_Picture_For_Service) &&
          <section className="service-detail">
            <div className="container">
              <div className="img-wrap">
                <img className="img-fluid border-8" loading="lazy" src={fields.serviceDetail.serviceInfo.image} alt={fields.serviceDetail.serviceInfo.shortTitle} />
              </div>
            </div>
          </section>
        }
        <section className="service-detail">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 ps-lg-0 pe-lg-3">
                <div className="service-picto mb-3 mb-lg-4 d-flex align-items-center ">
                  <span className={"picto  " + (fields.serviceDetail.serviceInfo.pictoOn !== "" && fields.serviceDetail.serviceInfo.pictoOn !== null ? fields.serviceDetail.serviceInfo.pictoOn : " icon-servicesaspictosservices") + " me-3"}></span>
                  <h1 className="page-title text-uppercase m-0 p-0">{fields.serviceDetail.serviceInfo.shortTitle}</h1>
                </div>
                <div className="description mb-2 text-start">
                  <div className="content ">
                    <RichText field={serviceInfoDescription} />
                  </div>
                </div>
                {/* CTA */}
                {fields.serviceDetail.serviceInfo.serviceLink !== null && fields.serviceDetail.serviceInfo.serviceLink !== "" && fields.serviceDetail.serviceInfo.serviceUrlTitle !== null && fields.serviceDetail.serviceInfo.serviceUrlTitle !== "" &&
                  <a href={fields.serviceDetail.serviceInfo.serviceLink} className="parking-service-cta cursor-pointer">{fields.serviceDetail.serviceInfo.serviceUrlTitle}</a>
                }
                {((fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel !== null && fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel !== undefined && fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel !== "")
                  || (loyaltyBenefitsDescription?.value !== null && loyaltyBenefitsDescription?.value !== undefined && loyaltyBenefitsDescription?.value !== "")) &&
                  <div className="loyalty-block-wc p-0 service-detail-border">
                    <div className="loyalty-block-wc-img service-detail-radius position-relative">
                      <picture>
                        <div className=" justify-content-between align-items-center  service-detail-desktop-header ">
                          <img src={fields?.serviceDetail?.logo?.value?.src} loading="lazy" alt={fields?.serviceDetail?.logo?.value?.alt} className="loyalty-program-responsive-content d-flex " />
                          <span className="service-detail-text d-flex text-white pe-2">{fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel}</span>
                        </div>

                      </picture>
                    </div>
                    <div className="service-detail-content">
                      <div className="service-detail-text-content"><RichText field={loyaltyBenefitsDescription} /></div>
                    </div>
                  </div>}
                <div className="row">
                  <div className="col-12 col-lg-6 text-start p-lg-0 service-contact">
                    {(fields.serviceDetail.serviceInfo.price !== null && fields.serviceDetail.serviceInfo.price !== "") &&
                      <div className="label mb-3 d-flex">
                        {(fields.serviceDetail.isNewBranded) ?
                          (
                            <span className="picto icon-cashback"></span>
                          ) :
                          (
                            <img src={fields.constants.mediaServerUrl + "/img/ico-tariff.png"} loading="lazy" alt="tarifs" />
                          )
                        }
                        <div className="ms-3 flex-grow-1 service-contact-desscription">
                          <div className="text text-uppercase fw-bold"><RichText field={serviceInfoPriceText} /></div>
                          <div className="text"><RichText field={serviceInfoprice} /></div>
                        </div>
                      </div>
                    }
                    {(fields.serviceDetail.serviceInfo.levelName !== "" && fields.serviceDetail.serviceInfo.levelName !== null) &&
                      <div className="label mb-3 d-flex">
                        <span className="picto icon-location"></span>
                        <div className="ms-3 flex-grow-1 service-contact-desscription">
                          <div className="text text-uppercase fw-bold">Localisation</div>
                          <div className="text"><RichText field={serviceInfolevelName} /></div>
                        </div>
                      </div>

                    }
                    {(fields.serviceDetail.serviceInfo.phoneNumber !== "" && fields.serviceDetail.serviceInfo.phoneNumber !== null || fields.serviceDetail.serviceInfo.email !== "" && fields.serviceDetail.serviceInfo.email !== null) &&
                      <div className="label mb-32 mb-lg-0 d-flex">
                        <span className="picto icon-infos"></span>
                        <div className="ms-3 flex-grow-1 service-contact-desscription">
                          <div className="text text-uppercase fw-bold">{fields.serviceDetail.contactText}</div>
                          <div className="text">
                            {fields.serviceDetail.serviceInfo.phoneNumber + fields.serviceDetail.serviceInfo.email}
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                </div>
                {(fields.serviceDetail.elligibility_Text !== null && fields.serviceDetail.elligibility_Text !== "") &&
                  <div className="label loyalty-text mb-4">
                    <span className="align-middle picto icon-vip-card"></span>
                    <div className="text d-inline-block text-uppercase fw-bold ms-2">{fields.serviceDetail.elligibility_Text}</div>
                  </div>
                }
              </div>
              {fnNonEuropeanRegionData(fields)}
            </div>
          </div>
        </section>
      </>
    )
  } else {
    return <></>;
  }
}


function fnNonEuropeanRegionData(fields) {
  if (fields.serviceDetail.isParkingService && fields.serviceDetail.easyParkingEligible) {
    return (
      <EasyParking fields={fields.serviceDetail.easyParking} />
    )
  }
  else if (!fields.serviceDetail.isLoyaltyProgramDisabled && fields.serviceDetail.isEuropeanRegion) {
    return (
      (fields.serviceDetail.showServicePillar) ?
        (
          <ServicePillar model={fields.serviceDetail.servicePillar} constants={fields.constants} isClubPage={fields.serviceDetail.isClubPage} />
        )
        :
        (
          <HowToEnjoyIt model={fields.serviceDetail.howToEnjoyIt} constants={fields.constants} />
        ))
  }
}

