import React from 'react';
import { openLoyaltyCardBarCodePopin } from '../Js-CommonFunctions';
import { GoBackToMenu } from "../MyAccount/MyAccountScripts";

export default function MyBarCode({ fields }) {
    if (fields != undefined && fields != null && fields != "") {
        return (
            <>
                <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
                    <div className="container modalContainer p-0 ">
                        <nav className="benefits-header-height px-lg-1 row m-0   py-2">
                            <div className="col-2 text-center px-0">
                                <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                                    href="javascript:void(0);" data-testid="loyaltyCardClick" onClick={() => { GoBackToMenu("my-loyalty-card") }}></a>
                            </div>
                            <div className="col-8 p-0 text-center">
                                <div className=" text-center benefits-subtitle ">
                                    {fields.myBarCodeTitle}
                                </div>
                            </div>
                            <div className="col-2 text-center px-0">
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="user-card">
                    <div className="loyalty-card-title d-none d-lg-block pb-0 mb-4">{fields.myBarCodeTitle}</div>
                    <div className="user-card-info">
                        <div className="user-info">
                            <div className="user-details">
                                <div className="user-name text-align-center mb-1">{fields.firstName + " " + fields.lastName}</div>
                            </div>
                            <div className="card-details">
                                <span className="card-number ">{fields.ndegree + " " + fields.barcode}</span>
                            </div>
                            {fields.isMobileView &&
                                <div className="card-icon " data-testid="card-icon" onClick={(e) => openLoyaltyCardBarCodePopin(fields.barcode)}>
                                    {(fields.barImagePath !== null && fields.barImagePath !== "") &&
                                        <img src={fields.barImagePath} alt={fields.barcode} title={fields.barcode} className="card-icon-img" />
                                    }
                                </div>
                            }
                            {(fields.isMobileView == false) &&
                                <div className="card-icon " >
                                    {(fields.barImagePath !== null && fields.barImagePath !== "") &&
                                        <img src={fields.barImagePath} alt={fields.barcode} title={fields.barcode} className="card-icon-img" />
                                    }
                                </div>
                            }
                        </div>
                        <div className="user-card-btn mt-3 align-items-center d-flex m-auto justify-content-center">
                            <a href="/loyaltyProgram" aria-label={fields?.discoverAdvantages}
                                className="btn-loyalty-card btn btn-white loyalty-card-btn-discover-benefits">{fields.discoverAdvantages}</a>
                        </div>
                        {fields.enableCaptainWallet && fields.captainWalletBlocks !== undefined && fields.captainWalletBlocks !== null && fields.captainWalletBlocks !== "" && fields.captainWalletBlocks.length > 0 &&
                            <>
                                <div className="choose-cards">{fields?.walletTitle}</div>
                                {fields.captainWalletBlocks.map((item, index) => {
                                    return (
                                        <div key={index} className="choose-cards-countries">
                                            <div className="choose-cards-countryname">
                                                {item.walletLabel}
                                            </div>
                                            <div className="choose-cards-wallet">
                                                {item.walletDescription?.value}
                                            </div>
                                            <div className="choose-cards-countrybuttom">
                                                <a href={item.walletLink} aria-label={item?.walletLink} target="_blank" rel="noreferrer"
                                                    className=" loyalty-card-btn-phone-wallet btn btn-dark wallet-cta align-items-center d-flex justify-content-center m-auto"><span
                                                        className="picto icon-divers-wallet me-2"></span>
                                                    <span>{item.ctAtext}</span></a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                        <div className="user-description  d-flex justify-content-center mt-4 text-start">{fields.description}</div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return <></>;
    }
}
